import * as i18n from "i18next";
import * as React from "react";
import {Button, Form} from "semantic-ui-react";
import {BryxLocal} from "../../utils/bryxLocal";
import {BryxWebSocket} from "../../utils/bryxWebSocket";
import {SupportUtils} from "../../utils/supportUtils";
import {UpdateManager} from "../../utils/updateManager";

export class DevToolsTab extends React.Component<{}, {}> {

    render() {
        return (
            <Form className="dev-tools-tab">
                <Form.Field>
                    <Button
                        href={`data:text/plain;base64,${SupportUtils.logsAttachment()}`}
                        download={"logs.txt"}
                        content={i18n.t("settings.devTools.downloadLogs")}
                    />
                </Form.Field>
                <Form.Field>
                    <Button
                        href={`data:text/plain;base64,${SupportUtils.bryxItemsAttachment()}`}
                        download={"localStorage.txt"}
                        content={i18n.t("settings.devTools.downloadUserData")}
                    />
                </Form.Field>
                <Form.Field>
                    <Button
                        href={`data:text/plain;base64,${SupportUtils.deviceInfoAttachment()}`}
                        download={"deviceInfo.txt"}
                        content={i18n.t("settings.devTools.downloadDeviceInfo")}
                    />
                </Form.Field>
                <Form.Field>
                    <Button
                        color="red"
                        content={i18n.t("settings.devTools.clearLocationState")}
                        onClick={() => {
                            BryxLocal.setLocationState("default");
                            window.location.reload(true);
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <Button
                        color="red"
                        content={i18n.t("settings.devTools.clearForceDeviceSupport")}
                        onClick={() => {
                            BryxLocal.setForceDeviceSupport(false);
                            window.location.reload(true);
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <Button
                        color="green"
                        content={i18n.t("settings.devTools.fakeUpdate")}
                        onClick={() => {
                            UpdateManager.shared.fakeAvailableUpdate();
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <Button
                        color="green"
                        content={i18n.t("settings.devTools.toggleFakeDisconnect")}
                        onClick={() => {
                            BryxWebSocket.shared.toggleFakeDisconnect();
                        }}
                    />
                </Form.Field>
            </Form>
        );
    }
}
