import { Button, Menu, Modal, Tab } from "semantic-ui-react";
import React, { useEffect, useMemo, useState } from "react";
import i18n from "i18next";
import {
    NotificationAdd,
    ManageAccounts,
    CorporateFare,
    Close,
} from "@mui/icons-material";
import { BryxLocal } from "../../../utils/bryxLocal";
import { AccountPane } from "./accountPane";
import { AlertPane } from "./alertPane";
import { AgencyPane } from "./agencyPane";
import { AccountManagementModalState, useModals } from "../modalManager";
import { AccountSections } from "./util";

export type AccountManagementModalProps = {
    open: boolean;
    onClose: () => void;
    initialState: AccountManagementModalState;
};

const tabNames = ["account", "agency", "alert"];

export function AccountManagementModal(props: AccountManagementModalProps) {
    const [active, setActive] = useState(0);
    const [activeSection, setActiveSection] = useState<AccountSections | null>(
        null
    );
    const {close} = useModals();
    const panes = [
        {
            menuItem: (
                <Menu.Item key="account" className="tab-title">
                    <ManageAccounts className="tab-icon" />
                    {i18n.t("account.sections.account")}
                </Menu.Item>
            ),
            render: () => (
                <AccountPane
                    section={activeSection}
                    onChangeSection={setActiveSection}
                />
            ),
        },
        {
            menuItem: (
                <Menu.Item key="agencies" className="tab-title">
                    <CorporateFare className="tab-icon" />
                    {i18n.t("account.sections.agencies")}
                </Menu.Item>
            ),
            render: () => (
                <AgencyPane
                    section={activeSection}
                    onChangeSection={setActiveSection}
                />
            ),
        },
        {
            menuItem: (
                <Menu.Item key="alerts" className="tab-title">
                    <NotificationAdd className="tab-icon" />
                    {i18n.t("account.sections.alerts.title")}
                </Menu.Item>
            ),
            render: () => (
                <AlertPane
                    section={activeSection}
                    onChangeSection={setActiveSection}
                />
            ),
        },
    ];

    useEffect(
        () => setActive(tabNames.indexOf(props.initialState.openTab)),
        [props.initialState.openTab]
    );

    useEffect(
        () => setActiveSection(props.initialState.openSection ?? null),
        [props.initialState.openSection]
    );

    return (
        <Modal open={props.open} onClose={props.onClose} size="large">
            <Button icon circular className="modal-close" onClick={close}><Close /></Button>
            <Modal.Header>
                {i18n.t("account.header")} - {BryxLocal.getEmail()}
            </Modal.Header>
            <Modal.Content className="bryx-raised-background">
                <Tab
                    panes={panes}
                    menu={{ attached: false }}
                    activeIndex={active}
                    onTabChange={(event, data) =>
                        setActive(Number(data.activeIndex ?? 0))
                    }
                />
            </Modal.Content>
        </Modal>
    );
}
