import * as React from "react";
import {Card, Icon} from 'semantic-ui-react';
import {PatientItemView} from "../../components/views/patientItemView";
import {FullJob} from "../../models/job";
import {Patient} from "../../models/patient";
import { ModalContext, ModalContextType, PatientCreationModalState } from "../../components/modals/modalManager";

interface JobPatientsProps {
    patients: Patient[];
    job: FullJob;
    onSelectPatient: (patientId: string, initialCreationPrompts: string[] | null) => void;
}

export class JobPatientsTab extends React.Component<JobPatientsProps, {}> {
    static contextType: React.Context<ModalContextType | null> | undefined = ModalContext;
    context: ModalContextType;
    constructor(props: JobPatientsProps, context: any) {
        super(props, context);
    }

    render() {
        const {patients} = this.props;
        return (
            <div className="job-patients-tab">
                <Card.Group>
                    {patients.map(p => (
                        <Card
                            key={p.id}
                            className="patient-card"
                            onClick={() => this.props.onSelectPatient(p.id, null)}
                        >
                            <PatientItemView patient={p}/>
                        </Card>
                    ))}
                    <Card className="add-patient-button-container" onClick={() => this.context.open<PatientCreationModalState>("patientCreation", {
                        job: this.props.job,
                        onCreatePatient: (newPatient, prompts) => {
                        this.setState({isCreating: false}, () => this.props.onSelectPatient(newPatient.id, prompts));
                    }
                    })}>
                        <Icon className="add-patient-button" name="plus" size="huge" />
                    </Card>
                </Card.Group>
            </div>
        );
    }
}
