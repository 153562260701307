import * as i18n from "i18next";
import * as React from "react";
import {Group} from "../../models/group";
import {Message, MessageContentType} from "../../models/message";
import {DateUtils} from "../../utils/dateUtils";
import {BryxPreferences} from "../../utils/preferenceManager";
import "../../../sass/pages/messages/_groupListItem.scss";

export interface GroupListItemProps {
    group: Group;
    preferences: BryxPreferences;
    selected: boolean;
    onSelectGroup: (group: Group) => void;
    unread: boolean;
}

export class GroupListItem extends React.Component<GroupListItemProps, {}> {
    private static messageSummary(message: Message): string {
        switch (message.content.type) {
            case MessageContentType.text:
            case MessageContentType.bryx:
                return `${message.sender.name}: ${message.content.text}`;
            case MessageContentType.image:
                return `${message.sender.name}: ${i18n.t("messages.image")}`;
        }
    }

    render() {
        const {group, selected, onSelectGroup} = this.props;
        const latestMessage = group.latestMessage;
        const classNames = ["GroupListItem"];
        if (selected) {
            classNames.push("GroupListItem_Selected");
        }
        return (
            <div className={classNames.join(" ")} onClick={() => onSelectGroup(group)}>
                {this.props.unread && <div className="unread-indicator"></div>}
                <div className="group-list-item-container">
                    <span className="truncate" style={{fontWeight: "bold"}}>{group.name}</span>
                    <span className="last-updated">
                        {DateUtils.formatApproximateDateTime(group.lastUpdated, this.props.preferences)}
                    </span>
                </div>
                <span className="truncate message-summary">
                    {latestMessage ? GroupListItem.messageSummary(latestMessage) : i18n.t("messages.noMessages")}
                </span>
            </div>
        );
    }
}
