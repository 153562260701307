import {Point} from "geojson";
import {ParseResult, ParseUtils} from "../utils/cerealParse";
import {GeoUtils} from "../utils/geoUtils";

export class Responder {
    private constructor(
        public id: string,
        public isMe: boolean,
        public location: Point | null,
        public name: string,
        public phone: string | null,
        public currentResponse: Response,
        public distance: number | null,
    ) {}

    static parse(o: any): ParseResult<Responder> {
        try {
            return ParseUtils.parseSuccess(new Responder(
                ParseUtils.getString(o, "id"),
                ParseUtils.getBoolean(o, "isMe"),
                o["location"] || null,
                ParseUtils.getString(o, "name"),
                ParseUtils.getStringOrNull(o, "phone"),
                ParseUtils.getSubobject(o, "currentResponse", Response.parse),
                ParseUtils.getNumberOrNull(o, "distance"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Responder>(`Invalid Responder Model: ${e.message}`);
        }
    }

    static compare(r1: Responder, r2: Responder): number {
        return r1.name.localeCompare(r2.name);
    }

    isEqual(responder: Responder): boolean {
        return this.name == responder.name &&
            this.id == responder.id &&
            this.phone == responder.phone &&
            this.isMe == responder.isMe &&
            this.distance == responder.distance &&
            this.currentResponse.isEqual(responder.currentResponse) &&
            GeoUtils.geoJsonPointIsEqual(this.location, responder.location);
    }
}

export class Response {
    private constructor(
        public responseTime: Date,
        public responseOption: ResponseOption,
    ) { }

    static parse(o: any) {
        try {
            return ParseUtils.parseSuccess(new Response(
                ParseUtils.getUNIXTimestampDate(o, "ts"),
                ParseUtils.getSubobject(o, "responseOption", ResponseOption.parse),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Response>(`Invalid Response Model: ${e.message}`);
        }
    }

    isEqual(response: Response): boolean {
        return this.responseTime.getTime() == response.responseTime.getTime() &&
            this.responseOption.isEqual(response.responseOption);
    }

    static compare(r1: Response, r2: Response): number {
        return r2.responseTime.getTime() - r1.responseTime.getTime();
    }
}

export enum ResponseOptionType {
    positive, negative,
}

export class ResponseOption {
    public constructor(
        public id: string,
        public text: string,
        public type: ResponseOptionType,
    ) { }

    static parse(o: any): ParseResult<ResponseOption> {
        try {
            return ParseUtils.parseSuccess(new ResponseOption(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "text"),
                ParseUtils.getEnum(o, "type", ResponseOptionType),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<ResponseOption>(`Invalid ResponseOption Model: ${e.message}`);
        }
    }

    isEqual(responseOption: ResponseOption): boolean {
        // TODO: Use ID only; when actually API-unique
        return this.text == responseOption.text &&
            this.type == responseOption.type;
    }

    static compare(r1: ResponseOption, r2: ResponseOption): number {
        const r1TypePoint = r1.type == ResponseOptionType.positive ? 0 : 1;
        const r2TypePoint = r2.type == ResponseOptionType.positive ? 0 : 1;
        return r1TypePoint - r2TypePoint || r2.text.localeCompare(r2.text) || r1.id.localeCompare(r2.id);
    }
}
