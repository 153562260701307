import * as moment from "moment";
import {config} from "../config";
import {BryxDateFormat, BryxPreferences} from "./preferenceManager";

export class DateUtils {
    private static clockOffset = 0;

    public static setOffset(offset: number) {
        config.info(`Updating system clock offset to ${offset} ms`);
        DateUtils.clockOffset = offset;
    }

    public static duration(date1: Date, date2: Date): moment.Duration {
        return moment.duration(moment(date1).diff(date2));
    }

    private static preferredTimeFormat(preferences: BryxPreferences, includeSeconds: boolean = true): string {
        if (includeSeconds) {
            return preferences.use24HourTime ? "HH:mm:ss" : "hh:mm:ssA";
        } else {
            return preferences.use24HourTime ? "HH:mm" : "hh:mm A";
        }
    }

    private static preferredDateFormat(preferences: BryxPreferences, length: "short" | "medium" = "short", includeYear: boolean = true): string {
        if (length == "short") {
            switch (preferences.preferredDateFormat) {
                case BryxDateFormat.monthDayYear:
                    return `MM-DD${includeYear ? "-YYYY" : ""}`;
                case BryxDateFormat.dayMonthYear:
                    return `DD-MM${includeYear ? "-YYYY" : ""}`;
                case BryxDateFormat.yearMonthDay:
                    return `${includeYear ? "YYYY-" : ""}MM-DD`;
                default:
                    throw Error("Impossible case");
            }
        } else {
            switch (preferences.preferredDateFormat) {
                case BryxDateFormat.monthDayYear:
                    return `MMM D${includeYear ? " YYYY" : ""}`;
                case BryxDateFormat.dayMonthYear:
                    return `D MMM${includeYear ? " YYYY" : ""}`;
                case BryxDateFormat.yearMonthDay:
                    return `${includeYear ? "YYYY " : ""}MMM D`;
                default:
                    throw Error("Impossible case");
            }
        }
    }

    public static formatDateTime(date: Date, preferences: BryxPreferences): string {
        return moment(date).format(`${DateUtils.preferredTimeFormat(preferences)} [on] ${DateUtils.preferredDateFormat(preferences)}`);
    }

    public static formatTime(date: Date, preferences: BryxPreferences): string {
        return moment(date).format(DateUtils.preferredTimeFormat(preferences));
    }

    public static formatApproximateDateTime(date: Date, preferences: BryxPreferences): string {
        const target = moment(date);
        const now = moment(DateUtils.bryxNow());
        if (target.isSameOrAfter(now.subtract(moment.duration(24, "hours")))) {
            return target.format(DateUtils.preferredTimeFormat(preferences, false));
        } else if (target.isSameOrAfter(now.subtract(moment.duration(7, "days")))) {
            return target.format("ddd");
        } else if (target.year() == now.year()) {
            return target.format(DateUtils.preferredDateFormat(preferences, "medium", false));
        } else {
            return target.format(DateUtils.preferredDateFormat(preferences, "medium"));
        }
    }

    public static formatMessageDateTime(date: Date, preferences: BryxPreferences): string {
        const target = moment(date);
        const now = moment(DateUtils.bryxNow());
        if (target.isSameOrAfter(now.subtract(moment.duration(24, "hours")))) {
            return target.format(DateUtils.preferredTimeFormat(preferences));
        } else if (target.isSameOrAfter(now.subtract(moment.duration(7, "days")))) {
            return target.format(`ddd ${DateUtils.preferredTimeFormat(preferences)}`);
        } else if (target.year() == now.year()) {
            return target.format(`${DateUtils.preferredDateFormat(preferences, "medium", false)} ${DateUtils.preferredTimeFormat(preferences)}`);
        } else {
            return target.format(`${DateUtils.preferredDateFormat(preferences, "medium")} ${DateUtils.preferredTimeFormat(preferences)}`);
        }
    }

    public static bryxNow(): Date {
        const systemNow = new Date();
        return new Date(systemNow.getTime() + DateUtils.clockOffset);
    }
}
