import {BryxApi, AccountApi, ManagementApi, SupportApi} from "@bryxinc/lunch/utils";
import { BryxApi as OldApi } from "./bryxApi";

export class ApiRoot {
    static bryxApi = new BryxApi(
        OldApi.patchedConfig,
        OldApi.patchedLunchLocal
    );
    static accountApi = new AccountApi(
        OldApi.patchedConfig,
        OldApi.patchedLunchLocal
    );
    static managementApi = new ManagementApi(
        OldApi.patchedConfig,
        OldApi.patchedLunchLocal
    );
    static supportApi = new SupportApi(
        OldApi.patchedConfig,
        OldApi.patchedLunchLocal
    );
}

export function useApi(key: "bryx" | "account" | "management" | "support"): BryxApi | AccountApi | ManagementApi | SupportApi {
    return ApiRoot[key + "Api"];
}

export function useBryxApi(): BryxApi {
    return ApiRoot.bryxApi;
}

export function useAccountApi(): AccountApi {
    return ApiRoot.accountApi;
}

export function useManagementApi(): ManagementApi {
    return ApiRoot.managementApi;
}

export function useSupportApi(): SupportApi {
    return ApiRoot.supportApi;
}