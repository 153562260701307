import { Delete, AlternateEmail, Add, Sms } from "@mui/icons-material";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { Segment, Button, Tab, Input, Modal, Loader, Dimmer } from "semantic-ui-react";
import { SettingsSection, PhoneInput, ModalPaneProps } from "./util";
import React from "react";
import { validateEmail } from "../../../utils/functions";
import { useAccountApi } from "../../../utils/lunchHooks";
import { EmailRegistration, ClientSMSFeature } from "@bryxinc/lunch/models";
import { toast } from "react-toastify";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import { SmsAlertManager } from "./SmsAlertManager";

const STRIPE_KEY: string = "pk_test_Bjn4ofrBTfMUcx5k5Sw6iM0i00jHxB9aLj";
const stripePromise = loadStripe(STRIPE_KEY);

export function AlertItem(props: {
    id: string;
    address: string;
    onDelete: (id: string) => void;
}) {
    return (
        <Segment className="alert-item" id={props.id}>
            <span className="alert-name">{props.address}</span>
            <Button
                icon
                className="delete-alert"
                size="tiny"
                onClick={() => props.onDelete(props.id)}
            >
                <Delete />
            </Button>
        </Segment>
    );
}

export function AlertPane({ section, onChangeSection }: ModalPaneProps) {
    const [emailAlerts, setEmailAlerts] = useState<EmailRegistration[]>([]);
    const [newEmail, setNewEmail] = useState<string>("");
    const [addLoading, setAddLoading] = useState(false);

    const [paymentClientSecret, setPaymentClientSecret] = useState<string | null>(null);

    const account = useAccountApi();

    function loadEmails() {
        account.getEmailRegistrations((result) => {
            if (result.success) {
                setEmailAlerts(result.value);
                setAddLoading(false);
            }
        });
    }

    useEffect(() => {
        loadEmails();
        account.generateSmsFeatureSecret((result) => {
            if (result.success) {
                setPaymentClientSecret(result.value.clientSecret);
            } else {
                console.error("Failed to load Stripe secret");
            }
        });
    }, []);

    const [deletingEmail, setDeletingEmail] =
        useState<EmailRegistration | null>(null);

    return (
        <Tab.Pane className="settings-tab alerts" attached={false}>
            <Segment.Group>
                <SettingsSection
                    section="email"
                    icon={<AlternateEmail />}
                    open={section == "alert.email"}
                    onToggle={(open) =>
                        onChangeSection(open ? "alert.email" : null)
                    }
                >
                    <Input
                        placeholder="john.doe@example.com"
                        className="alert-input email"
                        value={newEmail}
                        onChange={(event) => setNewEmail(event.target.value)}
                        error={!validateEmail(newEmail) && newEmail.length > 0}
                        disabled={addLoading}
                    />
                    <Button
                        icon
                        className="alert-add"
                        disabled={!validateEmail(newEmail) || addLoading}
                        loading={addLoading}
                        onClick={() => {
                            setAddLoading(true);
                            account.createEmailRegistration(
                                newEmail,
                                (result) => {
                                    if (result.success) {
                                        setNewEmail("");
                                        loadEmails();
                                        toast.success(
                                            i18n.t(
                                                "account.sections.email.success"
                                            )
                                        );
                                    } else {
                                        if (
                                            result.message ==
                                            "Please provide a valid email"
                                        ) {
                                            toast.error(
                                                i18n.t(
                                                    "account.sections.email.failureInvalid"
                                                )
                                            );
                                        } else {
                                            toast.error(
                                                i18n.t(
                                                    "account.sections.email.failure"
                                                )
                                            );
                                        }
                                    }
                                }
                            );
                        }
                        }
                    >
                        <Add />
                    </Button>
                    <Segment.Group className="alert-list">
                        {emailAlerts.length > 0 &&
                            emailAlerts.map((emailAlert, index) => (
                                <AlertItem
                                    key={index}
                                    id={emailAlert.id}
                                    address={emailAlert.email}
                                    onDelete={(id: string) =>
                                        setDeletingEmail(emailAlert)
                                    }
                                />
                            ))}
                        {emailAlerts.length == 0 && (
                            <Segment className="alert-item no-alerts">
                                {i18n.t("account.sections.alerts.none")}
                            </Segment>
                        )}
                    </Segment.Group>
                </SettingsSection>
                <SettingsSection
                    section="sms"
                    icon={<Sms />}
                    open={section == "alert.sms"}
                    onToggle={(open) =>
                        onChangeSection(open ? "alert.sms" : null)
                    }
                >
                    {paymentClientSecret ? (
                        <Elements
                            stripe={stripePromise}
                            options={{ clientSecret: paymentClientSecret }}
                        >
                            <SmsAlertManager />
                        </Elements>
                    ) : (
                        <Loader active inline="centered" />
                    )}
                </SettingsSection>
            </Segment.Group>
            <Modal
                className="delete-email-registration"
                open={!!deletingEmail}
                onClose={() => setDeletingEmail(null)}
            >
                <Modal.Header>
                    {i18n.t("account.sections.email.delete.title")}
                </Modal.Header>
                <Modal.Content>
                    {i18n.t("account.sections.email.delete.message", {
                        email: deletingEmail && deletingEmail.email,
                    })}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        className="cancel"
                        onClick={() => setDeletingEmail(null)}
                    >
                        {i18n.t("general.cancel")}
                    </Button>
                    <Button
                        className="confirm"
                        onClick={() => {
                            if (deletingEmail) {
                                account.removeEmailRegistration(
                                    deletingEmail.id,
                                    () => {
                                        toast.success(
                                            i18n.t(
                                                "account.sections.email.delete.toast"
                                            )
                                        );
                                        loadEmails();
                                    }
                                );
                                setDeletingEmail(null);
                            }
                        }}
                    >
                        {i18n.t("general.ok")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Tab.Pane>
    );
}
