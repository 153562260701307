import * as i18n from "i18next";
import * as React from 'react';
import {Button, Header, Popup} from "semantic-ui-react";
import {Group} from "../models/group";
import {GroupMember} from "../models/groupMember";
import {DateUtils} from "../utils/dateUtils";

interface MembersPopupProps {
    group: Group;
}

interface MembersPopupState {
    currentTime: Date;
}

export class MembersPopup extends React.Component<MembersPopupProps, MembersPopupState> {
    private timerId: NodeJS.Timer | null = null;

    constructor(props: MembersPopupProps, context: any) {
        super(props, context);
        this.state = {
            currentTime: DateUtils.bryxNow(),
        };
    }

    componentDidMount() {
        this.timerId = setInterval(
            () => this.setState({currentTime: DateUtils.bryxNow()}),
            1000,
        );
    }

    componentWillUnmount() {
        if (this.timerId != null) {
            clearInterval(this.timerId);
        }
    }

    render() {
        const group = this.props.group;

        const membersItems = group.members.slice().sort(GroupMember.compare).map(m => {
            let lastSeenMessage;
            if (group.latestMessage == null || m.readUntil.getTime() >= group.latestMessage.timeSent.getTime()) {
                lastSeenMessage = i18n.t("messages.upToDate");
            } else if (m.readUntil.getTime() > 0) {
                lastSeenMessage = i18n.t("messages.lastSeenX", {
                    ago: DateUtils.duration(m.readUntil, this.state.currentTime).humanize(true),
                });
            } else {
                lastSeenMessage = i18n.t("messages.noLastSeen");
            }

            return (
                <div className="members-item" key={m.id}>
                    {m.name}
                    <div className="last-seen-message">
                        {lastSeenMessage}
                    </div>
                </div>
            );
        });

        const membersButton = (
            <Button
                compact
                icon="users"
                content={i18n.t("messages.members")}
                label={group.members.length.toString()}/>
        );

        return (
            <Popup
                className="members-popup"
                on="click"
                position="bottom right"
                trigger={membersButton}>
                <Popup.Header className="header-container">
                    <Header className="header" as="h3">{i18n.t("messages.members")}</Header>
                </Popup.Header>
                <div className="members-items-container">
                    {membersItems}
                </div>
            </Popup>
        );
    }
}
