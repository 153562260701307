import i18n from "i18next";
import React, { ReactNode, useState } from "react";
import { Input, Segment, Accordion } from "semantic-ui-react";
import { IMaskInput } from "react-imask";

export function PhoneInput(props: {
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
}) {
    const { value, onChange, disabled } = props;
    return (
        <Input
            className="alert-input"
            error={value.includes("_") && value != "+_ (___) ___-____"}
            disabled={disabled}
        >
            <IMaskInput
                value={value}
                onAccept={onChange}
                mask={"+0 (000) 000-0000"}
                lazy={false}
                unmask={true}
            />
        </Input>
    );
}

function camelToKebab(str: string): string {
    return str
        .split("")
        .map((char: string) =>
            char.toLowerCase() == char ? char : "-" + char.toLowerCase()
        )
        .join("");
}

export function SettingsSection({section, icon, children, open, onToggle}: {
    section: string;
    icon: ReactNode;
    children?: ReactNode | ReactNode[];
    open: boolean;
    onToggle: (open: boolean) => void;
}) {
    return (
        <Segment >
            <Accordion
                fluid
                className={
                    "account-management-section " +
                    camelToKebab(section)
                        
                }
            >
                <Accordion.Title
                    onClick={() => onToggle(!open)}
                    active={open}
                >
                    {icon}
                    {i18n.t(`account.sections.${section}.title`)}
                </Accordion.Title>
                <Accordion.Content active={open}>
                    {children}
                </Accordion.Content>
            </Accordion>
        </Segment>
    );
}

export type AccountSections = "account.password" | "account.disable" | "agency.view" | "agency.join" | "alert.email" | "alert.sms";

export type ModalPaneProps = {
    section: AccountSections | null;
    onChangeSection: (section: AccountSections | null) => void;
};