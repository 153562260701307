import * as i18n from "i18next";
import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Redirect} from "react-router-dom";
import {Icon, Label, Loader, Menu} from "semantic-ui-react";
import {ForcedResponseModal, ForcedResponsePresentedStatus} from "../../components/modals/forcedResponseModal";
import {ZeroStateView} from "../../components/views/zeroStateView";
import {config} from "../../config";
import {FullJob, RespondPermission} from "../../models/job";
import {ResponseOptionType} from "../../models/responder";
import {JobManager, JobManagerActiveJobObserver} from "../../utils/jobManager";
import {NotificationManager} from "../../utils/notificationManager";
import {JobDetailTab} from "./jobDetailTab";
import {JobHistoricalTab} from "./jobHistoricalTab";
import {JobPatientsTab} from "./jobPatientsTab";
import {JobRespondersTab} from "./jobRespondersTab";
import {JobSiteSurveyTab} from "./jobSiteSurveyTab";
import {JobsPageMatchParams} from "./jobsPage";
import {SpecificPatientView, SpecificPatientViewStatus} from "./patients/specificPatientView";

type JobInfoTabType = "details" | "responders" | "historical" | "site-survey" | "patients";

interface JobInfoState {
    status: { key: "unset" } |
        { key: "loading" } |
        {
            key: "active",
            job: FullJob,
            forcedResponseStatus: ForcedResponsePresentedStatus,
        } |
        { key: "failed", message: string };
}

export class JobInfoView extends React.Component<RouteComponentProps<JobsPageMatchParams>, JobInfoState> implements JobManagerActiveJobObserver {
    constructor(props: RouteComponentProps<JobsPageMatchParams>, context: any) {
        super(props, context);

        if (props.match.params.jobId == null) {
            this.state = {
                status: {
                    key: "unset",
                },
            };
        } else {
            this.state = {
                status: {
                    key: "loading",
                },
            };
        }
    }

    componentDidMount() {
        JobManager.shared.registerActiveJobObserver(this);
        if (this.props.match.params.jobId != null) {
            JobManager.shared.setActiveJob(this.props.match.params.jobId);
        }
    }

    componentWillUnmount() {
        if (this.props.match.params.jobId != null) {
            JobManager.shared.clearActiveJob(this.props.match.params.jobId);
        }
        JobManager.shared.unregisterActiveJobObserver(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: RouteComponentProps<JobsPageMatchParams>, nextContext: any) {
        if (nextProps.match.params.jobId == this.props.match.params.jobId) {
            return;
        } else if (nextProps.match.params.jobId == null) {
            this.setState({status: {key: "unset"}});
            if (this.props.match.params.jobId != null) {
                JobManager.shared.clearActiveJob(this.props.match.params.jobId);
            }
        } else {
            this.setState({status: {key: "loading"}});
            if (this.props.match.params.jobId != null) {
                JobManager.shared.clearActiveJob(this.props.match.params.jobId);
            }
            JobManager.shared.setActiveJob(nextProps.match.params.jobId);
        }
    }

    // JobManagerActiveJobObserver Functions

    private setJob(job: FullJob) {
        this.setState(prevState => {
            let forcedResponseStatus: ForcedResponsePresentedStatus;
            if (prevState.status.key == "active" && prevState.status.job.id == job.id) {
                forcedResponseStatus = job.hasResponded ? {key: "dismissed"} : prevState.status.forcedResponseStatus;
                job.siteSurvey = prevState.status.job.siteSurvey;
            } else {
                forcedResponseStatus = (job.respondPermission == RespondPermission.forced && !job.hasResponded) ? {
                    key: "presented",
                    job: job,
                } : {key: "dismissed"};
                job.loadSiteSurvey(result => {
                    if (result.success == false) {
                        config.warn(`Failed to load site survey for job@${job.id}: ${result.debugMessage}`);
                    }
                    this.setState(innerPrevState => {
                        if (innerPrevState.status.key == "active") {
                            // Site Survey object already set, just reload job
                            innerPrevState.status.job = job;
                        }
                        return innerPrevState;
                    });
                });
                NotificationManager.shared.notifyReadJob(job.id);
            }
            return {
                status: {
                    key: "active",
                    job: job,
                    forcedResponseStatus: forcedResponseStatus,
                },
            };
        });
    }

    jobManagerDidReceiveActiveJob(job: FullJob): void {
        this.setJob(job);
    }

    jobManagerDidUpdateActiveJobSupplementals(activeJob: FullJob): void {
        this.setJob(activeJob);
    }

    jobManagerDidUpdateActiveJobResponders(activeJob: FullJob): void {
        this.setJob(activeJob);
    }

    jobManagerDidUpdateActiveJobHydrants(activeJob: FullJob): void {
        this.setJob(activeJob);
    }

    jobManagerDidUpdateActiveJobAssignments(activeJob: FullJob): void {
        this.setJob(activeJob);
    }

    jobManagerDidUpdateActiveJobPatients(activeJob: FullJob): void {
        this.setJob(activeJob);
    }

    jobManagerDidFailToLoadActiveJob(errorMessage: string): void {
        config.warn(`Failed to load job@${this.props.match.params.jobId}: ${errorMessage}`);
        this.setState({
            status: {
                key: "failed",
                message: errorMessage,
            },
        });
    }

    render() {
        let content;
        if (this.state.status.key == "loading") {
            content = (
                <div className="loading-content">
                    <Menu className="job-info-view-menu" pointing secondary>
                        <Menu.Item name={i18n.t("jobs.jobInfo.tabs.details")} active/>
                        <Menu.Item name={i18n.t("jobs.jobInfo.tabs.responders")}/>
                        <Menu.Menu position="right">
                            <Menu.Item className="cancel-icon-container">
                                <Icon className="cancel-icon" link size="large" name="x"/>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    <div className="loader-container">
                        <Loader inline active/>
                    </div>
                </div>
            );
        } else if (this.state.status.key == "failed") {
            content = <Redirect to="/jobs"/>;
        } else if (this.state.status.key == "unset") {
            content = (
                <div className="zero-state-content">
                    <ZeroStateView header={i18n.t("jobs.jobInfo.noSelectedJob")}
                                   subheader={i18n.t("jobs.jobInfo.selectAJob")}/>
                </div>
            );
        } else {
            const {job, forcedResponseStatus} = this.state.status;
            const availableTabs: JobInfoTabType[] = ["details", "responders"];
            if (job.hasHistorical) {
                availableTabs.push("historical");
            }
            if (job.siteSurvey != null) {
                availableTabs.push("site-survey");
            }
            if (job.canHospitalAlert) {
                availableTabs.push("patients");
            }

            if (this.props.match.params.tabId == null || (availableTabs as string[]).indexOf(this.props.match.params.tabId) == -1) {
                return <Redirect to={`/jobs/${this.props.match.params.jobId}/${availableTabs[0]}`}/>;
            }

            const tabId = this.props.match.params.tabId as JobInfoTabType;
            let tabContent;
            switch (tabId) {
                case "details":
                    tabContent = <JobDetailTab job={job}/>;
                    break;
                case "responders":
                    tabContent = <JobRespondersTab responders={job.responders}/>;
                    break;
                case "site-survey":
                    tabContent = <JobSiteSurveyTab job={job}/>;
                    break;
                case "historical":
                    tabContent = <JobHistoricalTab job={job}/>;
                    break;
                case "patients":
                    tabContent = (
                        <JobPatientsTab
                            patients={job.patients}
                            job={job}
                            onSelectPatient={(patientId, initialCreationPrompts) => {
                                this.props.history.push(`/jobs/${job.id}/patients/${patientId}`, {prompts: initialCreationPrompts});
                            }}
                        />
                    );
                    break;
            }

            let specificPatientViewStatus: SpecificPatientViewStatus;
            if (this.props.match.params.tabId == "patients" && this.props.match.params.contentId != null) {
                specificPatientViewStatus = {key: "shown", patientId: this.props.match.params.contentId};
            } else {
                specificPatientViewStatus = {key: "hidden"};
            }

            content = (
                <div className="job-info-view-menu-container">
                    <Menu className="job-info-view-menu" pointing secondary>
                        {availableTabs.map(tabType => {
                            let tabTitle;
                            switch (tabType) {
                                case "details":
                                    tabTitle = i18n.t("jobs.jobInfo.tabs.details");
                                    break;
                                case "responders":
                                    tabTitle = i18n.t("jobs.jobInfo.tabs.responders");
                                    break;
                                case "site-survey":
                                    tabTitle = i18n.t("jobs.jobInfo.tabs.siteSurvey");
                                    break;
                                case "historical":
                                    tabTitle = i18n.t("jobs.jobInfo.tabs.historical");
                                    break;
                                case "patients":
                                    tabTitle = i18n.t("jobs.jobInfo.tabs.patients");
                                    break;
                            }
                            return (
                                <Menu.Item
                                    key={tabType}
                                    active={tabId == tabType}
                                    onClick={() => {
                                        this.props.history.push(`/jobs/${this.props.match.params.jobId}/${tabType}`);
                                    }}>
                                    {tabTitle}{tabType == "responders" ? <Label
                                    size="tiny"
                                >
                                    {job.responders.filter(r => r.currentResponse.responseOption.type == ResponseOptionType.positive).length}</Label> : null}
                                </Menu.Item>
                            );
                        })}
                        <Menu.Menu position="right">
                            <Menu.Item className="cancel-icon-container">
                                <Icon
                                    className="cancel-icon"
                                    link
                                    size="large"
                                    name="x"
                                    onClick={() => this.props.history.push("/jobs")}
                                />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    {tabContent}
                    <ForcedResponseModal
                        presentedStatus={forcedResponseStatus}
                        onClose={() => this.setState(prevState => {
                            if (prevState.status.key == "active") {
                                prevState.status.forcedResponseStatus = {key: "dismissed"};
                            }
                            return prevState;
                        })}
                    />
                    <SpecificPatientView
                        viewStatus={specificPatientViewStatus}
                        alertingHospitals={job.hospitals}
                        prompts={this.props.history.location.state && (this.props.history.location.state as any).prompts}
                        onDismissPrompts={() => {
                            if (specificPatientViewStatus.key == "shown") {
                                this.props.history.replace(`/jobs/${job.id}/patients/${specificPatientViewStatus.patientId}`, {prompts: null});
                            }
                        }}
                        onDismiss={() => this.props.history.replace(`/jobs/${job.id}/patients`)}
                    />
                </div>
            );
        }

        return (
            <div className="job-info-view">
                {content}
            </div>
        );
    }
}
