import * as i18n from 'i18next';
import {Options} from "i18next";
import * as XHR from 'i18next-xhr-backend';
import * as React from 'react';
import {Redirect, RouteComponentProps} from "react-router";
import {Button, Divider, Header, Icon} from "semantic-ui-react";
import {config} from "../config";
import {BryxLocal} from "../utils/bryxLocal";
import {DeviceUtils} from "../utils/deviceUtils";
import {UpdateManager} from "../utils/updateManager";

interface BadBrowserState {
    loading: boolean;
}

export class BadBrowser extends React.Component<RouteComponentProps<{}>, BadBrowserState> {
    constructor(props: any, context: any) {
        super(props, context);

        if (!DeviceUtils.deviceIsSupported) {
            i18n
                .use(XHR)
                .init({
                    lng: BryxLocal.getItem<string>("locale") || "en-US",
                    backend: {
                        loadPath: "/public/locales/{{lng}}.json",
                    },
                    fallbackLng: "en",
                } as Options, () => {
                    this.i18nLoaded();
                });
        }

        this.state = {
            loading: true,
        };
    }

    private i18nLoaded() {
        this.setState({
            loading: false,
        });
    }

    render() {
        if (DeviceUtils.deviceIsSupported) {
            return (
                <Redirect to="/login"/>
            );
        }

        let content;
        if (DeviceUtils.deviceIsMobile) {
            content = (
                <div className="unsupported-browser-content">
                    <Header className="unsupported-browser-content-header">
                        {i18n.t("badBrowser.mobile.statement")}
                    </Header>
                    <Divider className="whiteDivider" />
                    <Header className="download-now">
                        {i18n.t("badBrowser.mobile.downloadNow")}
                    </Header>
                    <div className="download-icons-container">
                        <a href="https://itunes.apple.com/us/app/bryx-911/id813078029" style={{textDecoration: "none"}}>
                            <Icon className="download-icon" name="apple"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.bryx.bryx911"
                           style={{textDecoration: "none"}}>
                            <Icon className="download-icon" name="android"/>
                        </a>
                    </div>
                </div>
            );
        } else {
            content = (
                <div className="unsupported-browser-content">
                    <Header style={{
                        fontSize: "32px",
                        color: "#f1f1f1",
                        textAlign: "center",
                        letterSpacing: "1px",
                    }}>
                        {i18n.t("badBrowser.unsupportedBrowser.statement")}
                    </Header>
                    <Divider className="whiteDivider"
                             style={{width: "40%", margin: "82px auto 60px auto"}}/>
                    <Header style={{color: "#f1f1f1", fontSize: "24px"}} href="https://downloads.bryx.com/ua">
                        {i18n.t("badBrowser.unsupportedBrowser.downloadNow")}
                    </Header>
                </div>
            );
        }

        return (
            <div className="bad-browser">
                <div className="flexCenteredContainer whole-page-container">
                    <div className="text-centered-container">
                        <img id="signinLogo" src="/public/assets/logo_white.png"/>
                        {!this.state.loading ? content : undefined}
                        {config.configType == "dev" ? (
                            <Button
                                style={{marginTop: "20px"}}
                                onClick={() => {
                                    BryxLocal.setForceDeviceSupport(true);
                                    UpdateManager.shared.update();
                                }}>
                                {i18n.t("badBrowser.proceedAnyway")}
                            </Button>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
