import {ParseResult, ParseUtils} from "../utils/cerealParse";

export class InformationalPair {
    private constructor(
        public key: string,
        public value: string,
    ) {}

    static parse(o: any): ParseResult<InformationalPair> {
        try {
            return ParseUtils.parseSuccess(new InformationalPair(
                ParseUtils.getString(o, "key"),
                ParseUtils.getString(o, "value"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<InformationalPair>(`Invalid InformationalPair Model: ${e.message}`);
        }
    }
}

export class SiteSurvey {
    private constructor(
        public critical: string | null,
        public advisory: string[],
        public informational: InformationalPair[],
    ) {}

    static parse(o: any): ParseResult<SiteSurvey> {
        try {
            return ParseUtils.parseSuccess(new SiteSurvey(
                ParseUtils.getStringOrNull(o, "critical"),
                ParseUtils.getArray(o, "advisory"),
                ParseUtils.getArrayOfSubobjects(o, "informational", InformationalPair.parse, "warn"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<SiteSurvey>(`Invalid SiteSurvey Model: ${e.message}`);
        }
    }
}
