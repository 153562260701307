import * as i18n from "i18next";
import * as React from 'react';
import Linkify from "react-linkify";
import {Button, Header, Segment} from "semantic-ui-react";
import {Supplemental, SupplementalSortOrder} from "../../models/supplemental";
import {BryxLocal} from "../../utils/bryxLocal";
import {DateUtils} from "../../utils/dateUtils";
import {BryxPreferences} from "../../utils/preferenceManager";
import {KeyValueView} from "./keyValueView";

interface JobSupplementalsViewProps {
    supplementals: Supplemental[] | null;
    preferences: BryxPreferences;
    style?: any;
}

interface JobSupplementalsViewState {
    sortOrder: SupplementalSortOrder;
}

export class JobSupplementalsView extends React.Component<JobSupplementalsViewProps, JobSupplementalsViewState> {

    public constructor(props: JobSupplementalsViewProps) {
        super(props);

        const savedSortOrder = BryxLocal.getSupplementalSortOrder();
        this.state = {
            sortOrder: savedSortOrder != null ? savedSortOrder : SupplementalSortOrder.ascending,
        };
    }

    private getOrderedSupplementals(): Supplemental[] | null {
        if (this.props.supplementals == null) {
            return null;
        } else {
            const ascendingSupplementals = this.props.supplementals.slice().sort(Supplemental.compare);
            return this.state.sortOrder == SupplementalSortOrder.ascending ? ascendingSupplementals : ascendingSupplementals.reverse();
        }
    }

    private sortToggle() {
        const newSortOrder = this.state.sortOrder == SupplementalSortOrder.ascending ? SupplementalSortOrder.descending : SupplementalSortOrder.ascending;
        BryxLocal.setSupplementalSortOrder(newSortOrder);
        this.setState({
            sortOrder: newSortOrder,
        });
    }

    render() {
        const preferences = this.props.preferences;
        const supplementals = this.getOrderedSupplementals();
        return (
            <div className="job-supplementals-view" style={this.props.style}>
                <Header className="supplementals-header bryx-text" as="h3" attached="top">
                    {i18n.t("jobs.jobInfo.supplementals.title")}
                    <div className="sort-toggle-container">
                        {supplementals != null ? (
                            <Button
                                className="sort-toggle-button"
                                icon={this.state.sortOrder == SupplementalSortOrder.descending ? "caret down" : "caret up"}
                                onClick={() => this.sortToggle()}/>
                        ) : null}
                    </div>
                </Header>
                {
                    supplementals == null || supplementals.length == 0 ? (
                        <Segment className="supplementals-segment" attached>
                            <Header as="h4" color="grey"
                                    textAlign="center">{i18n.t("jobs.jobInfo.supplementals.noSupplementals")}</Header>
                        </Segment>
                    ) : (
                        <Segment className="supplementals-segment" attached>
                            {supplementals.map(supplemental => (
                                <KeyValueView
                                    key={supplemental.id}
                                    keyNode={DateUtils.formatTime(supplemental.creationTime, preferences)}
                                    valueNode={
                                        <Linkify properties={{target: "_blank"}}>
                                            {supplemental.text}
                                        </Linkify>
                                    }/>
                            ))}
                        </Segment>
                    )
                }
            </div>
        );
    }
}
