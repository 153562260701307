import * as i18n from "i18next";
import * as React from 'react';
import {Button, Header, Message, Modal} from 'semantic-ui-react';
import {config} from "../../config";
import {FullJob} from "../../models/job";
import {ResponseOption, ResponseOptionType} from "../../models/responder";
import {BryxApi} from "../../utils/bryxApi";
import {BryxColors} from "../../utils/bryxColors";
import {JobBasicInfoView} from "../views/jobBasicInfoView";

export type ForcedResponsePresentedStatus = { key: "presented", job: FullJob } | { key: "dismissed" };

interface ForcedResponseModalProps {
    presentedStatus: ForcedResponsePresentedStatus;
    onClose: () => void;
}

interface ForcedResponseModalState {
    status: { key: "ready" } | { key: "loading", option: ResponseOption } | { key: "failed", message: string };
}

export class ForcedResponseModal extends React.Component<ForcedResponseModalProps, ForcedResponseModalState> {
    constructor(props: ForcedResponseModalProps, context: any) {
        super(props, context);
        this.state = {
            status: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: ForcedResponseModalProps) {
        const presentationChanged = this.props.presentedStatus.key != nextProps.presentedStatus.key;
        const jobChanged = this.props.presentedStatus.key == "presented" &&
            nextProps.presentedStatus.key == "presented" &&
            this.props.presentedStatus.job.id != nextProps.presentedStatus.job.id;
        if (presentationChanged || jobChanged) {
            this.setState({status: {key: "ready"}});
        }
    }

    private renderContent() {
        const {presentedStatus, onClose} = this.props;
        if (presentedStatus.key != "presented") {
            return null;
        }
        const job = presentedStatus.job;
        const {status} = this.state;
        return (
            <Modal.Content>
                <Header as="h3" className="modal-header">{job.synopsis}</Header>
                <div className="body-container">
                    <JobBasicInfoView job={job} style={{margin: "0 20px", flex: 3}}/>
                    <div className="job-response-options-container">
                        {job.responseOptions.map(o => {
                            const isLoading = status.key == "loading";
                            const isLoadingCurrentOption = status.key == "loading" && status.option.isEqual(o);
                            return (
                                <Button
                                    className="job-button"
                                    key={o.id + o.type + o.text}
                                    content={o.text}
                                    style={{backgroundColor: o.type == ResponseOptionType.positive ? BryxColors.green : undefined}}
                                    color={o.type == ResponseOptionType.negative ? "red" : undefined}
                                    disabled={isLoading}
                                    loading={isLoadingCurrentOption}
                                    onClick={() =>
                                        this.setState({
                                            status: {key: "loading", option: o},
                                        }, () => {
                                            BryxApi.respondToJob(job.id, o, result => {
                                                if (result.success == true) {
                                                    this.setState({status: {key: "ready"}}, () => onClose());
                                                } else {
                                                    config.warn(`Failed to respond from forced response to job@${job.id}: ${result.debugMessage}`);
                                                    this.setState({
                                                        status: {
                                                            key: "failed",
                                                            message: result.message,
                                                        },
                                                    });
                                                }
                                            });
                                        })
                                    }
                                />
                            );
                        })}
                    </div>
                </div>
                {status.key == "failed" ? (
                    <Message className="error-message" error>
                        <Message.Content className="error-content">
                            <div>
                                <Header>{i18n.t("jobs.jobInfo.details.failedToRespond")}</Header>
                                {status.message}
                            </div>
                            <Button content={i18n.t("jobs.jobInfo.forcedResponse.skipResponse")}
                                    onClick={() => {
                                        config.warn(`Skipping forced response because of error for job@${job.id}`);
                                        this.setState({status: {key: "ready"}}, () => this.props.onClose());
                                    }}/>
                        </Message.Content>
                    </Message>
                ) : null}
            </Modal.Content>
        );
    }

    render() {
        const {presentedStatus, onClose} = this.props;
        return (
            <Modal className="forced-response-modal" open={presentedStatus.key == "presented"}>
                <Modal.Header className="forced-response-header">
                    {i18n.t("jobs.jobInfo.forcedResponse.selectResponse")}
                </Modal.Header>
                {this.renderContent()}
                {config.configType != "prod" ? (
                    <Modal.Actions>
                        <Button content="Close (DEV)"
                                onClick={() => this.setState({status: {key: "ready"}}, () => onClose())}/>
                    </Modal.Actions>
                ) : null}
            </Modal>
        );
    }
}
