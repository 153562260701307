import * as i18n from "i18next";
import * as Qty from "js-quantities";
import * as React from "react";
import {Button, Icon, Label, Modal, Popup, Segment} from "semantic-ui-react";
import {BryxGeoJSONLayer} from "../../components/bryxGeoJSONLayer";
import {BryxMap} from "@bryxinc/ui";
import {ZeroStateView} from "../../components/views/zeroStateView";
import {Responder, Response, ResponseOptionType} from "../../models/responder";
import {DateUtils} from "../../utils/dateUtils";
import {normalizedQuantity} from "../../utils/functions";
import {GeoUtils} from "../../utils/geoUtils";
import {BryxPreferences, PreferenceManager, PreferenceManagerObserver} from "../../utils/preferenceManager";

interface JobRespondersProps {
    responders: Responder[];
}

interface JobRespondersState {
    preferences: BryxPreferences;
    mapModalStatus: { key: "hidden" } | { key: "shown", responder: Responder };
}

export class JobRespondersTab extends React.Component<JobRespondersProps, JobRespondersState> implements PreferenceManagerObserver {
    private timerId: number | null = null;

    constructor(props: JobRespondersProps, context: any) {
        super(props, context);

        this.state = {
            preferences: PreferenceManager.shared.preferences,
            mapModalStatus: {key: "hidden"},
        };
    }

    componentDidMount() {
        PreferenceManager.shared.registerObserver(this);
    }

    componentWillUnmount() {
        if (this.timerId != null) {
            clearInterval(this.timerId);
        }
        PreferenceManager.shared.unregisterObserver(this);
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void {
        this.setState({preferences: newPrefs});
    }

    render() {
        if (this.props.responders.length == 0) {
            return (
                <div className="zero-state-view-jrt">
                    <ZeroStateView
                        icon="shipping"
                        header={i18n.t("jobs.jobInfo.responders.noResponders")}
                    />
                </div>
            );
        }
        const positiveResponders = this.props.responders
            .filter(r => r.currentResponse.responseOption.type == ResponseOptionType.positive)
            .sort((a, b) => Response.compare(a.currentResponse, b.currentResponse));
        const negativeResponders = this.props.responders
            .filter(r => r.currentResponse.responseOption.type == ResponseOptionType.negative)
            .sort((a, b) => Response.compare(a.currentResponse, b.currentResponse));
        let mapModalContent: any = null;
        let mapModalActions: any = null;
        if (this.state.mapModalStatus.key == "shown") {
            const responder = this.state.mapModalStatus.responder;
            const responderLocation = responder.location!;
            mapModalContent = (
                <Modal.Content>
                    <BryxMap
                        >
                        <BryxGeoJSONLayer geojson={responderLocation}/>
                    </BryxMap>
                </Modal.Content>
            );
            const urlInfo = GeoUtils.directionsUrl(responderLocation, this.state.preferences);
            mapModalActions = (
                <Modal.Actions>
                    <Button
                        basic
                        color='green'
                        inverted
                        href={urlInfo.url}
                        target={urlInfo.newWindow ? "_blank" : undefined}>
                        <Icon name='location arrow'/> {i18n.t("general.navigate")}
                    </Button>
                    <Button inverted onClick={() => this.setState({mapModalStatus: {key: "hidden"}})}>
                        {i18n.t("general.done")}
                    </Button>
                </Modal.Actions>
            );
        }
        return (
            <div className="job-responders-tab">
                {
                    positiveResponders.length != 0 ? (
                        <Segment.Group>
                            <Label attached="top" content={i18n.t("jobs.jobInfo.responders.positive")}/>
                            {positiveResponders.map(responder => (
                                <ResponderItemView
                                    key={responder.id}
                                    responder={responder}
                                    preferences={this.state.preferences}
                                    onClickLocation={() => this.setState({
                                        mapModalStatus: {
                                            key: "shown",
                                            responder: responder,
                                        },
                                    })}
                                />
                            ))}
                        </Segment.Group>
                    ) : null
                }
                {
                    negativeResponders.length != 0 ? (
                        <Segment.Group>
                            <Label attached="top" content={i18n.t("jobs.jobInfo.responders.negative")}/>
                            {negativeResponders.map(responder => (
                                <ResponderItemView
                                    key={responder.id}
                                    responder={responder}
                                    preferences={this.state.preferences}
                                    onClickLocation={() => this.setState({
                                        mapModalStatus: {
                                            key: "shown",
                                            responder: responder,
                                        },
                                    })}
                                />
                            ))}
                        </Segment.Group>
                    ) : null
                }
                <Modal
                    open={this.state.mapModalStatus.key == "shown"}
                    basic
                    onClose={() => this.setState({mapModalStatus: {key: "hidden"}})}
                >
                    {mapModalContent}
                    {mapModalActions}
                </Modal>
            </div>
        );
    }
}

interface ResponderItemViewProps {
    responder: Responder;
    preferences: BryxPreferences;
    onClickLocation: () => void;
}

class ResponderItemView extends React.Component<ResponderItemViewProps, {}> {
    render() {
        const responder = this.props.responder;
        const preferences = this.props.preferences;
        const distanceQuantity = responder.distance != null ? normalizedQuantity(Qty(responder.distance, "m"), preferences) : null;
        const distanceString = distanceQuantity ? i18n.t("general.xAway", {replace: {distance: distanceQuantity}}) : i18n.t("jobs.jobInfo.responders.distanceUnknown");
        const timeString = DateUtils.formatDateTime(responder.currentResponse.responseTime, preferences);
        return (
            <Segment
                className={responder.currentResponse.responseOption.type == ResponseOptionType.positive ? "JobResponderItem_positive" : "JobResponderItem_negative"}>
                <div className="job-responders-content-container">
                    <div>
                        <span className="responder-name">{responder.name}</span>
                        <span className="response-option">{responder.currentResponse.responseOption.text}</span>
                        <span className="time-and-distance">{distanceString} &bull; {timeString}</span>
                    </div>
                    {responder.currentResponse.responseOption.type == ResponseOptionType.positive ? (
                        <div>
                            {responder.phone != null ? (
                                <Popup
                                    trigger={<Icon circular inverted link name="phone" size="large" color="grey"/>}
                                    content={this.props.responder.phone}
                                    on='click'
                                    position='top right'
                                />
                            ) : (
                                <Icon circular inverted disabled name="phone" size="large" color="grey"/>
                            )}
                            <Icon
                                circular
                                inverted
                                name="location arrow"
                                size="large"
                                color="grey"
                                link={this.props.responder.location != null}
                                disabled={this.props.responder.location == null}
                                style={{marginLeft: "10px"}}
                                onClick={() => {
                                    if (this.props.responder.location != null) {
                                        this.props.onClickLocation();
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <div/>
                    )}
                </div>
            </Segment>
        );
    }
}
