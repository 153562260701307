import {ParseResult, ParseUtils} from "../utils/cerealParse";

export enum SupplementalSortOrder {
    ascending, descending,
}

export class Supplemental {
    private constructor(
        public id: string,
        public creationTime: Date,
        public text: string,
    ) {}

    static parse(o: any): ParseResult<Supplemental> {
        try {
            return ParseUtils.parseSuccess(new Supplemental(
                ParseUtils.getString(o, "id"),
                ParseUtils.getUNIXTimestampDate(o, "ts"),
                ParseUtils.getString(o, "text"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Supplemental>(`Invalid Supplemental Model: ${e.message}`);
        }
    }

    static compare(s1: Supplemental, s2: Supplemental): number {
        return s1.creationTime.getTime() - s2.creationTime.getTime() || s1.id.localeCompare(s2.id);
    }

    isEqual(supplemental: Supplemental): boolean {
        return this.id == supplemental.id &&
            this.creationTime.getTime() == supplemental.creationTime.getTime() &&
            this.text == supplemental.text;
    }
}
