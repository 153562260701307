import * as base64 from 'base-64';
import * as utf8 from 'utf8';
import {config} from "../config";
import {BryxLocal} from "./bryxLocal";
import {DeviceUtils} from "./deviceUtils";

export class SupportUtils {

    public static bryxItemsAttachment(): string {
        return SupportUtils.utf8Base64Encode(JSON.stringify(BryxLocal.getBryxItems(), null, "    "));
    }

    public static logsAttachment(): string {
        return SupportUtils.utf8Base64Encode(config.log.exportToArray().join("\n"));
    }

    public static deviceInfoAttachment(): string {
        return SupportUtils.utf8Base64Encode(JSON.stringify(DeviceUtils.deviceInfo, null, "    "));
    }

    private static utf8Base64Encode(plaintext: string): string {
        const bytes = utf8.encode(plaintext);
        return base64.encode(bytes);
    }
}
