import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {ParseUtils} from "../utils/cerealParse";
import {Patient} from "./patient";

// PatientUpdate

export type PatientUpdateReplace = {
    key: "replace",
    patient: Patient;
};

export type PatientUpdate = PatientUpdateReplace;

export function parsePatientUpdate(message: BryxWebSocketMessage): ApiResult<PatientUpdate | null> {
    try {
        switch (message.key) {
            case "serverUpdate":
                return apiSuccess({
                    key: "replace",
                    patient: ParseUtils.getSubobject(message, "data", Patient.parse),
                } as PatientUpdateReplace);
            case "subscribeResponse":
                if (message.successful) {
                    return apiSuccess({
                        key: "replace",
                        patient: ParseUtils.getSubobject(message, "initialData", Patient.parse),
                    } as PatientUpdateReplace);
                } else {
                    return apiFailure(null, message.errorReason);
                }
            default:
                return apiSuccess(null);
        }
    } catch (e) {
        return apiFailure(null, `Invalid GroupUpdate Model: ${e.message}`);
    }
}
