import {ParseResult, ParseUtils} from "../utils/cerealParse";

export class ServerTime {
    private constructor(
        public time: Date,
    ) {}

    static parse(o: any): ParseResult<ServerTime> {
        try {
            return ParseUtils.parseSuccess(new ServerTime(
                ParseUtils.getUNIXTimestampDate(o, "ts"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<ServerTime>(`Invalid ServerTime Model: ${e.message}`);
        }
    }
}
