import {config} from "../config";
import {Disposition, FullJob, ListJob} from "../models/job";
import {JobsListServerUpdate} from "../models/jobsListUpdate";
import {BryxApi} from "./bryxApi";

export enum JobListType {
    open, closed,
}

export interface JobManagerListObserver {
    jobManagerDidUpdateJobsListsStatus(status: JobsListsStatus): void;
}

export interface JobManagerActiveJobObserver {
    jobManagerDidReceiveActiveJob(job: FullJob): void;
    jobManagerDidUpdateActiveJobSupplementals(activeJob: FullJob): void;
    jobManagerDidUpdateActiveJobResponders(activeJob: FullJob): void;
    jobManagerDidUpdateActiveJobHydrants(activeJob: FullJob): void;
    jobManagerDidUpdateActiveJobAssignments(activeJob: FullJob): void;
    jobManagerDidUpdateActiveJobPatients(activeJob: FullJob): void;
    jobManagerDidFailToLoadActiveJob(errorMessage: string): void;
}

export interface ActiveJobInfo {
    id: string;
    cachedJob: FullJob | null;
}

export type JobListStatus = {key: "loading"} | {key: "active", jobs: ListJob[], canLoadMoreJobs: boolean} | {key: "failed", message: string};
export type JobsListsStatus = {key: "loading"} | {key: "active", openJobs: ListJob[], closedJobs: ListJob[], canLoadMoreOpen: boolean, canLoadMoreClosed: boolean} | {key: "failed", message: string};

export class JobManager {
    static shared = new JobManager();

    jobsListsStatus: JobsListsStatus = {key: "loading"};

    activeJob: ActiveJobInfo | null = null;

    jobLookupTable: {[jobId: string]: ListJob} = {};

    private listObservers: JobManagerListObserver[] = [];
    private activeJobObservers: JobManagerActiveJobObserver[] = [];

    private static readonly jobsListSubscriptionKey = "jobManager-list";
    private static specificJobSubscriptionKey(jobId: string): string {
        return `jobManager-job@${jobId}`;
    }

    static readonly LIST_LIMIT = 30;

    startLoadingJobs() {
        BryxApi.subscribeToNewJobs(JobManager.jobsListSubscriptionKey, "reset", result => {
            if (result.success == true) {
                BryxApi.changeJobListSubscription(JobManager.jobsListSubscriptionKey, "resume", false);
                switch (result.value.key) {
                    case "replace":
                        const openJobs = result.value.openJobs;
                        openJobs.sort(ListJob.compare);
                        openJobs.forEach(j => this.jobLookupTable[j.id] = j);

                        const closedJobs = result.value.closedJobs;
                        closedJobs.sort(ListJob.compare);
                        closedJobs.forEach(j => this.jobLookupTable[j.id] = j);

                        this.updateJobsListsStatus({
                            key: "active",
                            openJobs: openJobs,
                            closedJobs: closedJobs,
                            canLoadMoreOpen: openJobs.length >= JobManager.LIST_LIMIT,
                            canLoadMoreClosed: closedJobs.length >= JobManager.LIST_LIMIT,
                        });
                        break;
                    case "fastForward":
                        this.applyUpdates(result.value.updates);
                        break;
                    case "update":
                        this.applyUpdates([result.value.update]);
                        break;
                }
            } else {
                config.warn(`Jobs list websocket failed: ${result.debugMessage}`);
                this.updateJobsListsStatus({key: "failed", message: result.message});
            }
        });
    }

    private updateJobsListsStatus(status: JobsListsStatus) {
        this.jobsListsStatus = status;
        this.listObservers.forEach(o => o.jobManagerDidUpdateJobsListsStatus(this.jobsListsStatus));
    }

    public static getListStatus(type: JobListType, listsStatus: JobsListsStatus): JobListStatus {
        if (listsStatus.key == "loading") {
            return {key: "loading"};
        } else if (listsStatus.key == "failed") {
            return {key: "failed", message: listsStatus.message};
        } else {
            if (type == JobListType.open) {
                return {
                    key: "active",
                    jobs: listsStatus.openJobs,
                    canLoadMoreJobs: listsStatus.canLoadMoreOpen,
                };
            } else {
                return {
                    key: "active",
                    jobs: listsStatus.closedJobs,
                    canLoadMoreJobs: listsStatus.canLoadMoreClosed,
                };
            }
        }
    }

    setActiveJob(jobId: string) {
        if (this.activeJob != null) {
            return;
        }

        this.activeJob = {
            id: jobId,
            cachedJob: null,
        };

        BryxApi.subscribeToJob(JobManager.specificJobSubscriptionKey(jobId), jobId, result => {
            if (result.success == true) {
                const activeJob = this.activeJob != null ? this.activeJob : null;
                const cachedJob = activeJob != null ? activeJob.cachedJob : null;
                switch (result.value.key) {
                    case "replace":
                        const fullJob = result.value.job;
                        if (cachedJob != null) {
                            cachedJob.updateWithJob(fullJob);
                        } else if (activeJob != null) {
                            activeJob.cachedJob = fullJob;
                        } else {
                            config.warn(`Trying to replace a cached active job@${jobId} which has already been cleared.`);
                        }
                        this.activeJobObservers.forEach(o => o.jobManagerDidReceiveActiveJob(fullJob));
                        break;
                    case "assignments":
                        if (cachedJob != null) {
                            cachedJob.updateUnitShortNames(result.value.unitShortNames);
                            this.activeJobObservers.forEach(o => o.jobManagerDidUpdateActiveJobAssignments(cachedJob));
                        }
                        break;
                    case "supplementals":
                        if (cachedJob != null) {
                            cachedJob.updateSupplementals(result.value.supplementals);
                            this.activeJobObservers.forEach(o => o.jobManagerDidUpdateActiveJobSupplementals(cachedJob));
                        }
                        break;
                    case "responders":
                        if (cachedJob != null) {
                            cachedJob.updateResponders(result.value.responders);
                            this.activeJobObservers.forEach(o => o.jobManagerDidUpdateActiveJobResponders(cachedJob));
                        }
                        break;
                    case "hydrants":
                        if (cachedJob != null) {
                            cachedJob.updateHydrants(result.value.hydrants);
                            this.activeJobObservers.forEach(o => o.jobManagerDidUpdateActiveJobHydrants(cachedJob));
                        }
                        break;
                    case "patients":
                        if (cachedJob != null) {
                            cachedJob.updatePatients(result.value.patients);
                            this.activeJobObservers.forEach(o => o.jobManagerDidUpdateActiveJobPatients(cachedJob));
                        }
                        break;
                }
            } else {
                this.activeJobObservers.forEach(o => o.jobManagerDidFailToLoadActiveJob(result.message));
            }
        });
    }

    clearActiveJob(jobId: string) {
        if (this.activeJob == null || this.activeJob.id != jobId) {
            config.warn("Could not clear active job; not set or ID did not match");
            return;
        }
        BryxApi.unsubscribe(JobManager.specificJobSubscriptionKey(jobId));
        this.activeJob = null;
    }

    stopLoadingJobs() {
        BryxApi.unsubscribe(JobManager.jobsListSubscriptionKey);
    }

    refreshJobs() {
        BryxApi.changeJobListSubscription(JobManager.jobsListSubscriptionKey, "reset", true);
    }

    loadMoreJobs(type: JobListType) {
        if (this.jobsListsStatus.key != "active") {
            config.error(`Failed to load more jobs, JobsListsStatus = ${this.jobsListsStatus.key}`);
            return;
        }
        const list = type == JobListType.open ? this.jobsListsStatus.openJobs : this.jobsListsStatus.closedJobs;
        if (list.length == 0) {
            // Can't load more
            config.warn(`Can't load more more ${JobListType[type]} jobs; list length is 0`);
            return;
        }
        BryxApi.loadJobs(list[list.length - 1].creationTime, JobManager.LIST_LIMIT, type, result => {
            if (result.success == true) {
                if (this.jobsListsStatus.key != "active") {
                    return;
                }
                // Merge each job into the list
                result.value.forEach(job => {
                    const existingIndex = list.map(j => j.id).indexOf(job.id);
                    if (existingIndex != -1) {
                        list[existingIndex] = job;
                    } else {
                        list.push(job);
                    }
                    this.jobLookupTable[job.id] = job;
                });

                // Sort the list
                list.sort(ListJob.compare);

                // Notify observers
                // The underlying list changed, observers already have the object, they just need a notify
                this.listObservers.forEach(o => o.jobManagerDidUpdateJobsListsStatus(this.jobsListsStatus));

                if (result.value.length < JobManager.LIST_LIMIT) {
                    // If got less than we asked for, we've reached the end
                    this.updateJobsListsStatus({
                        key: "active",
                        openJobs: this.jobsListsStatus.openJobs,
                        closedJobs: this.jobsListsStatus.closedJobs,
                        canLoadMoreOpen: type == JobListType.open ? false : this.jobsListsStatus.canLoadMoreOpen,
                        canLoadMoreClosed: type == JobListType.closed ? false : this.jobsListsStatus.canLoadMoreClosed,
                    });
                }
            } else {
                // TODO: Notify observers; potentially use a toast mechanism
                config.error(`Failed to load more jobs: ${result.debugMessage}`);
            }
        });
    }

    private applyUpdates(updates: JobsListServerUpdate[]) {
        if (this.jobsListsStatus.key != "active") {
            config.error(`Failed to apply job list updates, JobsListsStatus = ${this.jobsListsStatus.key}`);
            return;
        }
        const openJobs = this.jobsListsStatus.openJobs;
        const closedJobs = this.jobsListsStatus.closedJobs;
        let openJobsChanged = false;
        let closedJobsChanged = false;
        updates.forEach(update => {
            switch (update.key) {
                case "new":
                    config.debug(`Processing 'new' jobs list update: job@${update.job.id}`);
                    const existingOpenJobIndex = openJobs.map(j => j.id).indexOf(update.job.id);
                    if (existingOpenJobIndex != -1) {
                        openJobs[existingOpenJobIndex] = update.job;
                    } else {
                        openJobs.push(update.job);
                    }
                    this.jobLookupTable[update.job.id] = update.job;
                    openJobsChanged = true;
                    break;
                case "old":
                    config.debug(`Processing 'old' jobs list update: job@${update.jobId}`);
                    const existingJobForOld = this.jobLookupTable[update.jobId];
                    if (existingJobForOld != null) {
                        existingJobForOld.disposition = Disposition.old;
                        openJobsChanged = true;
                    }
                    break;
                case "closed":
                    config.debug(`Processing 'closed' jobs list update: job@${update.job.id}`);
                    const previouslyOpenJobIndex = openJobs.map(j => j.id).indexOf(update.job.id);
                    if (previouslyOpenJobIndex != -1) {
                        openJobs.splice(previouslyOpenJobIndex, 1);
                        openJobsChanged = true;
                    }
                    const existingClosedJobIndex = closedJobs.map(j => j.id).indexOf(update.job.id);
                    if (existingClosedJobIndex != -1) {
                        closedJobs[existingClosedJobIndex] = update.job;
                    } else {
                        if (closedJobs.length >= JobManager.LIST_LIMIT) {
                            closedJobs.splice(closedJobs.length - 1, 1);
                        }
                        closedJobs.push(update.job);
                    }
                    this.jobLookupTable[update.job.id] = update.job;
                    closedJobsChanged = true;
                    break;
                case "hasResponded":
                    config.debug(`Processing 'hasResponded' jobs list update: job@${update.jobId}`);
                    const existingJobForHasResponded = this.jobLookupTable[update.jobId];
                    if (existingJobForHasResponded != null && !existingJobForHasResponded.hasResponded) {
                        existingJobForHasResponded.hasResponded = true;
                        if (existingJobForHasResponded.isOpen) {
                            openJobsChanged = true;
                        } else {
                            closedJobsChanged = true;
                        }
                    }
                    break;
                case "assignments":
                    config.debug(`Processing 'assignments' jobs list update: job@${update.jobId}`);
                    const existingJobForAssignment = this.jobLookupTable[update.jobId];
                    if (existingJobForAssignment != null) {
                        existingJobForAssignment.unitShortNames = update.unitShortNames;
                        if (existingJobForAssignment.isOpen) {
                            openJobsChanged = true;
                        } else {
                            closedJobsChanged = true;
                        }
                    }
                    break;
            }
        });

        // Ensure both lists are sorted, if required, before notifying anyone of anything.
        if (openJobsChanged) {
            openJobs.sort(ListJob.compare);
        }
        if (closedJobsChanged) {
            closedJobs.sort(ListJob.compare);
        }

        // Only notify if changes have been made
        if (openJobsChanged) {
            this.listObservers.forEach(o => o.jobManagerDidUpdateJobsListsStatus(this.jobsListsStatus));
        }
        if (closedJobsChanged) {
            this.listObservers.forEach(o => o.jobManagerDidUpdateJobsListsStatus(this.jobsListsStatus));
        }

        // Acknowledge updates
        if (updates.length != 0) {
            BryxApi.acknowledgeJobsListUpdates(updates.map(u => u.id), result => {
                if (result.success == true) {
                    config.debug(`Successfully acknowledged ${updates.length} jobs list updates.`);
                } else {
                    // It's OK for acks to fail. We will just get them on the next reconnection.
                    config.warn(`Failed to acknowledge updates: ${result.debugMessage}`);
                }
            });
        }

    }

    reset(resubscribe: boolean) {
        this.updateJobsListsStatus({key: "loading"});
        this.jobLookupTable = {};
        if (resubscribe) {
            this.refreshJobs();
        } else {
            this.stopLoadingJobs();
        }
    }

    // JobManagerObservers

    public registerListObserver(observer: JobManagerListObserver) {
        if (this.listObservers.filter(o => o === observer).length == 0) {
            this.listObservers.push(observer);
        }
    }

    public unregisterListObserver(observer: JobManagerListObserver) {
        const observerIndex = this.listObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.listObservers.splice(observerIndex, 1);
        }
    }

    public registerActiveJobObserver(observer: JobManagerActiveJobObserver) {
        if (this.activeJobObservers.filter(o => o === observer).length == 0) {
            this.activeJobObservers.push(observer);
        }
    }

    public unregisterActiveJobObserver(observer: JobManagerActiveJobObserver) {
        const observerIndex = this.activeJobObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.activeJobObservers.splice(observerIndex, 1);
        }
    }
}
