import * as i18n from "i18next";
import * as React from "react";
import {Button, Form, Icon, Input, Modal} from "semantic-ui-react";

/**
 * Definition for the UploadFileModal props
 * @property {boolean} isOpen - State that controls whether the modal is open or closed.
 * @property {Function} setOpen - Function that controls the isOpen state.
 * @interface
 */
interface UploadFileModalProps {
    isOpen: boolean;
    sendMessage: () => void;
}

/**
 * Displays a modal for uploading images to the "Messaging" section of the app.
 *
 * @param {UploadFileModalProps} props - {@link UploadFileModalProps}
 * @param {*} context
 */
export class UploadFileModal extends React.Component<any, any> {
    constructor(props: UploadFileModalProps, context: any) {
        super(props, context);
        this.state = {
            isOpen: props.isOpen,
            selectedFile: null,
            fileUploaded: false,
            previewUrl: '',
            sendMessage: props.sendMessage,
        };
    }

    /**
     * Updates the states of selectedFile and fileUploaded and replaces the placeholder image with a preview
     * @param event - The upload event that triggered
     */
    private onFileChange = (event: any) => {
        this.setState({selectedFile: event.target.files[0], fileUploaded: true}, () => {
            // Replaces the image source of the preview when a file is uploaded so that a preview can be seen.
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                this.setState({previewUrl: reader.result});
            }, false);

            if (this.state.selectedFile) {
                reader.readAsDataURL(this.state.selectedFile);
            }
        });
    }

    /**
     * Resets the state to its initial values (except isOpen) and resets the input form
     */
    private onClear = () => {
        if (this.state.fileUploaded) {
            this.setState({selectedFile: null, fileUploaded: false, previewURL: ''}, () => {
                // Resetting the file input
                const form = document.getElementById('file-upload-form');
                if (form) {
                    (form as HTMLFormElement).reset();
                }
            });
        }
    }

    /**
     * Calls {@link onClear} and sets the isOpen state to false, closing the dialog
     */
    private onClose = () => {
        // Clearing the file input/resetting states
        this.onClear();
        this.setState({isOpen: false});
    }

    render() {
        return (
            <Modal
                className="upload-file-modal"
                onClose={() => {
                    this.onClose();
                }}
                onOpen={() => this.setState({isOpen: true})}
                open={this.state.isOpen}
                trigger={<Button icon id="upload-button"><Icon name="cloud upload"/></Button>}
                closeIcon
            >
                <Modal.Header>{i18n.t(`messages.uploadFileModal.modal-header`)}</Modal.Header>
                <Modal.Content className="upload-file-modal-content">
                    <h3 className="content-header">{i18n.t(`messages.uploadFileModal.content-header`)}</h3>
                    <Form id="file-upload-form">
                        <Input
                            id="file-upload"
                            type="file"
                            accept='image/jpeg,image/gif,image/png,image/jpg'
                            onChange={(event) => {
                                this.onFileChange(event);
                            }}
                            action={<Button icon onClick={() => this.onClear()}><Icon name="cancel"/></Button>}
                        />
                    </Form>
                    {/* The file details will show up once a file has been uploaded */}
                    {this.state.fileUploaded && (
                        <div className="upload-file-details">
                            <h2>{i18n.t(`messages.uploadFileModal.fileDetails`)}</h2>
                            <p>{i18n.t(`messages.uploadFileModal.fileName`) + this.state.selectedFile.name}</p>
                            <p>{i18n.t(`messages.uploadFileModal.fileType`) + this.state.selectedFile.type}</p>
                            <img src={this.state.previewUrl} height="200" alt="Image Preview" id="image-preview"/>
                        </div>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={() => this.onClose()}>
                        {i18n.t(`messages.uploadFileModal.cancel`)}
                    </Button>
                    <Button
                        content={i18n.t(`messages.uploadFileModal.upload`)}
                        labelPosition="right"
                        icon="checkmark"
                        onClick={() => {
                            this.state.sendMessage(this.state.selectedFile);
                            this.onClose();
                        }}
                        positive
                        disabled={!this.state.fileUploaded}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default UploadFileModal;
