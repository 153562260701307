import {LineString, Point} from "geojson";
import * as i18n from "i18next";
import {GeoJSON} from "leaflet";
import * as React from "react";
import {LayerGroup, Marker} from "react-leaflet";
import Linkify from "react-linkify";
import {Button, Dimmer, Divider, Dropdown, Grid, Header, Icon, Message, Modal} from "semantic-ui-react";
import {BryxGeoJSONLayer} from "../../components/bryxGeoJSONLayer";
import {BryxMapUtil} from "../../components/bryxMap";
import { BryxMap } from "@bryxinc/ui";
import {JobAdvisoryView} from "../../components/views/jobAdvisoryView";
import {JobBasicInfoView} from "../../components/views/jobBasicInfoView";
import {JobComplainantView} from "../../components/views/jobComplainantView";
import {JobSupplementalsView} from "../../components/views/jobSupplementalsView";
import {JobTypeInfoView} from "../../components/views/jobTypeInfoView";
import {config} from "../../config";
import {FullJob, RespondPermission} from "../../models/job";
import {ResponseOptionType} from "../../models/responder";
import {ApiResult, BryxApi} from "../../utils/bryxApi";
import {BryxColors} from "../../utils/bryxColors";
import {GeoUtils} from "../../utils/geoUtils";
import {LocationManager, LocationManagerObserver} from "../../utils/locationManager";
import {BryxPreferences, PreferenceManager, PreferenceManagerObserver} from "../../utils/preferenceManager";
import {Position} from "geojson";

interface JobDetailProps {
    job: FullJob;
}

interface JobDetailState {
    preferences: BryxPreferences;
    route: LineString | null;
    responseStatus: { key: "ready" } | { key: "loading" } | { key: "failed", message: string };
    showExpandedMap: boolean;
    clientLocation: number[] | null;
}

export class JobDetailTab extends React.Component<JobDetailProps, JobDetailState> implements PreferenceManagerObserver, LocationManagerObserver {
    private static readonly LOCATION_ACCURACY_ROUTING_THRESHOLD = 100.0; // meters

    private mounted = false;

    constructor(props: JobDetailProps, context: any) {
        super(props, context);
        this.state = {
            preferences: PreferenceManager.shared.preferences,
            route: null,
            responseStatus: {key: "ready"},
            showExpandedMap: false,
            clientLocation: null,
        };
    }

    componentWillMount() {
        BryxApi.getClientLocation((result: ApiResult<any>) => {
            if (result.success) {
                this.setState({clientLocation: result.value});
            }
        });
    }

    componentDidMount() {
        this.mounted = true;
        PreferenceManager.shared.registerObserver(this);
        LocationManager.shared.registerObserver(this);
        this.loadInitialRoute(this.props.job);
    }

    UNSAFE_componentWillReceiveProps(nextProps: JobDetailProps, nextContext: any) {
        if (nextProps.job.id != this.props.job.id) {
            this.setState({route: null}, () => this.loadInitialRoute(nextProps.job));
        }
    }

    private loadInitialRoute(job: FullJob) {
        const currentPosition = LocationManager.shared.currentPosition;
        if (currentPosition != null) {
            this.loadRoute(currentPosition, job);
        }
    }

    // LocationManagerObserver

    locationManagerDidUpdatePosition(position: Position): void {
        this.loadRoute(position, this.props.job);
    }

    locationManagerDidClearPosition(): void {
        this.setState({route: null});
    }

    private loadRoute(userPosition: Position, job: FullJob) {
        const jobCentroid = job.centroid;
        if (jobCentroid != null) {
            if (userPosition.coords.accuracy > JobDetailTab.LOCATION_ACCURACY_ROUTING_THRESHOLD) {
                config.info(`Not loading route, location was only accurate to ${userPosition.coords.accuracy} meter(s).`);
                return;
            }
            const userLocation = {
                type: "Point",
                coordinates: [userPosition[0], userPosition[1]],
            } as Point;
            BryxApi.getRoute(userLocation, jobCentroid, result => {
                if (!this.mounted) {
                    // User has left the page before the request could come back.
                    return;
                }
                if (result.success == true) {
                    this.setState({route: result.value});
                } else {
                    config.warn(`Failed to load route: ${result.debugMessage}`);
                }
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        PreferenceManager.shared.unregisterObserver(this);
        LocationManager.shared.unregisterObserver(this);
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void {
        this.setState({preferences: newPrefs});
    }

    render() {
        const job = this.props.job;
        const mapBounds = job.centroid != null ? GeoJSON.coordsToLatLng(job.centroid.coordinates as [number, number]).toBounds(300) : undefined;
        const userResponder = job.userResponder;

        const responseDropdown = (
            <Dropdown
                button
                floating
                labeled
                disabled={this.state.responseStatus.key == "loading"}
                icon="marker"
                className="large icon JobDetailButton"
                text={userResponder != null ? i18n.t("jobs.jobInfo.details.changeResponse") : i18n.t("jobs.jobInfo.details.respond")}
                style={{textAlign: "center", height: "42px", margin: 0}}>
                <Dropdown.Menu>
                    {job.responseOptions.map(o => (
                        <Dropdown.Item
                            key={o.id + o.text + o.type}
                            className="dropdown-item-bordered"
                            style={{
                                borderLeft: `4px solid ${o.type == ResponseOptionType.positive ? BryxColors.green : BryxColors.red}`,
                            }}
                            text={o.text}
                            active={userResponder != null && userResponder.currentResponse.responseOption.isEqual(o)}
                            onClick={() => {
                                this.setState({responseStatus: {key: "loading"}});
                                BryxApi.respondToJob(job.id, o, result => {
                                    if (result.success == true) {
                                        // Job Detail websocket will automatically update the user's response if successful
                                        this.setState({responseStatus: {key: "ready"}});
                                    } else {
                                        config.warn(`Failed to respond from detail button to job@${job.id}: ${result.debugMessage}`);
                                        this.setState({responseStatus: {key: "failed", message: result.message}});
                                    }
                                });
                            }}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );

        const {responseStatus} = this.state;
        const respondSection = job.respondPermission != RespondPermission.off ? (
            <div style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginRight: job.centroid != null ? "5px" : 0,
                maxWidth: "350px",
            }}>
                <div
                    id="responseLabelContainer"
                    style={{
                        minHeight: "20px",
                    }}>
            <span
                id="responseLabel"
                style={{
                    display: "block",
                    textAlign: "center",
                    verticalAlign: "middle",
                    textTransform: "uppercase",
                    color: "#909090",
                    fontSize: "14px",
                    letterSpacing: "0.5px",
                }}>
                {userResponder != null ? userResponder.currentResponse.responseOption.text : null}
            </span>
                </div>
                {responseDropdown}
            </div>
        ) : null;

        let navigateSection;
        if (job.centroid != null) {
            const urlInfo = GeoUtils.directionsUrl(
                job.centroid,
                this.state.preferences,
                this.props.job.address,
                this.state.clientLocation || undefined,
            );
            navigateSection = (
                <div style={{
                    flex: 1,
                    height: "42px",
                    marginTop: "20px",
                    marginLeft: job.respondPermission != RespondPermission.off ? "5px" : 0,
                    maxWidth: "350px",
                }}>
                    <Button
                        href={urlInfo.url}
                        className="JobDetailButton"
                        icon="location arrow"
                        labelPosition="left"
                        content={i18n.t("jobs.jobInfo.details.navigate")}
                        target={urlInfo.newWindow ? "_blank" : undefined}
                        size="large"
                        style={{width: "100%", margin: 0}}
                    />
                </div>
            );
        }

        const mapItems = [
            job.centroid != null ? (
                <BryxGeoJSONLayer key="job" geojson={job.centroid} icon={BryxMapUtil.jobTypeToIcon(job.typeInformation.type)}/>
            ) : null,
            this.state.route != null ? (
                <BryxGeoJSONLayer key="route" geojson={this.state.route}/>
            ) : null,
            job.hydrants != null ? (
                <LayerGroup key="hydrants">
                    {job.hydrants.map(h => (
                        <Marker
                            key={h.location.coordinates.toString()}
                            riseOnHover
                            icon={BryxMapUtil.hydrantIcon(h)}
                            position={GeoUtils.geoJsonToLatLng(h.location)}
                        />
                    ))}
                </LayerGroup>
            ) : null,
        ];

        const expandedMapModal = (
            <Modal
                className="expanded-map-modal"
                open={this.state.showExpandedMap}
                basic
                size="fullscreen"
                onClose={() => this.setState({ showExpandedMap: false })}
            >
                <Modal.Content>
                    <BryxMap onZoomChange={() => {}}
                    >
                        {mapItems}
                    </BryxMap>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        inverted
                        onClick={() =>
                            this.setState({ showExpandedMap: false })
                        }
                    >
                        {i18n.t("general.done")}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        const criticalWarningSection = job.criticalWarning ? (
            <div className="critical-warning-section">
                <div className="critical-warning-container">
                    {job.criticalWarning}
                </div>
            </div>
        ) : null;

        const mapAndButtons = (
          <div className="map-and-buttons">
            <Dimmer.Dimmable
              className="map-dimmer"
              blurring
              dimmed={job.centroid == null}
            >
              <Dimmer active={job.centroid == null} inverted>
                <Header as="h2" style={{ opacity: 0.7 }}>
                  <Icon name="location arrow" />
                  {i18n.t("jobs.jobInfo.details.noLocation")}
                </Header>
              </Dimmer>
              <BryxMap
                onZoomChange={() => {}}
                center={job.centroid as Point}
                defaultZoom={14}
              >
                {mapItems}
              </BryxMap>
            </Dimmer.Dimmable>
            {criticalWarningSection}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "center",
                flex: 1,
                marginTop: job.criticalWarning ? "0" : "10px",
              }}
            >
              {respondSection} {navigateSection}
            </div>
            {responseStatus.key == "failed" ? (
              <Message
                error
                header={i18n.t("jobs.jobInfo.details.failedToRespond")}
                content={responseStatus.message}
                onDismiss={() =>
                  this.setState({ responseStatus: { key: "ready" } })
                }
              />
            ) : null}
          </div>
        );

        const criticalWarning = job.siteSurvey != null && job.siteSurvey.critical != null ? (
            <Message
                className="critical-warning"
                warning
                icon="warning sign"
                header={i18n.t("jobs.jobInfo.details.criticalWarning")}
                content={
                    <Linkify properties={{target: "_blank", className: "lightBackgroundLink"}}>
                        {job.siteSurvey.critical}
                    </Linkify>
                }
            />
        ) : null;

        const advisoryWarning = job.siteSurvey != null && job.siteSurvey.advisory.length > 0 ? (
            <JobAdvisoryView jobAdvisory={job.siteSurvey.advisory.join("\n")} style={{padding: "10px 0"}}/>
        ) : null;

        return (
            <div className="job-detail-tab">
                <Header className="job-detail-header" as="h2">{job.synopsis}</Header>
                <Divider/>
                {criticalWarning}
                <Grid className="job-detail-grid" columns="equal">
                    <Grid.Row className="job-detail-grid-row">
                        <Grid.Column className="grid-column-1">
                            {mapAndButtons}
                            <JobSupplementalsView
                                supplementals={job.supplementals}
                                preferences={this.state.preferences}
                                style={{padding: "10px 0"}}
                            />
                        </Grid.Column>
                        <Grid.Column className="grid-column-2">
                            {advisoryWarning}
                            <JobBasicInfoView job={job} style={{padding: "10px 0"}}/>
                            <JobTypeInfoView jobType={job.typeInformation} style={{padding: "10px 0"}}/>
                            <JobComplainantView jobComplainant={job.complainant} style={{padding: "10px 0"}}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {expandedMapModal}
            </div>
        );
    }
}
