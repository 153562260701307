import {ParseResult, ParseUtils} from "../utils/cerealParse";

export class LocationDevice {
    private constructor(
        public id: string,
        public name: string,
        public useForLocation: boolean,
    ) {}

    static parse(o: any): ParseResult<LocationDevice> {
        try {
            return ParseUtils.parseSuccess(new LocationDevice(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getBoolean(o, "useForLocation"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<LocationDevice>(`Invalid LocationDevice Model: ${e.message}`);
        }
    }

    static compare(d1: LocationDevice, d2: LocationDevice): number {
        return d1.name.localeCompare(d2.name) || d1.id.localeCompare(d2.id);
    }
}
