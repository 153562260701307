import {ParseResult, ParseUtils} from "../utils/cerealParse";
import {DateUtils} from "../utils/dateUtils";
import {Hospital} from "./hospital";
import {Injury} from "./injury";

export class Patient {
    private constructor(
        public id: string,
        public age: number,
        public sex: Sex,
        public status: PatientStatus,
        public color: string,
        public hospital: Hospital | null,
        public vitals: Vitals,
        public injuries: Injury[],
    ) {}

    static parse(o: any): ParseResult<Patient> {
        try {
            return ParseUtils.parseSuccess(new Patient(
                ParseUtils.getString(o, "id"),
                ParseUtils.getNumber(o, "age"),
                ParseUtils.getEnum(o, "sex", Sex),
                ParseUtils.getSubobject(o, "status", so => {
                    const statusString = ParseUtils.getString({status: so}, "status");
                    switch (statusString) {
                        case "new":
                            return ParseUtils.parseSuccess(PatientStatus.newPatient);
                        case "alerted":
                            return ParseUtils.parseSuccess(PatientStatus.alerted);
                        case "acknowledged":
                            return ParseUtils.parseSuccess(PatientStatus.acknowledged);
                        case "cleared":
                            return ParseUtils.parseSuccess(PatientStatus.cleared);
                        default:
                            return ParseUtils.parseFailure(`Invalid PatientStatus: ${statusString}`);
                    }
                }),
                ParseUtils.getString(o, "color"),
                ParseUtils.getSubobjectOrNull(o, "hospital", Hospital.parse),
                ParseUtils.getSubobject(o, "vitals", Vitals.parse),
                ParseUtils.getArrayOfSubobjects(o, "injuries", Injury.parse, "warn"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Patient>(`Invalid Patient Model: ${e.message}`);
        }
    }
}

export enum PatientStatus {
    newPatient, alerted, acknowledged, cleared,
}

export enum Sex {
    male, female, unknown,
}

export type VitalValidity = {key: "valid"} | {key: "invalid", reasonKey: string};

export interface Vital {
    validate: () => VitalValidity;
    toObject: () => {[index: string]: any};
}

export class Vitals {
    constructor(
        public bloodOxygen: BloodOxygen | null,
        public bloodPressure: BloodPressure | null,
        public gcs: GCS | null,
        public heartRate: HeartRate | null,
    ) {}

    static parse(o: any): ParseResult<Vitals> {
        try {
            return ParseUtils.parseSuccess(new Vitals(
                ParseUtils.getSubobjectOrNull(o, "spO2", BloodOxygen.parse),
                ParseUtils.getSubobjectOrNull(o, "bp", BloodPressure.parse),
                ParseUtils.getSubobjectOrNull(o, "gcs", GCS.parse),
                ParseUtils.getSubobjectOrNull(o, "hr", HeartRate.parse),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Vitals>(`Invalid Vitals Model: ${e.message}`);
        }
    }

    toObject(): {[index: string]: any} {
        const payload: {[index: string]: any} = {};
        if (this.bloodOxygen != null) {
            payload.spO2 = this.bloodOxygen.toObject();
        }
        if (this.bloodPressure != null) {
            payload.bp = this.bloodPressure.toObject();
        }
        if (this.gcs != null) {
            payload.gcs = this.gcs.toObject();
        }
        if (this.heartRate != null) {
            payload.hr = this.heartRate.toObject();
        }
        return payload;
    }
}

export class BloodOxygen implements Vital {
    constructor(
        public value: number,
        public time: Date,
    ) {}

    static parse(o: any): ParseResult<BloodOxygen> {
        try {
            return ParseUtils.parseSuccess(new BloodOxygen(
                ParseUtils.getNumber(o, "spO2"),
                ParseUtils.getUNIXTimestampDate(o, "time"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<BloodOxygen>(`Invalid BloodOxygen Model: ${e.message}`);
        }
    }

    validate(): VitalValidity {
        if (this.value < 0) {
            return {key: "invalid", reasonKey: "patients.vitals.validation.spO2.mustBeGreaterThanZero"};
        } else if (this.value > 100) {
            return {key: "invalid", reasonKey: "patients.vitals.validation.spO2.mustBe100OrLess"};
        } else {
            return {key: "valid"};
        }
    }

    toObject(): {[index: string]: any} {
        return {spO2: this.value};
    }
}

export class BloodPressure implements Vital {
    constructor(
        public systolic: number | null,
        public diastolic: number | null,
        public time: Date,
    ) {}

    static parse(o: any): ParseResult<BloodPressure> {
        try {
            return ParseUtils.parseSuccess(new BloodPressure(
                ParseUtils.getNumberOrNull(o, "systolic"),
                ParseUtils.getNumberOrNull(o, "diastolic"),
                ParseUtils.getUNIXTimestampDate(o, "time"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<BloodPressure>(`Invalid BloodPressure Model: ${e.message}`);
        }
    }

    validate(): VitalValidity {
        if (this.systolic == null) {
            return {key: "invalid", reasonKey: "patients.vitals.validation.bp.mustProvideSystolic"};
        }
        if (this.systolic < 0) {
            return {key: "invalid", reasonKey: "patients.vitals.validation.bp.systolicGreaterThanZero"};
        } else if (this.systolic > 500) {
            return {key: "invalid", reasonKey: "patients.vitals.validation.bp.systolic500OrLess"};
        } else if (this.diastolic != null) {
            if (this.diastolic < 0) {
                return {key: "invalid", reasonKey: "patients.vitals.validation.bp.diastolicGreaterThanZero"};
            } else if (this.diastolic > 350) {
                return {key: "invalid", reasonKey: "patients.vitals.validation.bp.diastolic350OrLess"};
            } else if (this.diastolic > this.systolic) {
                return {key: "invalid", reasonKey: "patients.vitals.validation.bp.systolicGreaterThanDiastolic"};
            }
        }
        return {key: "valid"};
    }

    formatDiastolic(): string {
        return this.diastolic != null ? this.diastolic.toString() : "P";
    }

    toObject(): {[index: string]: any} {
        return {
            systolic: this.systolic,
            diastolic: this.diastolic,
        };
    }
}

export class GCS implements Vital {
    constructor(
        public eye: number,
        public verbal: number,
        public motor: number,
        public time: Date,
    ) {}

    static parse(o: any): ParseResult<GCS> {
        try {
            return ParseUtils.parseSuccess(new GCS(
                ParseUtils.getNumber(o, "eye"),
                ParseUtils.getNumber(o, "verbal"),
                ParseUtils.getNumber(o, "motor"),
                ParseUtils.getUNIXTimestampDate(o, "time"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<GCS>(`Invalid GCS Model: ${e.message}`);
        }
    }

    validate(): VitalValidity {
        // Input is sterilized by input view
        return {key: "valid"};
    }

    toObject(): {[index: string]: any} {
        return {
            eye: this.eye,
            verbal: this.verbal,
            motor: this.motor,
        };
    }

    static minGCS(): GCS {
        return new GCS(1, 1, 1, DateUtils.bryxNow());
    }

    static maxGCS(): GCS {
        return new GCS(4, 5, 6, DateUtils.bryxNow());
    }
}

export class HeartRate implements Vital {
    constructor(
        public value: number,
        public time: Date,
    ) {}

    static parse(o: any): ParseResult<HeartRate> {
        try {
            return ParseUtils.parseSuccess(new HeartRate(
                ParseUtils.getNumber(o, "heartRate"),
                ParseUtils.getUNIXTimestampDate(o, "time"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<HeartRate>(`Invalid HeartRate Model: ${e.message}`);
        }
    }

    validate(): VitalValidity {
        if (this.value < 0) {
            return {key: "invalid", reasonKey: "patients.vitals.validation.hr.mustBeGreaterThanZero"};
        } else if (this.value > 350) {
            return {key: "invalid", reasonKey: "patients.vitals.validation.hr.mustBe350OrLess"};
        } else {
            return {key: "valid"};
        }
    }

    toObject(): {[index: string]: any} {
        return {heartRate: this.value};
    }
}
