import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
import * as i18n from "i18next";
import * as React from 'react';
import {Button, Dropdown, Form, Header, List, Loader, Message, Modal, TransitionablePortal} from 'semantic-ui-react';
import {PatientItemView} from "../../../components/views/patientItemView";
import {config} from "../../../config";
import {MinimalHospital} from "../../../models/hospital";
import {Patient, PatientStatus} from "../../../models/patient";
import {BryxApi} from "../../../utils/bryxApi";
import {BryxColors} from "../../../utils/bryxColors";
import {DateUtils} from "../../../utils/dateUtils";
import {nullIfBlank} from "../../../utils/functions";
import {GroupInfoView} from "../../messages/groupInfoView";
import {VitalEditModal, VitalEditViewStatus} from "./vitalEditModal";
import {BloodOxygenView, BloodPressureView, GCSView, HeartRateView} from "./vitals";

export type SpecificPatientViewStatus = { key: "hidden" } | { key: "shown", patientId: string };

interface SpecificPatientViewProps {
    viewStatus: SpecificPatientViewStatus;
    alertingHospitals: MinimalHospital[];
    prompts: string[] | null;
    onDismissPrompts: () => void;
    onDismiss: () => void;
}

interface SpecificPatientViewState {
    status: { key: "loading" } | { key: "ready", patient: Patient };
    overlayStatus: { key: "hidden" } |
        { key: "alerting", status: { key: "ready" } | { key: "sending" } | { key: "error", message: string }, hospital: MinimalHospital, eta: number | null, rigId: string };
    vitalEditStatus: VitalEditViewStatus;
    currentTime: Date;
}

export class SpecificPatientView extends React.Component<SpecificPatientViewProps, SpecificPatientViewState> {
    private phoneUtil = PhoneNumberUtil.getInstance();
    private timerId: NodeJS.Timer | null = null;

    constructor(props: SpecificPatientViewProps, context: any) {
        super(props, context);

        this.state = {
            status: {key: "loading"},
            overlayStatus: {key: "hidden"},
            currentTime: DateUtils.bryxNow(),
            vitalEditStatus: {key: "hidden"},
        };
    }

    componentDidMount() {
        const {viewStatus} = this.props;
        if (viewStatus.key == "shown") {
            this.subscribeToPatient(viewStatus.patientId);
        }
        this.timerId = setInterval(() => {
            this.setState({currentTime: DateUtils.bryxNow()});
        }, 1000);
    }

    UNSAFE_componentWillReceiveProps(newProps: SpecificPatientViewProps) {
        const {viewStatus} = this.props;
        const newViewStatus = newProps.viewStatus;

        if (viewStatus.key == "shown" && newViewStatus.key == "shown") {
            if (viewStatus.patientId == newViewStatus.patientId) {
                // Same patient. just return
                return;
            } else {
                // New patient
                BryxApi.unsubscribe(SpecificPatientView.getTopicKey(viewStatus.patientId));
                this.subscribeToPatient(newViewStatus.patientId);
            }
        } else if (viewStatus.key == "shown" && newViewStatus.key == "hidden") {
            BryxApi.unsubscribe(SpecificPatientView.getTopicKey(viewStatus.patientId));
        } else if (viewStatus.key == "hidden" && newViewStatus.key == "shown") {
            this.subscribeToPatient(newViewStatus.patientId);
        }
    }

    componentWillUnmount() {
        if (this.timerId != null) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
        const {viewStatus} = this.props;
        if (viewStatus.key == "shown") {
            BryxApi.unsubscribe(SpecificPatientView.getTopicKey(viewStatus.patientId));
        }
    }

    private subscribeToPatient(patientId: string) {
        BryxApi.subscribeToPatient(SpecificPatientView.getTopicKey(patientId), patientId, result => {
            if (result.success == true) {
                this.setState({
                    status: {
                        key: "ready",
                        patient: result.value.patient,
                    },
                });
            } else {
                // Handle failed response
                config.error(result.debugMessage);
            }
        });
    }

    private openAlertOverlay(hospital: MinimalHospital) {
        this.setState({
            overlayStatus: {key: "alerting", status: {key: "ready"}, hospital: hospital, eta: null, rigId: ""},
        });
    }

    private alertPatient() {
        const {overlayStatus} = this.state;
        if (this.state.status.key == "ready" && overlayStatus.key == "alerting") {
            const patientId = this.state.status.patient.id;
            this.setState(prevState => {
                if (prevState.overlayStatus.key == "alerting") {
                    prevState.overlayStatus.status = {key: "sending"};
                }
                return prevState;
            });

            BryxApi.alertPatient(patientId, overlayStatus.hospital.id, overlayStatus.eta, nullIfBlank(overlayStatus.rigId), result => {
                if (result.success == true) {
                    this.setState({
                        overlayStatus: {key: "hidden"},
                        status: {key: "ready", patient: result.value},
                    });
                } else {
                    this.setState(prevState => {
                        if (prevState.overlayStatus.key == "alerting") {
                            config.debug(result.debugMessage);
                            prevState.overlayStatus.status = {key: "error", message: result.message};
                        }
                        return prevState;
                    });
                }
            });
        }
    }

    private handleOnKeyUp(e: any) {
        if (e.key == 'Enter') {
            this.alertPatient();
        }
    }

    private static getTopicKey(patientId: string): string {
        return `specificPatientView@${patientId}`;
    }

    render() {
        const {viewStatus, alertingHospitals} = this.props;
        let portalContent;

        if (this.state.status.key == "loading") {
            portalContent = (
                <Loader active/>
            );
        } else {
            const alertButton = (
                <Dropdown
                    button
                    floating
                    icon=""
                    text={i18n.t("patients.alertButton")}
                    className="large alert-button-dropdown dropdown_noIcon">
                    <Dropdown.Menu>
                        {alertingHospitals.map(h => (
                            <Dropdown.Item
                                key={h.id}
                                onClick={() => this.openAlertOverlay(h)}
                                text={h.name}/>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            );

            const patient = this.state.status.patient;
            const hospital = patient.hospital;

            let hospitalContent = alertButton;
            if (patient.status != PatientStatus.newPatient && hospital != null) {
                let statusColor = undefined;
                switch (patient.status) {
                    case PatientStatus.acknowledged:
                        statusColor = BryxColors.green;
                        break;
                    case PatientStatus.cleared:
                        statusColor = BryxColors.gray;
                        break;
                }
                const patientStatus = (
                    <span style={{
                        color: statusColor,
                        fontWeight: "normal",
                    }}>
                        {i18n.t(`patients.status.${PatientStatus[patient.status]}`)}
                    </span>
                );
                hospitalContent = (
                    <div>
                        <div className="patient-status-display">
                            {i18n.t("patients.status.title")}: {patientStatus}
                        </div>
                        <Header className="hospital-name" as="h3">
                            {hospital.name}
                        </Header>
                        <Header className="hospital-phone" as="h5">
                            {this.phoneUtil.format(this.phoneUtil.parse(hospital.phone, 'US'), PhoneNumberFormat.NATIONAL)}
                        </Header>
                    </div>
                );
            }

            const alertModal = this.state.overlayStatus.key == "alerting" ? (
                <Modal size="mini"
                       open={this.state.overlayStatus.key == "alerting"}
                       onClose={() => this.setState({overlayStatus: {key: "hidden"}})}>
                    <Modal.Header>
                        {this.state.overlayStatus.hospital.name}
                    </Modal.Header>
                    <Modal.Content>
                        <Form onSubmit={() => this.alertPatient()}>
                            <Form.Input
                                autoFocus
                                label={i18n.t("patients.eta")}
                                onKeyUp={this.handleOnKeyUp.bind(this)}
                                placeholder={i18n.t("patients.etaPlaceholder")}
                                error={this.state.overlayStatus.eta != null ? (this.state.overlayStatus.eta < 0) : undefined}
                                value={this.state.overlayStatus.eta != null ? this.state.overlayStatus.eta : ""}
                                onChange={(e, d) => {
                                    if (d.value == "" || d.value == undefined) {
                                        this.setState(prevState => {
                                            if (prevState.overlayStatus.key == "alerting") {
                                                prevState.overlayStatus.eta = null;
                                                prevState.overlayStatus.status = {key: "ready"};
                                            }
                                            return prevState;
                                        });
                                    } else {
                                        const newAge = parseInt(d.value, 10);
                                        if (!isNaN(newAge)) {
                                            this.setState(prevState => {
                                                if (prevState.overlayStatus.key == "alerting") {
                                                    prevState.overlayStatus.eta = newAge;
                                                    prevState.overlayStatus.status = {key: "ready"};
                                                }
                                                return prevState;
                                            });
                                        }
                                    }
                                }}/>
                            <Form.Input
                                label={i18n.t("patients.rigId")}
                                onKeyUp={this.handleOnKeyUp.bind(this)}
                                placeholder={i18n.t("patients.rigIdPlaceholder")}
                                value={this.state.overlayStatus.rigId}
                                onChange={(e, d) => {
                                    this.setState(prevState => {
                                        if (prevState.overlayStatus.key == "alerting") {
                                            prevState.overlayStatus.rigId = d.value;
                                            prevState.overlayStatus.status = {key: "ready"};
                                        }
                                        return prevState;
                                    });
                                }}/>
                        </Form>
                        {this.state.overlayStatus.status.key == "error" ?
                            <Message error content={this.state.overlayStatus.status.message}/> :
                            null}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button disabled={this.state.overlayStatus.status.key == "sending"}
                                onClick={() => this.setState({overlayStatus: {key: "hidden"}})}>
                            {i18n.t("general.cancel")}
                        </Button>
                        <Button primary
                                type="submit"
                                loading={this.state.overlayStatus.status.key == "sending"}
                                onClick={() => this.alertPatient()}>
                            {i18n.t("patients.alert")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            ) : null;

            portalContent = (
                <div className="portal-content">
                    <div className="portal-content-container">
                        <div className="patient-item-view-container">
                            <PatientItemView patient={patient}/>
                        </div>
                        <div className="hospital-content-container">
                            {hospitalContent}
                        </div>
                        <div className="vital-views-container">
                            <HeartRateView
                                vital={patient.vitals.heartRate}
                                currentTime={this.state.currentTime}
                                onClick={() => this.setState({
                                    vitalEditStatus: {
                                        key: "shown",
                                        vitalType: "heartRate",
                                        patient: patient,
                                    },
                                })}/>
                            <BloodPressureView
                                vital={patient.vitals.bloodPressure}
                                currentTime={this.state.currentTime}
                                onClick={() => this.setState({
                                    vitalEditStatus: {
                                        key: "shown",
                                        vitalType: "bloodPressure",
                                        patient: patient,
                                    },
                                })}/>
                            <BloodOxygenView
                                vital={patient.vitals.bloodOxygen}
                                currentTime={this.state.currentTime}
                                onClick={() => this.setState({
                                    vitalEditStatus: {
                                        key: "shown",
                                        vitalType: "bloodOxygen",
                                        patient: patient,
                                    },
                                })}/>
                            <GCSView
                                vital={patient.vitals.gcs}
                                currentTime={this.state.currentTime}
                                onClick={() => this.setState({
                                    vitalEditStatus: {
                                        key: "shown",
                                        vitalType: "gcs",
                                        patient: patient,
                                    },
                                })}/>
                        </div>
                    </div>
                    {viewStatus.key == "shown" ?
                        <GroupInfoView
                            groupType="patient"
                            onClose={this.props.onDismiss}
                            groupId={viewStatus.patientId}/> :
                        null}
                    {alertModal}
                </div>
            );
        }

        return (
            <TransitionablePortal
                open={viewStatus.key == "shown"}
                closeOnEscape={false}
                closeOnDocumentClick={false}
                onClose={this.props.onDismiss}
                transition={{animation: "fade up", duration: 200}}>
                <div className="transitionable-portal-container">
                    {portalContent}
                    <Modal
                        className="information-requested-modal"
                        open={this.props.prompts != null}
                        onClose={this.props.onDismissPrompts}>
                        <Modal.Header>{i18n.t("patients.informationRequested.title")}</Modal.Header>
                        <Modal.Content>
                            <span className="information-requested-prompt">
                                {i18n.t("patients.informationRequested.prompt")}
                            </span>
                            <List as='ul'>
                                {this.props.prompts && this.props.prompts.map(p => (
                                    <List.Item as='li' key={p}>{p}</List.Item>
                                ))}
                            </List>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                content={i18n.t("general.ok")}
                                onClick={this.props.onDismissPrompts}/>
                        </Modal.Actions>
                    </Modal>
                    <VitalEditModal
                        viewStatus={this.state.vitalEditStatus}
                        onUpdate={vitals => {
                            this.setState(prevState => {
                                if (prevState.status.key !== "loading") {
                                    return {
                                        ...prevState,
                                        status: {
                                            ...prevState.status,
                                            patient: {
                                                ...prevState.status.patient,
                                                vitals: vitals,
                                            },
                                        },
                                        vitalEditStatus: {
                                            key: "hidden",
                                        },
                                    };
                                } else {
                                    return prevState;
                                }
                            });
                        }}
                        onCancel={() => this.setState({vitalEditStatus: {key: "hidden"}})}/>
                </div>
            </TransitionablePortal>
        );
    }
}
