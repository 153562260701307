import * as i18n from "i18next";
import {Options} from "i18next";
import * as XHR from "i18next-xhr-backend";
import * as React from 'react';
import {Redirect, RouteComponentProps} from "react-router";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Divider, Icon, Loader, Menu, Popup} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import '../sass/main.scss';
import '../../public/fonts/openSans/opensans.css'
import '../../public/css/font-awesome/css/font-awesome.min.css'
import '../../public/css/react-rangeslider/rangeslider.min.css'
import {DutyMenuItem, DutyState} from "./components/dutyMenuItem";
import {PermissionsModal} from "./components/modals/permissionsModal";
import {NotificationMenu} from "./components/notificationMenu";
import {config} from "./config";
import {Login} from "./login";
import {BryxNotification, BryxNotificationType} from "./models/bryxNotification";
import {Stream, StreamingStatus} from "./models/stream";
import {BadBrowser} from "./pages/badBrowser";
import {EulaPage} from "./pages/eulaPage";
import {JobsPage} from "./pages/jobs/jobsPage";
import {MapPage} from "./pages/map/mapPage";
import {MessagesPage} from "./pages/messages/messagesPage";
import {SettingsTabType} from "./pages/settings/settingsModal";
import {StreamPlaybackCommand, StreamsPage} from "./pages/streams/streamsPage";
import {RouterMenuItem} from "./routedItems";
import {BryxApi} from "./utils/bryxApi";
import {BryxLocal} from "./utils/bryxLocal";
import {BryxWebSocket, BryxWebSocketState, BryxWebSocketStateObserver} from "./utils/bryxWebSocket";
import {DeviceUtils} from "./utils/deviceUtils";
import {JobManager} from "./utils/jobManager";
import {LocationManager} from "./utils/locationManager";
import {MessageManager} from "./utils/messageManager";
import {NotificationManager, NotificationManagerObserver, NotificationStatus} from "./utils/notificationManager";
import {PreferenceManager} from "./utils/preferenceManager";
import {ReleaseNotesUtils} from "./utils/releaseNotesUtils";
import {SessionManager} from "./utils/sessionManager";
import {UpdateManager, UpdateManagerObserver, UpdateStatus} from "./utils/updateManager";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { AccountManagementModalState, ApparatusSignoutModalState, ModalContext, ModalContextType, ModalManager, SettingsModalState } from "./components/modals/modalManager";
import { RedirectionPage } from "./pages/redirection";

export type SignOutType = "standard" | "manual" | "forced" | "passwordChanged";
type OverlayContentKey =
    "none"
    | "menu"
    | "duty"
    | "notifications"
    | "permissions";

interface MainState {
    isLoading: boolean;
    status: { key: "signedIn" } |
        { key: "signedOut", type: SignOutType };
    notificationStatus: NotificationStatus;
    overlayContent: OverlayContentKey;
    dutyState: DutyState;
    streamStatus: StreamingStatus;
    webSocketState: BryxWebSocketState;
    updateStatus: UpdateStatus;
}

export class Main extends React.Component<RouteComponentProps<any>, MainState> implements NotificationManagerObserver, BryxWebSocketStateObserver, UpdateManagerObserver {
    private activeStream: HTMLAudioElement | null = null;
    private static readonly minimumSplashTime = 2.0 * 1000;
    static contextType = ModalContext;
    public context: ModalContextType;

    constructor(props: any, context: any) {
        super(props, context);

        BryxApi.onUnauthenticated = () => {
            config.info("User is being force signed out");
            Main.resetManagers();
            this.setState({
                isLoading: false,
                status: {key: "signedOut", type: "forced"},
            });
        };

        i18n
            .use(XHR)
            .init({
                lng: BryxLocal.getItem<string>("locale") || "en-US",
                backend: {
                    loadPath: "/public/locales/{{lng}}.json",
                },
                fallbackLng: "en",
            } as Options, () => {
                this.loadSession();
            });

        this.state = {
            isLoading: BryxLocal.isSignedIn(),
            status: BryxLocal.isSignedIn() ? {key: "signedIn"} : {key: "signedOut", type: "standard"},
            notificationStatus: NotificationManager.shared.status,
            overlayContent: Main.defaultOverlayContent(),
            dutyState: {key: "initial"},
            streamStatus: {key: "unselected"},
            webSocketState: BryxWebSocket.shared.state,
            updateStatus: UpdateManager.shared.updateStatus,
        };
    }

    private static defaultOverlayContent(): OverlayContentKey {
        if (PermissionsModal.anyIncompleteStages()) {
            return "permissions";
        } else {
            return "none";
        }
    }

    componentDidMount() {
        NotificationManager.shared.registerObserver(this);
        BryxWebSocket.shared.registerStateObserver(this);
        UpdateManager.shared.registerObserver(this);
    }

    private onSelectStream(stream: Stream) {
        if (this.activeStream != null) {
            this.activeStream.pause();
            this.activeStream.currentTime = 0;
            this.activeStream.src = "";
            this.activeStream = null;
        }
        this.setState({
            streamStatus: {
                key: "selected",
                stream: stream,
                playback: {key: "playing", loading: true},
            },
        });
        const activeStream = new Audio(stream.url);
        this.activeStream = activeStream;
        activeStream.play().then(() => {
            this.setState({
                streamStatus: {
                    key: "selected",
                    stream: stream,
                    playback: {key: "playing", loading: false},
                },
            });
        }).catch(reason => {
            if (reason.name == "AbortError") {
                config.info(`User paused stream@${stream.id} while still loading`);
            } else {
                config.warn(`Failed to load stream${stream.id}: ${reason}`);
                this.setState({
                    streamStatus: {
                        key: "selected",
                        stream: stream,
                        playback: {key: "error"},
                    },
                });
            }
        });
    }

    private onStreamPlaybackCommand(command: StreamPlaybackCommand) {
        if (this.state.streamStatus.key != "selected") {
            config.error("Failed to change playback; no stream selected");
            return;
        }
        switch (command) {
            case "play":
                // Resume logic is the same as selection
                this.onSelectStream(this.state.streamStatus.stream);
                break;
            case "pause":
                if (this.activeStream != null) {
                    this.activeStream.pause();
                    this.activeStream.currentTime = 0;
                    this.activeStream.src = "";
                    this.activeStream = null;
                } else {
                    config.error("Failed to pause null stream");
                }
                this.setState(prevState => {
                    if (prevState.streamStatus.key == "selected") {
                        prevState.streamStatus.playback = {key: "paused"};
                    }
                    return prevState;
                });
                break;
            case "stop":
                if (this.activeStream != null) {
                    this.activeStream.pause();
                    this.activeStream.currentTime = 0;
                    this.activeStream.src = "";
                    this.activeStream = null;
                }
                // User can stop a stream from any playback state
                this.setState({streamStatus: {key: "unselected"}});
                break;
        }
    }

    componentWillUnmount() {
        NotificationManager.shared.unregisterObserver(this);
        BryxWebSocket.shared.unregisterStateObserver(this);
        UpdateManager.shared.unregisterObserver(this);
    }

    private loadSession() {
        if (BryxLocal.isSignedIn()) {
            const preSessionTime = Date.now();
            BryxApi.session((result) => {
                const postSessionTime = Date.now();
                // If session fails on a 401, `BryxApi.onUnauthenticated` will be called and will force sign out.
                // Any other errors are non-recoverable.
                if (result.success == true) {
                    config.info("Successfully updated session");
                    if (BryxLocal.getShowEula() != true) {
                        JobManager.shared.startLoadingJobs();
                        MessageManager.shared.startMonitoringGroups();
                        NotificationManager.shared.startLoadingNotifications();
                        UpdateManager.shared.start();
                        SessionManager.shared.start();
                        LocationManager.shared.startWatch();
                        setTimeout(() => {
                            this.setState({
                                isLoading: false,
                                status: {key: "signedIn"},
                                dutyState: {key: "active", duty: result.value.dutyStatus, subState: {key: "ready"}},
                            });
                        }, Math.max(Main.minimumSplashTime - (postSessionTime - preSessionTime), 0));
                    } else {
                        this.props.history.push("/eula", {
                            from: this.props.location,
                        });
                    }
                } else {
                    config.warn(`Failed to update session: ${result.debugMessage}`);
                }
            });
        }
    }

    private signoutComplete(type: SignOutType) {
        Main.resetManagers();
        this.setState({
            status: {key: "signedOut", type: type},
        });
    }

    private openOverlay(key: OverlayContentKey) {
        if (this.state.overlayContent == "none") {
            this.setState({overlayContent: key});
        } else if (this.state.overlayContent != key) {
            // If a popup is already open, we need close the existing one before opening the new one.
            // Otherwise, `onClose` will immediately be called for the new overlay.
            this.setState({overlayContent: "none"}, () => this.setState({overlayContent: key}));
        }
    }

    private closeOverlay() {
        this.setState({
            overlayContent: Main.defaultOverlayContent(),
        });
    }

    public static resetManagers() {
        JobManager.shared.reset(false);
        MessageManager.shared.reset();
        NotificationManager.shared.reset();
        BryxLocal.clear();
        PreferenceManager.shared.reset();
        UpdateManager.shared.reset();
        SessionManager.shared.reset();
        LocationManager.shared.reset();
    }

    private signOut() {
        if (BryxLocal.isApparatus()) {
            this.context.open<ApparatusSignoutModalState>("apparatusSignOut", {onSignOut: this.signoutComplete});
        } else {
            BryxApi.signOut(result => {
                if (result.success == true) {
                    config.info("User successfully signed out");
                    this.signoutComplete("manual");
                } else {
                    config.warn(`Failed to sign out: ${result.debugMessage}`);
                }
            });
        }
    }

    // NotificationManagerObserver Functions

    notificationManagerDidUpdateStatus(status: NotificationStatus): void {
        this.setState({notificationStatus: status});
    }

    notificationManagerDidReceiveClick(notification: BryxNotification): void {
        window.focus();
        switch (notification.content.type) {
            case BryxNotificationType.job:
                this.props.history.push(`/jobs/${notification.content.jobId}`);
                break;
            case BryxNotificationType.supplemental:
                this.props.history.push(`/jobs/${notification.content.jobId}`);
                break;
            case BryxNotificationType.message:
                this.props.history.push(`/messaging/${notification.content.groupId}`);
                break;
        }
    }

    // BryxWebSocketStateObserver Functions

    websocketStateDidChange(state: BryxWebSocketState) {
        this.setState({webSocketState: state});
    }

    // UpdateManagerObserver Functions

    updateManagerDidChangeUpdateStatus(updateStatus: UpdateStatus) {
        this.setState({updateStatus: updateStatus});
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="index-loading">
                    <img id="loadingIcon" src="/public/assets/logo_white.png" className="centerVertically"/>
                </div>
            );
        }

        if (this.state.status.key == "signedOut") {
            return (
                <Redirect to={{
                    pathname: "/login",
                    state: {
                        type: this.state.status.type,
                        from: this.state.status.type != "manual" ? this.props.location : null,
                    },
                }}/>
            );
        }

        // Setting dark or light mode based on localStorage
        if (localStorage.getItem('darkMode') == 'true') {
            document.body.classList.add('dark');
        } else {
            document.body.classList.add('light');
        }

        return (
            <div id="mainRoot">
                <Menu
                    className="navigation-menu"
                    attached="top"
                    color="red"
                    inverted
                >
                    <Menu.Item>
                        <img
                            className="logo"
                            src={"/public/assets/logo_white.png"}
                        />
                        <span className="siteName">
                            {i18n.t("branding.siteName")}
                        </span>
                    </Menu.Item>

                    <RouterMenuItem to="/jobs">
                        {i18n.t("nav.jobs")}
                    </RouterMenuItem>
                    <RouterMenuItem to="/map">
                        {i18n.t("nav.map")}
                    </RouterMenuItem>
                    <RouterMenuItem to="/messaging">
                        {i18n.t("nav.messages")}
                    </RouterMenuItem>
                    <RouterMenuItem to="/streams">
                        <span
                            className={
                                this.state.streamStatus.key == "selected" &&
                                this.state.streamStatus.playback.key ==
                                    "playing"
                                    ? "animateFlicker"
                                    : undefined
                            }
                        >
                            {i18n.t("nav.streams")}
                        </span>
                    </RouterMenuItem>

                    <Menu.Menu position="right">
                        {this.state.webSocketState ==
                        BryxWebSocketState.reconnecting ? (
                            <Menu.Item>
                                <Popup
                                    trigger={
                                        <div className="reconnecting-popup">
                                            <Loader
                                                className="reconnecting-loader"
                                                active
                                                inline
                                                inverted
                                                size="small"
                                            />
                                            {i18n.t("general.reconnecting")}
                                        </div>
                                    }
                                >
                                    {i18n.t("general.reconnectingToBryx")}
                                </Popup>
                            </Menu.Item>
                        ) : null}
                        <DutyMenuItem
                            open={this.state.overlayContent == "duty"}
                            onOpen={() => this.openOverlay("duty")}
                            onClose={this.closeOverlay.bind(this)}
                            dutyState={this.state.dutyState}
                            onUpdateDutyState={(state) =>
                                this.setState({ dutyState: state })
                            }
                        />
                        <NotificationMenu
                            open={this.state.overlayContent == "notifications"}
                            onOpen={() => this.openOverlay("notifications")}
                            onClose={this.closeOverlay.bind(this)}
                            status={this.state.notificationStatus}
                            onClickSettings={() =>
                                this.context.open<SettingsModalState>(
                                    "settings",
                                    {
                                        onPasswordChanged: () =>
                                            this.signoutComplete(
                                                "passwordChanged"
                                            ),
                                        initialTab: SettingsTabType.alerts,
                                    }
                                )
                            }
                        />
                        <Popup
                            id="settingsMenu"
                            on="click"
                            position="bottom right"
                            open={this.state.overlayContent == "menu"}
                            onOpen={() => this.openOverlay("menu")}
                            onClose={this.closeOverlay.bind(this)}
                            trigger={
                                <Menu.Item>
                                    <Icon.Group
                                        className="link"
                                        size="big"
                                        style={{
                                            opacity:
                                                this.state.updateStatus.key ==
                                                "updateAvailable"
                                                    ? 1
                                                    : undefined,
                                        }}
                                    >
                                        <Icon
                                            name="setting"
                                            style={{ margin: 0 }}
                                        />
                                        {this.state.updateStatus.key ==
                                        "updateAvailable" ? (
                                            <Icon
                                                corner
                                                name="circle"
                                                color="orange"
                                                style={{ fontSize: "10px" }}
                                            />
                                        ) : null}
                                    </Icon.Group>
                                </Menu.Item>
                            }
                        >
                            <Popup.Content>
                                {this.state.updateStatus.key ==
                                "updateAvailable" ? (
                                    <div>
                                        <div
                                            className="popupItem update-available"
                                            onClick={() => {
                                                if (
                                                    ReleaseNotesUtils.getStatus() ==
                                                    "hide"
                                                ) {
                                                    ReleaseNotesUtils.setStatus(
                                                        "show"
                                                    );
                                                }
                                                UpdateManager.shared.update();
                                            }}
                                        >
                                            <Icon
                                                corner
                                                name="circle"
                                                color="orange"
                                            />
                                            <span className="update-available-text">
                                                {i18n.t(
                                                    "updates.updateAvailable"
                                                )}
                                            </span>
                                        </div>
                                        <Divider style={{ margin: 0 }} />
                                    </div>
                                ) : null}
                                <div
                                    className="popupItem"
                                    onClick={() => {
                                        this.context.open<SettingsModalState>(
                                            "settings",
                                            {
                                                onPasswordChanged: () =>
                                                    this.signoutComplete(
                                                        "passwordChanged"
                                                    ),
                                                initialTab:
                                                    SettingsTabType.general,
                                            }
                                        );
                                        this.closeOverlay();
                                    }}
                                >
                                    {i18n.t("nav.settings.settings")}
                                </div>
                                <div
                                    className="popupItem"
                                    onClick={() => {
                                        this.context.open<AccountManagementModalState>(
                                            "accountManagement",
                                            {
                                                openTab: "account",
                                            }
                                        );
                                        this.closeOverlay();
                                    }}
                                >
                                    {i18n.t("account.dropdown")}
                                </div>
                                <div
                                    className="popupItem"
                                    onClick={() => {
                                        this.context.open("contactSupport");
                                        this.closeOverlay();
                                    }}
                                >
                                    {i18n.t("contactSupport.header")}
                                </div>
                                <div
                                    className="popupItem"
                                    onClick={() => {
                                        this.context.open("aboutUs");
                                        this.closeOverlay();
                                    }}
                                >
                                    {i18n.t("about.settingsItem")}
                                </div>
                                <div
                                    className="popupItem"
                                    onClick={this.signOut.bind(this)}
                                >
                                    {i18n.t("nav.settings.signOut")}
                                </div>
                            </Popup.Content>
                        </Popup>
                    </Menu.Menu>
                </Menu>
                <PermissionsModal
                    open={this.state.overlayContent == "permissions"}
                    onClose={this.closeOverlay.bind(this)}
                />

                <div id="content-wrapper">
                    <Switch>
                        <Route
                            path="/jobs/:jobId?/:tabId?/:contentId?"
                            render={(props) => <JobsPage {...props} />}
                        />
                        <Route
                            exact
                            path="/map"
                            render={(props) => <MapPage {...props} />}
                        />
                        <Route
                            exact
                            path="/messaging/:groupId?"
                            render={(props) => <MessagesPage {...props} />}
                        />
                        <Route
                            exact
                            path="/streams"
                            render={(props) => (
                                <StreamsPage
                                    streams={BryxLocal.getStreams()}
                                    playStatus={this.state.streamStatus}
                                    onSelect={this.onSelectStream.bind(this)}
                                    onPlaybackCommand={this.onStreamPlaybackCommand.bind(
                                        this
                                    )}
                                    {...props}
                                />
                            )}
                        />
                        <Route path="/redirect/:scope/:encoded" component={(props) => <RedirectionPage {...props} />} />
                        <Redirect to="/jobs" />
                    </Switch>
                </div>
            </div>
        );
    }
}

export class MainRouter extends React.Component<any, any> {
    render() {
        return (
            <ModalManager>
                <BrowserRouter>
                    <Switch>
                        <Route path="/unsupported-browser" component={BadBrowser}/>
                        <Route path="/eula" component={EulaPage}/>
                        <Route render={() => {
                            if (!DeviceUtils.deviceIsSupported) {
                                return <Redirect to="/unsupported-browser"/>;
                            } else {
                                return (
                                    <Switch>
                                        <Route
                                            path="/login"
                                            component={Login}
                                        />
                                        <Route component={Main} />
                                    </Switch>
                                );
                            }
                        }}/>
                    </Switch>
                </BrowserRouter>
            </ModalManager>
        );
    }
}

window.onerror = (message: string | Event, filename?: string, lineno?: number, colno?: number, error?: Error) => {
    config.error(message, filename, lineno, colno, error);
};

// Fix for new React 18.2.0 init paradigm
const root = ReactDOM.createRoot(
  document.getElementById("content") as HTMLElement
);

root.render(
    <>
        <ToastContainer />
        <MainRouter />
    </>
);
