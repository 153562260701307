import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {ParseUtils} from "../utils/cerealParse";
import {Group} from "./group";
import {GroupMember} from "./groupMember";
import {Message} from "./message";

// GroupUpdate

export type GroupUpdateReplace = {
    key: "replace",
    group: Group,
};
export type GroupUpdateMessage = {
    key: "message",
    message: Message,
};
export type GroupUpdateMember = {
    key: "member",
    member: GroupMember,
};
export type GroupUpdate = GroupUpdateReplace | GroupUpdateMessage | GroupUpdateMember;

export function parseGroupUpdate(message: BryxWebSocketMessage): ApiResult<GroupUpdate | null> {
    try {
        switch (message.key) {
            case "serverUpdate":
                const type = ParseUtils.getString(message.data, "type");
                switch (type) {
                    case "message":
                        return apiSuccess({
                            key: "message",
                            message: ParseUtils.getSubobject(message.data, "message", Message.parse),
                        } as GroupUpdateMessage);
                    case "member":
                        return apiSuccess({
                            key: "member",
                            member: ParseUtils.getSubobject(message.data, "member", GroupMember.parse),
                        } as GroupUpdateMember);
                    default:
                        return apiFailure(null, `Invalid GroupUpdate subscribe response type: ${type}`);
                }
            case "subscribeResponse":
                return apiSuccess({
                    key: "replace",
                    group: ParseUtils.getSubobject(message, "initialData", Group.parse),
                } as GroupUpdateReplace);
            default:
                return apiSuccess(null);
        }
    } catch (e) {
        return apiFailure(null, `Invalid GroupUpdate Model: ${e.message}`);
    }
}
