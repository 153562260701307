import {BryxLocal} from "./bryxLocal";

export type ReleaseNotesStatus = "show" | "hide" | "never";

export class ReleaseNotesUtils {

    public static getStatus(): ReleaseNotesStatus {
        switch (BryxLocal.getShowReleaseNotes()) {
            case "show":
                return "show";
            case "never":
                return "never";
            default:
                return "hide";
        }
    }

    public static setStatus(status: ReleaseNotesStatus) {
        BryxLocal.setShowReleaseNotes(status);
    }
}
