export type ParseResult<T> =
    { success: true, value: T } |
    { success: false, justification: string };

type Type = 'number' | 'string' | 'boolean' | 'array';

export class ParseUtils {
    public static onWarn: (message: string) => void = (m) => console.warn(m);

    public static itemIsType(o: any, expectedType: Type): boolean {
        return ({}).toString.call(o).match(/\s([a-zA-Z]+)/)[1].toLowerCase() == expectedType;
    }

    private static getObjectValue(o: any, key: string, expectedType: Type, allowNull: boolean): any {
        if (this.itemIsType(o[key], expectedType)) {
            return o[key];
        } if (allowNull) {
            return null;
        }
        throw TypeError(`key '${key}' is (${o[key]}): ${typeof o[key]}, expected ${expectedType}`);
    }

    public static parseSuccess<T>(value: T): ParseResult<T> {
        return { success: true, value };
    }

    public static parseFailure<T>(justification: string): ParseResult<T> {
        return { success: false, justification };
    }

    public static getString(o: any, key: string): string {
        return this.getObjectValue(o, key, 'string', false);
    }

    public static getStringOrNull(o: any, key: string): string | null {
        return this.getObjectValue(o, key, 'string', true);
    }

    public static getNumber(o: any, key: string): number {
        return this.getObjectValue(o, key, 'number', false);
    }

    public static getNumberOrNull(o: any, key: string): number | null {
        return this.getObjectValue(o, key, 'number', true);
    }

    public static getUNIXTimestampDate(o: any, key: string): Date {
        return new Date(this.getObjectValue(o, key, 'number', false) * 1000);
    }

    public static getUNIXTimestampDateOrNull(o: any, key: string): Date | null {
        const seconds = this.getObjectValue(o, key, 'number', true);
        if (seconds != null) {
            return new Date(seconds * 1000);
        }
        return null;
    }

    public static getISODate(o: any, key: string): Date {
        const date = new Date(this.getObjectValue(o, key, 'string', false));
        if (isNaN(date.getTime())) {
            throw TypeError(`key '${key}' is (${o[key]}): ${typeof o[key]}, expected ISODate`);
        } else {
            return date;
        }
    }

    public static getISODateOrNull(o: any, key: string): Date | null {
        const dateString = this.getObjectValue(o, key, 'string', true);
        if (dateString != null) {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
                throw TypeError(`key '${key}' is (${o[key]}): ${typeof o[key]}, expected ISODate`);
            } else {
                return date;
            }
        } else {
            return null;
        }
    }

    public static getBoolean(o: any, key: string): boolean {
        return this.getObjectValue(o, key, 'boolean', false);
    }

    public static getBooleanOrNull(o: any, key: string): boolean | null {
        return this.getObjectValue(o, key, 'boolean', true);
    }

    public static getArray(o: any, key: string): any[] {
        return this.getObjectValue(o, key, 'array', false);
    }

    public static getArrayOrNull(o: any, key: string): any[] | null {
        return this.getObjectValue(o, key, 'array', true);
    }

    public static getSubobject<T>(o: any, key: string, parseFunction: (o: any) => ParseResult<T>): T {
        const result = parseFunction(o[key]);
        if (result.success) {
            return result.value;
        }
        throw TypeError(`Invalid subobject at key '${key}': ${result.justification}`);
    }

    public static getSubobjectOrNull<T>(o: any, key: string, parseFunction: (o: any) => ParseResult<T>): T | null {
        const result = parseFunction(o[key]);
        return result.success ? result.value : null;
    }

    public static getEnum<T>(o: any, key: string, e: any, defaultValue?: T): T {
        const result = e[this.getString(o, key)];
        if (result != undefined) {
            return result;
        } if (defaultValue != undefined) {
            return defaultValue;
        }
        throw TypeError(`Invalid enum at key '${key}'`);
    }

    public static getEnumOrNull<T>(o: any, key: string, e: any): T | null {
        const enumString = this.getStringOrNull(o, key);
        if (enumString != null) {
            return e[enumString];
        }
        return null;
    }

    public static getArrayOfSubobjects<T>(o: any, key: string, parseFunction: (o: any) => ParseResult<T>, failBehavior: 'ignore' | 'warn' | 'throw'): T[] {
        return (this.getArray(o, key))
            .map((elem) => {
                const result = parseFunction(elem);
                if (result.success) {
                    return result.value;
                } if (failBehavior == 'throw') {
                    throw TypeError(`Invalid subobject: ${result.justification}`);
                } else {
                    if (failBehavior == 'warn') {
                        ParseUtils.onWarn(`Ignoring invalid subobject: ${result.justification}`);
                    }
                    return null;
                }
            })
            .filter(Boolean) as T[];
    }

    public static getArrayOfSubobjectsOrNull<T>(o: any, key: string, parseFunction: (o: any) => ParseResult<T>, failBehavior: 'ignore' | 'warn' | 'throw'): T[] | null {
        if (this.getArrayOrNull(o, key) == null) {
            return null;
        }
        return this.getArrayOfSubobjects(o, key, parseFunction, failBehavior);
    }
}
