import * as i18n from "i18next";
import * as React from "react";
import {RouteComponentProps, StaticContext} from "react-router";
import {Button, Loader, Confirm} from "semantic-ui-react";
import {ZeroStateView} from "../../components/views/zeroStateView";
import {GroupsListStatus, MessageManager, MessageManagerGroupListObserver} from "../../utils/messageManager";
import {BryxPreferences, PreferenceManager, PreferenceManagerObserver} from "../../utils/preferenceManager";
import {UpdateManager} from "../../utils/updateManager";
import {GroupInfoView} from "./groupInfoView";
import {GroupListItem} from "./groupListItem";
import { BryxButton } from "@bryxinc/ui";
import { MarkChatRead } from "@mui/icons-material";
import { BryxApi } from "../../utils/bryxApi";
import { Message } from "../../models/message";

interface MessagesPageState {
    groupsStatus: GroupsListStatus;
    preferences: BryxPreferences;
    markingRead: boolean;
}

export class MessagesPage extends React.Component<RouteComponentProps<{ groupId: string | undefined }>, MessagesPageState> implements MessageManagerGroupListObserver, PreferenceManagerObserver {
    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            groupsStatus: MessageManager.shared.status,
            preferences: PreferenceManager.shared.preferences,
            markingRead: false,
        };
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void {
        this.setState({preferences: newPrefs});
    }

    // MessageManagerObserver Functions

    messageManagerDidUpdateGroupsListStatus(status: GroupsListStatus): void {
        this.setState({groupsStatus: status});
    }

    componentDidMount() {
        PreferenceManager.shared.registerObserver(this);
        MessageManager.shared.registerListObserver(this);
    }

    componentWillUnmount() {
        PreferenceManager.shared.unregisterObserver(this);
        MessageManager.shared.unregisterListObserver(this);
    }

    render() {
        const {groupsStatus, preferences} = this.state;
        let groupListContent: React.ReactNode;
        let canViewAny: boolean;
        if (groupsStatus.key == "loading") {
            groupListContent = (
                <div className="group-list-zero-state">
                    <Loader inline active/>
                </div>
            );
            canViewAny = false;
        } else if (groupsStatus.key == "failed") {
            groupListContent = (
                <div className="group-list-failed-to-load">
                    <ZeroStateView
                        header={i18n.t("messages.failedToLoad")}
                        subheader={
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <span style={{marginBottom: "10px"}}>{groupsStatus.message}</span>
                                <Button onClick={UpdateManager.shared.update}>{i18n.t("general.reload")}</Button>
                            </div>
                        }
                        icon="warning sign"
                    />
                </div>
            );
            canViewAny = false;
        } else if (groupsStatus.groups.length == 0) {
            groupListContent = (
                <div className="group-list-no-message-groups">
                    <ZeroStateView
                        header={i18n.t("messages.noMessageGroups.title")}
                        subheader={i18n.t("messages.noMessageGroups.body")}
                        icon="list"
                    />
                </div>
            );
            canViewAny = false;
        } else { 
            const newUnread: { [id: string]: boolean } =
                groupsStatus.groups.reduce((unread, group) => {
                    if (group.latestMessage) {
                        const hasUnread =
                            group.members.length === 0
                                ? group.hasUnread
                                : group.members.filter(
                                      (member) =>
                                          member.isMe && group.latestMessage &&
                                          member.hasSeen(
                                              group.latestMessage
                                          )
                                  ).length === 0;
                        return { ...unread, [group.id]: hasUnread };
                    }
                    return unread;
                }, {});
            groupListContent = groupsStatus.groups.map((group) => (
                <GroupListItem
                    key={group.id}
                    group={group}
                    selected={group.id == this.props.match.params.groupId}
                    preferences={preferences}
                    onSelectGroup={(groupSelect) =>
                        this.props.match.params.groupId == groupSelect.id
                            ? this.props.history.push("/messaging")
                            : this.props.history.push(
                                  `/messaging/${groupSelect.id}`
                              )
                    }
                    unread={
                        Object.keys(newUnread).includes(group.id)
                            ? newUnread[group.id]
                            : false
                    }
                />
            ));
            canViewAny = true;
        }
        return (
            <div id="messages-page">
                <div className="group-list-content-container">
                    <div className="mark-read" style={{ padding: "8px" }}>
                        <BryxButton
                            disabled={!canViewAny}
                            variant="contained"
                            startIcon={<MarkChatRead />}
                            onClick={() => this.setState({ markingRead: true })}
                        >
                            {i18n.t("messages.markAsRead.button")}
                        </BryxButton>
                        <Confirm
                            open={this.state.markingRead}
                            onCancel={() =>
                                this.setState({ markingRead: false })
                            }
                            onConfirm={() => {
                                this.setState({ markingRead: false });
                                groupsStatus.key === "active" &&
                                    groupsStatus.groups.forEach((v) =>
                                        MessageManager.shared.readGroup(v.id)
                                    );
                            }}
                            header={i18n.t("messages.markAsRead.confirmTitle")}
                            content={i18n.t(
                                "messages.markAsRead.confirmMessage"
                            )}
                            confirmButton={i18n.t(
                                "messages.markAsRead.confirm"
                            )}
                            cancelButton={i18n.t(
                                "messages.markAsRead.cancel"
                            )}
                        />
                    </div>
                    {groupListContent}
                </div>
                <GroupInfoView
                    groupType="messaging"
                    groupId={this.props.match.params.groupId || null}
                />
            </div>
        );
    }
}
