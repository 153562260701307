import * as i18n from "i18next";
import * as React from "react";
import {FormEvent, SyntheticEvent} from "react";
import {Button, Form, Icon, Input, Message, Popup} from "semantic-ui-react";
import {config} from "../../config";
import {BryxApi} from "../../utils/bryxApi";

interface PasswordTabState {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    status: { key: "ready" } | { key: "loading" } | { key: "error", message: string };
}

interface PasswordTabProps {
    onPasswordChanged: () => void;
}

export class PasswordTab extends React.Component<PasswordTabProps, PasswordTabState> {
    constructor(props: PasswordTabProps, context: any) {
        super(props, context);

        this.state = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            status: {key: "ready"},
        };
    }

    private onChangeCurrentPassword(event: SyntheticEvent<HTMLInputElement>) {
        this.setState({
            currentPassword: event.currentTarget.value,
        });
    }

    private onChangeNewPassword(event: SyntheticEvent<HTMLInputElement>) {
        this.setState({
            newPassword: event.currentTarget.value,
        });
    }

    private onChangeConfirmPassword(event: SyntheticEvent<HTMLInputElement>) {
        this.setState({
            confirmPassword: event.currentTarget.value,
        });
    }

    private onSubmit(e: FormEvent<any>) {
        e.preventDefault();
        this.setState({status: {key: "loading"}});

        BryxApi.changePassword(this.state.currentPassword, this.state.newPassword, result => {
            if (result.success == true) {
                this.setState({status: {key: "ready"}});
                this.props.onPasswordChanged();
            } else {
                config.warn(`Failed to change password: ${result.debugMessage}`);
                this.setState({status: {key: "error", message: result.message}});
            }
        });
    }

    render() {
        const passwordRulesPopup = (
            <Popup
                trigger={<Icon name="help circle" style={{marginLeft: "5px"}}/>}
                header={i18n.t("settings.password.passwordRulesHeader")}
                content={i18n.t("settings.password.passwordRules")}
                position="right center"
            />
        );

        const errorMessage = this.state.status.key == "error" ? (
            <Message error content={this.state.status.message}/>
        ) : null;

        return (
            <div className="password-tab">
                <Form className="changePasswordForm" onSubmit={this.onSubmit.bind(this)} style={{flex: 1}}>
                    <Form.Field className="bryx-text">
                        <label>{i18n.t("settings.password.currentPassword")}</label>
                        <Input
                            required
                            type="password"
                            placeholder={i18n.t("settings.password.currentPassword")}
                            value={this.state.currentPassword || ""}
                            disabled={this.state.status.key == "loading"}
                            onChange={this.onChangeCurrentPassword.bind(this)}
                        />
                    </Form.Field>
                    <Form.Field className="bryx-text">
                        <label>{i18n.t("settings.password.newPassword")}{passwordRulesPopup}</label>
                        <Input
                            required
                            type="password"
                            error={this.state.newPassword != this.state.confirmPassword}
                            placeholder={i18n.t("settings.password.newPassword")}
                            value={this.state.newPassword || ""}
                            disabled={this.state.status.key == "loading"}
                            onChange={this.onChangeNewPassword.bind(this)}
                        />
                    </Form.Field>
                    <Form.Field className="bryx-text">
                        <label>{i18n.t("settings.password.confirmPassword")}{passwordRulesPopup}</label>
                        <Input
                            required
                            type="password"
                            error={this.state.newPassword != this.state.confirmPassword}
                            placeholder={i18n.t("settings.password.confirmPassword")}
                            value={this.state.confirmPassword || ""}
                            disabled={this.state.status.key == "loading"}
                            onChange={this.onChangeConfirmPassword.bind(this)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            positive
                            style={{float: "right", marginRight: 0}}
                            loading={this.state.status.key == "loading"}
                            disabled={this.state.confirmPassword == "" || this.state.newPassword == "" || this.state.currentPassword == "" || this.state.newPassword != this.state.confirmPassword}
                            content={i18n.t("general.submit")}
                        />
                    </Form.Field>
                </Form>
                {errorMessage}
            </div>
        );
    }
}
