import * as i18n from "i18next";
import * as React from 'react';
import * as ReactMarkdown from "react-markdown";
import {Button, Header, Loader, Modal} from 'semantic-ui-react';
import {config} from "../../config";
import {ReleaseNotesUtils} from "../../utils/releaseNotesUtils";
import {HttpClient, ResponseStatus} from "../../utils/spoonClient";

interface ReleaseNotesModalProps {
    open: boolean;
    onClose: () => void;
}

interface ReleaseNotesModalState {
    status: { key: "loading" } | { key: "ready", releaseNotes: string } | { key: "failed" };
}

export class ReleaseNotesModal extends React.Component<ReleaseNotesModalProps, ReleaseNotesModalState> {
    constructor(props: ReleaseNotesModalProps, context: any) {
        super(props, context);
        this.state = {
            status: {key: "loading"},
        };
    }

    componentDidMount() {
        const client = new HttpClient({});
        client.get(`/release-notes-${config.version}.txt`, null, (request, response) => {
            if (response.status == ResponseStatus.Success && response.responseText != null) {
                this.setState({
                    status: {key: "ready", releaseNotes: response.responseText},
                });
            } else {
                config.warn(`Unable to load release notes for version ${config.version}: ${response.responseText}`);
                this.setState({
                    status: {key: "failed"},
                });
            }
        });
    }

    render() {
        const releaseNotesStatus = ReleaseNotesUtils.getStatus();
        let modalContent;
        switch (this.state.status.key) {
            case "loading":
                modalContent = <Loader/>;
                break;
            case "ready":
                modalContent = <ReactMarkdown className="react-markdown" source={this.state.status.releaseNotes}/>;
                break;
            case "failed":
                modalContent = <Header as="h2" content={i18n.t("releaseNotes.noReleaseNotes")}/>;
                break;
        }
        return (
            <Modal
                className="release-notes-modal"
                size="small"
                open={this.props.open}
                onClose={this.props.onClose}>
                <Header content={i18n.t("releaseNotes.versionX", {version: config.version})}/>

                <Modal.Content className="modal-content-container">
                    {modalContent}
                </Modal.Content>

                <Modal.Actions>
                    {releaseNotesStatus == "show" ? (
                        <Button
                            negative
                            content={i18n.t("releaseNotes.neverShow")}
                            onClick={() => {
                                ReleaseNotesUtils.setStatus("never");
                                this.props.onClose();
                            }}
                        />
                    ) : null}
                    <Button
                        content={i18n.t("general.close")}
                        onClick={() => {
                            if (releaseNotesStatus == "show") {
                                ReleaseNotesUtils.setStatus("hide");
                            }
                            this.props.onClose();
                        }}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
