import {Point} from "geojson";
import {ParseResult, ParseUtils} from "../utils/cerealParse";

export class MinimalHospital {
    public constructor(
        public id: string,
        public name: string,
    ) {}

    static parse(o: any): ParseResult<MinimalHospital> {
        try {
            return ParseUtils.parseSuccess(new MinimalHospital(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<MinimalHospital>(`Invalid MinimalHospital Model: ${e.message}`);
        }
    }

    static compare(h1: MinimalHospital, h2: MinimalHospital): number {
        return h1.name.localeCompare(h2.name) || h1.id.localeCompare(h2.id);
    }
}

export class Hospital extends MinimalHospital {
    private constructor(
        public id: string,
        public name: string,
        public phone: string,
        public location: Point,
    ) { super(id, name); }

    static parse(o: any): ParseResult<Hospital> {
        try {
            const minimalHospital = MinimalHospital.parse(o);
            if (minimalHospital.success == false) {
                return ParseUtils.parseFailure<Hospital>(`Invalid Hospital: ${minimalHospital.justification}`);
            }

            const minimal = minimalHospital.value;

            return ParseUtils.parseSuccess(new Hospital(
                minimal.id,
                minimal.name,
                ParseUtils.getString(o, "phone"),
                o["location"],
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Hospital>(`Invalid Hospital Model: ${e.message}`);
        }
    }
}
