import {ParseResult, ParseUtils} from "../utils/cerealParse";
import {LocationDevice} from "./locationDevice";
import {Stream} from "./stream";
import {Agency} from "./agency";

export enum DutyStatus {
    on, off,
}

export enum LocationPrivacy {
    always, responding, openCall, never,
}

export class Auth {
    private constructor(
        public apiKey: string,
        public email: string,
        public name: string,
        public type: string,
        public showEula: boolean,
        public dutyStatus: DutyStatus,
        public allowNotifications: boolean,
        public devices: LocationDevice[],
        public locationPrivacy: LocationPrivacy,
        public streams: Stream[],
        public usingAvl: boolean,
        public agencies: Agency[],
        public clientId: string
    ) {}

    static parse(o: any): ParseResult<Auth> {
        try {
            const type = ParseUtils.getString(o, "type");
            let name;
            if (type == "user") {
                name = `${ParseUtils.getString(o, "firstName")} ${ParseUtils.getString(o, "lastName")}`;
            } else {
                name = ParseUtils.getString(o, "name");
            }
            return ParseUtils.parseSuccess(
                new Auth(
                    ParseUtils.getString(o, "apiKey"),
                    ParseUtils.getString(o, "email"),
                    name,
                    type,
                    ParseUtils.getBoolean(o, "showEula"),
                    ParseUtils.getBoolean(o, "receiveNotifications")
                        ? DutyStatus.on
                        : DutyStatus.off,
                    ParseUtils.getBoolean(o, "receivePush"),
                    ParseUtils.getArrayOfSubobjects(
                        o,
                        "devices",
                        LocationDevice.parse,
                        "warn"
                    ),
                    ParseUtils.getEnum(o, "locationPrivacy", LocationPrivacy),
                    ParseUtils.getArrayOfSubobjects(
                        o,
                        "audioStreams",
                        Stream.parse,
                        "warn"
                    ).sort(Stream.compare),
                    ParseUtils.getBoolean(o, "usingAvl"),
                    ParseUtils.getArrayOfSubobjects(
                        o,
                        "agencies",
                        Agency.parse,
                        "warn"
                    ),
                    ParseUtils.getString(o, "userId")
                )
            );
        } catch (e) {
            return ParseUtils.parseFailure<Auth>(`Invalid Auth Model: ${e.message}`);
        }
    }
}
