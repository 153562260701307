import {Injury, InjurySection, SectionInputType} from "../models/injury";
import {FullJob} from "../models/job";
import {Sex} from "../models/patient";

interface NLPResults {
    injuries: Injury[];
    age: number | null;
    sex: Sex | null;

}

export class NLPUtils {
    // MARK: Constants

    // TODO: Use median age to pick the closest-to-median age
    // private static readonly MEDIAN_AGE = 38;

    private static readonly ageRegex = "(\\d+)[ -]?(year-old|yo[mf]?)";
    private static readonly maleRegex = "((yo)?m|male|man|he|him|dad|father|husb(and)|son|bf|boy(f|fr|friend)?|peni(s|le))";
    private static readonly femaleRegex = "((yo)?f|fem(ale)?|woman|she|her|mom|mother|wife|daughter|gf|gi?rl(f|fr|friend)?|vaginal?)";
    private static readonly strokeRegex = "(stroke|cva|cvi|droop(ing)?|slur(red)?|blur(red)?)";
    private static readonly miRegex = "(mi|stemi|ekg|heart|chest|tingl(e|ing)|cardiac)";
    private static readonly shootRegex = "(sho(ot|ooting|t)|gun|bullet|gsw)";
    private static readonly stabRegex = "(sta(b|bbed|bbing)|cut(ting)?|knife|lacerat(ion|ed))";
    private static readonly fallRegex = "(f[ea]ll|fracture|bone|ground)";
    private static readonly mvcRegex = "(mv[ac]|car|crash|veh(icle)?s?|mph|sedan|van|truck|suv|struck|ped(estrian)?)";
    private static readonly burnBlastRegex = "(burn(ed|t|s)|blast|explosion|fire)";

    private static readonly injuryStrokeName = "Stroke";
    private static readonly injuryStemiName = "STEMI";
    private static readonly injuryShootingName = "Shooting";
    private static readonly injuryStabbingName = "Stabbing";
    private static readonly injuryFallName = "Fall";
    private static readonly injuryMvcName = "MVC";
    private static readonly injuryBurnBlastName = "Burn / Blast";
    private static readonly injuryOtherName = "Other";

    static process(job: FullJob): NLPResults {
        const searchString = `${job.synopsis} ${job.supplementals.map(s => s.text).join(" ")}`.toLowerCase();

        // Injuries
        const injuryTemplates = [
            {name: NLPUtils.injuryStrokeName, pattern: NLPUtils.strokeRegex},
            {name: NLPUtils.injuryStemiName, pattern: NLPUtils.miRegex},
            {name: NLPUtils.injuryShootingName, pattern: NLPUtils.shootRegex},
            {name: NLPUtils.injuryStabbingName, pattern: NLPUtils.stabRegex},
            {name: NLPUtils.injuryFallName, pattern: NLPUtils.fallRegex},
            {name: NLPUtils.injuryMvcName, pattern: NLPUtils.mvcRegex},
            {name: NLPUtils.injuryBurnBlastName, pattern: NLPUtils.burnBlastRegex},
        ];
        const injuries = injuryTemplates.filter(t => NLPUtils.matchAlone(searchString, t.pattern)).map(t => NLPUtils.newModel(t.name));

        // Age
        let age: number | null = null;
        const ageMatch = NLPUtils.matchAlone(searchString, NLPUtils.ageRegex);
        if (ageMatch != null) {
            const possibleAge = parseInt(ageMatch[2], 10);
            if (possibleAge > 0 && possibleAge <= 150) {
                age = possibleAge;
            }
        }

        // Sex
        let sex: Sex | null = null;
        const isPossiblyMale = NLPUtils.matchAlone(searchString, NLPUtils.maleRegex) != null;
        const isPossiblyFemale = NLPUtils.matchAlone(searchString, NLPUtils.femaleRegex) != null;
        if (isPossiblyFemale && !isPossiblyMale) {
            sex = Sex.female;
        } else if (!isPossiblyFemale && isPossiblyMale) {
            sex = Sex.male;
        }

        return {
            injuries: injuries,
            sex: sex,
            age: age,
        };
    }

    static matchAlone(target: string, pattern: string): RegExpMatchArray | null {
        return target.match(`(^|\\W)${pattern}(\\W|$)`);
    }

    static fullModel(): {[index: string]: Injury} {
        const model: {[index: string]: Injury} = {};
        model[NLPUtils.injuryStrokeName] = new Injury(NLPUtils.injuryStrokeName, [
            new InjurySection("Cincinnati Stroke Scale", SectionInputType.multi, [
                {title: "Abnormal Facial Droop", checked: false},
                {title: "Abnormal Arm Drift", checked: false},
                {title: "Abnormal Speech", checked: false},
            ]),
        ], "Last Known Well Time");

        model[NLPUtils.injuryStemiName] = new Injury(NLPUtils.injuryStemiName, [
        ], "Picture of 12-Lead EKG (attach from different device)");

        model[NLPUtils.injuryShootingName] = new Injury(NLPUtils.injuryShootingName, [
            new InjurySection("Location", SectionInputType.multi, [
                {title: "Head / Neck", checked: false},
                {title: "Back / Chest", checked: false},
                {title: "Abdomen", checked: false},
                {title: "Pelvis / Buttocks", checked: false},
                {title: "Limb", checked: false},
            ]),
        ], null);

        model[NLPUtils.injuryStabbingName] = new Injury(NLPUtils.injuryStabbingName, [
            new InjurySection("Location", SectionInputType.multi, [
                {title: "Head / Neck", checked: false},
                {title: "Back / Chest", checked: false},
                {title: "Abdomen", checked: false},
                {title: "Pelvis / Buttocks", checked: false},
                {title: "Limb", checked: false},
            ]),
        ], null);

        model[NLPUtils.injuryFallName] = new Injury(NLPUtils.injuryFallName, [
            new InjurySection("Height", SectionInputType.single, [
                {title: "0-5 ft", checked: false},
                {title: "5-10 ft", checked: false},
                {title: "> 10 ft", checked: false},
            ]),
            new InjurySection("Bone", SectionInputType.multi, [
                {title: "Long Bone Fracture", checked: false},
                {title: "Open Bone Fracture", checked: false},
            ]),
        ], null);

        model[NLPUtils.injuryMvcName] = new Injury(NLPUtils.injuryMvcName, [
            new InjurySection("Combined Speed", SectionInputType.single, [
                {title: "0-50 mph", checked: false},
                {title: "55-100 mph", checked: false},
                {title: "> 100 mph", checked: false},
            ]),
            new InjurySection("Extras", SectionInputType.multi, [
                {title: "Rollover", checked: false},
                {title: "Extraction > 20 min", checked: false},
                {title: "Unrestrained", checked: false},
                {title: "Ejected", checked: false},
                {title: "Vehicle Size Mismatch", checked: false},
                {title: "Multiple Collisions", checked: false},
                {title: "Failure of Passenger Comp", checked: false},
            ]),
        ], null);

        model[NLPUtils.injuryBurnBlastName] = new Injury(NLPUtils.injuryBurnBlastName, [
            new InjurySection("Thickness", SectionInputType.single, [
                {title: "Full Thickness", checked: false},
                {title: "Partial Thickness", checked: false},
            ]),
            new InjurySection("Location", SectionInputType.multi, [
                {title: "Face", checked: false},
                {title: "Hands", checked: false},
                {title: "Genitalia", checked: false},
                {title: "Joint", checked: false},
            ]),
            new InjurySection("Type", SectionInputType.multi, [
                {title: "Thermal", checked: false},
                {title: "Electrical", checked: false},
                {title: "Chemical", checked: false},
                {title: "Explosion", checked: false},
                {title: "Inhalation", checked: false},
            ]),
        ], null);

        model[NLPUtils.injuryOtherName] = new Injury(NLPUtils.injuryOtherName, [
            new InjurySection("Patient Complaint", SectionInputType.multi, [
                {title: "Abdominal Pain", checked: false},
                {title: "Allergic Reaction", checked: false},
                {title: "Cardiac (Non-STEMI)", checked: false},
                {title: "Diabetic", checked: false},
                {title: "General Illness", checked: false},
                {title: "Injury (Non-Trauma)", checked: false},
                {title: "Intoxication / Poisoning", checked: false},
                {title: "Pregnancy / Delivery", checked: false},
                {title: "Psychiatric", checked: false},
                {title: "Respiratory", checked: false},
                {title: "Seizures", checked: false},
                {title: "Sepsis", checked: false},
                {title: "Syncope", checked: false},
                {title: "Other", checked: false},
            ]),
        ], null);
        return model;
    }

    static newModel(injury: string): Injury {
        const model = NLPUtils.fullModel()[injury];
        if (model == null) {
            throw Error(`Invalid injury '${injury}'`);
        } else {
            return model;
        }
    }

    static allInjuryTypes(): string[] {
        return [
            NLPUtils.injuryShootingName,
            NLPUtils.injuryStabbingName,
            NLPUtils.injuryFallName,
            NLPUtils.injuryMvcName,
            NLPUtils.injuryBurnBlastName,
            NLPUtils.injuryStrokeName,
            NLPUtils.injuryStemiName,
            NLPUtils.injuryOtherName,
        ];
    }
}
