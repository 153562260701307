import * as i18n from "i18next";
import * as React from "react";
import {SectionInputType} from "../../models/injury";
import {Patient, Sex} from "../../models/patient";

interface PatientItemViewProps {
    patient: Patient;
}

export class PatientItemView extends React.Component<PatientItemViewProps, {}> {
    render() {
        const {patient} = this.props;
        let sexInitials: string;
        let sexResource: string;
        switch (patient.sex) {
            case Sex.male:
                sexInitials = "M";
                sexResource = "/public/assets/patient_icons/sex_male.svg";
                break;
            case Sex.female:
                sexInitials = "F";
                sexResource = "/public/assets/patient_icons/sex_female.svg";
                break;
            case Sex.unknown:
                sexInitials = "UNK";
                sexResource = "/public/assets/patient_icons/sex_male.svg";
                break;
            default:
                throw Error("Impossible case");
        }
        return (
            <div className="patient-item">
                <img className="patient-item-image" src={sexResource}/>
                <div className="patient-item-info-container">
                    <div className="patient-item-info-col">
                        <span className="patient-item-label">{i18n.t("patients.injury")}</span>
                        <span className="patient-item-value">{PatientItemView.injuryDescription(patient)}</span>
                    </div>
                    <div className="patient-item-info-row">
                        <div className="patient-age-display">
                            <span className="patient-item-label">{i18n.t("patients.age")}</span>
                            <span className="patient-item-value">{patient.age}</span>
                        </div>
                        <div className="patient-item-info-col">
                            <span className="patient-item-label">{i18n.t("patients.sex.title")}</span>
                            <span className="patient-item-value">{sexInitials}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private static injuryDescription(patient: Patient): string {
        switch (patient.injuries.length) {
            case 0:
                return i18n.t("patients.noInjuries");
            case 1:
                const injury = patient.injuries[0];
                const injurySectionString = injury.sections.filter(s => s.inputType == SectionInputType.single).map(s => (
                    s.values.filter(v => v.checked).map(v => v.title).join(" ")
                )).join(" ");
                return `${injury.type} ${injurySectionString}`;
            default:
                return patient.injuries.map(i => i.type).join("\n");
        }
    }
}
