import { Redirect, RouteComponentProps } from "react-router";
import React, { useMemo } from "react";
import {
    AccountManagementModalState,
    ModalOpenFunction,
    useModals,
} from "../components/modals/modalManager";
import { toast } from "react-toastify";
import i18n from "i18next";

// Usability documentation is @ /docs/UrlRedirects.md

type StripeRedirect = {
    for: string;
};

type RedirectScope = "stripe";

function handleStripeRedirect(
    args: StripeRedirect,
    modalOpener: ModalOpenFunction
) {
    const params = new URLSearchParams(window.location.search);
    if (args.for == "smsAlerts") {
        modalOpener<AccountManagementModalState>("accountManagement", {
            openTab: "alert",
            openSection: "alert.sms",
        });
        if (params.get("redirect_status") == "succeeded") {
            toast.success(i18n.t("account.sections.sms.purchasing.success"));
        } else {
            toast.error(i18n.t("account.sections.sms.errors.paymentFailure"));
        }
    }
}

export function RedirectionPage(
    props: RouteComponentProps<{ scope?: RedirectScope; encoded: string }> & {
        scope?: RedirectScope;
        rawData?: boolean;
        redirectTo?: string;
    }
) {
    const { scope, encoded, rawData, redirectTo } = {
        ...props.match.params,
        ...props,
    };
    const { resolveUrl, args } =
        rawData && redirectTo
            ? { resolveUrl: redirectTo, args: encoded }
            : JSON.parse(atob(encoded));
    const { open } = useModals();

    const handled = useMemo(() => {
        switch (scope) {
            case "stripe":
                handleStripeRedirect(args, open);
                break;
        }
        return true;
    }, []);

    return handled ? <Redirect to={resolveUrl} /> : <></>;
}

export type RedirectionArguments<TArgumentType> = {
    resolveUrl: string;
    args: TArgumentType;
};

export function generateRedirectionUrl<TArgumentType>(
    scope: string,
    resolveUrl: string,
    args: TArgumentType
): string {
    const rawData: RedirectionArguments<TArgumentType> = {
        resolveUrl: resolveUrl,
        args,
    };
    const encodedData = btoa(JSON.stringify(rawData));
    return `/redirect/${scope}/${encodedData}`;
}

export function generateCustomRedirectionUrl<TArgumentType>(
    base: `${string}/`,
    resolveUrl: string,
    args: TArgumentType
): string {
    const rawData: RedirectionArguments<TArgumentType> = {
        resolveUrl: resolveUrl,
        args,
    };
    const encodedData = btoa(JSON.stringify(rawData));
    return base + encodedData;
}
