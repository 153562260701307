import {ParseResult, ParseUtils} from "../utils/cerealParse";

export enum ShiftStatus {
    on, forceOn, forceOff,
}

export class Shift {
    private constructor(
        public id: string,
        public name: string,
        public status: ShiftStatus,
        public filters: Filter[],
    ) {}

    static parse(o: any): ParseResult<Shift> {
        try {
            return ParseUtils.parseSuccess(new Shift(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getEnum(o, "scheduleMode", ShiftStatus),
                ParseUtils.getArrayOfSubobjects(o, "filters", Filter.parse, "warn"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Shift>(`Invalid Shift Model: ${e.message}`);
        }
    }

    static compare(s1: Shift, s2: Shift): number {
        return s1.name.localeCompare(s2.name) || s1.id.localeCompare(s2.id);
    }
}

export class Filter {
    private constructor(
        public id: string,
        public type: string,
        public value: string,
        public alert: AlertSetting,
        public description: string,
    ) {}

    static parse(o: any): ParseResult<Filter> {
        try {
            let alert: AlertSetting;
            const alertType = ParseUtils.getString(o["alert"], "type");
            switch (alertType) {
                case "audio":
                    alert = {
                        type: AlertSettingType.audio,
                        soundId: ParseUtils.getString(o["alert"], "fileName"),
                    };
                    break;
                case "silence":
                    alert = { type: AlertSettingType.silent };
                    break;
                case "none":
                    alert = { type: AlertSettingType.none };
                    break;
                default:
                    return ParseUtils.parseFailure(`Invalid Filter Model: 'alert.type' was ${alertType}`);
            }
            return ParseUtils.parseSuccess(new Filter(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "type"),
                ParseUtils.getString(o, "value"),
                alert,
                ParseUtils.getString(o, "description"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Filter>(`Invalid Filter Model: ${e.message}`);
        }
    }
}

export enum AlertSettingType {
    none, silent, audio,
}
export type AlertSetting = { type: AlertSettingType.none } | { type: AlertSettingType.silent } | { type: AlertSettingType.audio, soundId: string };
