import {ParseResult, ParseUtils} from "../utils/cerealParse";

export class Complainant {
    private constructor(
        public name: string | null,
        public phoneNumber: string | null,
        public address: string | null,
    ) {}

    static parse(o: any): ParseResult<Complainant> {
        try {
            return ParseUtils.parseSuccess(new Complainant(
                ParseUtils.getStringOrNull(o, "name"),
                ParseUtils.getStringOrNull(o, "phoneNumber"),
                ParseUtils.getStringOrNull(o, "address"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Complainant>(`Invalid Complainant Model: ${e.message}`);
        }
    }
}
