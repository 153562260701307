import * as i18n from "i18next";
import * as React from "react";
import {Button, CheckboxProps, Dropdown, Form, Header, Icon, Message, Modal, Segment} from "semantic-ui-react";
import {config} from "../../config";
import {Injury, SectionInputType} from "../../models/injury";
import {FullJob} from "../../models/job";
import {Patient, Sex} from "../../models/patient";
import {BryxApi} from "../../utils/bryxApi";
import {NLPUtils} from "../../utils/nlpUtils";

interface PatientCreationModalProps {
    open: boolean;
    job: FullJob;
    onCreatePatient: (newPatient: Patient, prompts: string[] | null) => void;
    onCancel: () => void;
}

type PatientCreationStage = { key: "initial" } | { key: "injuryDetail", injury: Injury };

type PreviousStageInfo =
    {
        key: "cancel",
    } |
    {
        key: "previous",
        previousStage: PatientCreationStage,
    };
type NextStageInfo =
    {
        key: "next",
        status: { key: "incomplete" } | { key: "complete", nextStage: PatientCreationStage },
    } |
    {
        key: "done",
    };

interface PatientCreationModalState {
    age: number | null;
    sex: Sex | null;
    injuries: Injury[];
    stage: PatientCreationStage;
    requestPhase: { key: "ready" } | { key: "loading" } | { key: "failed", message: string };
}

export class PatientCreationModal extends React.Component<PatientCreationModalProps, PatientCreationModalState> {
    constructor(props: PatientCreationModalProps, context: any) {
        super(props, context);
        this.state = {
            age: null,
            sex: null,
            injuries: [],
            stage: {key: "initial"},
            requestPhase: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: PatientCreationModalProps) {
        if (!this.props.open && nextProps.open) {
            const nlpInfo = NLPUtils.process(nextProps.job);
            this.setState({
                age: nlpInfo.age,
                sex: nlpInfo.sex,
                injuries: nlpInfo.injuries,
                stage: {key: "initial"},
                requestPhase: {key: "ready"},
            });
        }
    }

    private renderInitial() {
        return (
            <Modal.Content className="initial-content">
                <Form>
                    <Form.Group widths="equal">
                        <Form.Input
                            label={i18n.t("patients.age")}
                            error={this.state.age != null ? (this.state.age < 0 || this.state.age > 150) : undefined}
                            value={this.state.age != null ? this.state.age : ""}
                            onChange={(e, d) => {
                                if (d.value == "" || d.value == undefined) {
                                    this.setState({age: null});
                                } else {
                                    const newAge = parseInt(d.value, 10);
                                    if (!isNaN(newAge)) {
                                        this.setState({age: newAge});
                                    }
                                }
                            }}>
                        </Form.Input>
                        <Form.Select
                            label={i18n.t("patients.sex.title")}
                            value={this.state.sex != null ? this.state.sex : undefined}
                            options={[Sex.male, Sex.female, Sex.unknown].map(o => ({
                                key: o,
                                text: i18n.t(`patients.sex.${Sex[o]}`),
                                value: o,
                            }))}
                            onChange={(e, d) => {
                                const sex = d.value as (Sex | null);
                                this.setState({sex: sex});
                            }}/>
                    </Form.Group>
                    <Form.Field className="injuries-field" label={i18n.t("patients.injuries")}/>
                    {this.state.injuries.length > 0 ? (
                        <Segment.Group className="injury-display-container">
                            {this.state.injuries.map(i => (
                                <Segment className="injury-display" key={i.type}>
                                    {i.type}
                                    <Icon link name="x" color="red" size="large"
                                          onClick={() => this.setState(prevState => {
                                              return {
                                                  ...prevState,
                                                  injuries: prevState.injuries.filter(pi => pi !== i),
                                              };
                                          })}
                                    />
                                </Segment>
                            ))}
                        </Segment.Group>
                    ) : null}
                </Form>
                <Dropdown
                    button
                    floating
                    labeled
                    className="icon positive"
                    icon="plus"
                    text={i18n.t("patients.addInjury")}>
                    <Dropdown.Menu>
                        {NLPUtils.allInjuryTypes().map(t => (
                            <Dropdown.Item
                                key={t}
                                text={t}
                                onClick={() => {
                                    this.setState(prevState => {
                                        if (prevState.injuries.map(i => i.type).indexOf(t) == -1) {
                                            prevState.injuries.push(NLPUtils.newModel(t));
                                        }
                                        return prevState;
                                    });
                                }}/>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Modal.Content>
        );
    }

    private renderInjury(injury: Injury) {
        return (
            <Modal.Content className="injury-content">
                <Header as="h2">{injury.type}</Header>
                <div className="injury-sections-container">
                    {injury.sections.map(s => {
                        return (
                            <Form className="injury-section-form" key={s.header}>
                                <Header as="h4">{s.header}</Header>
                                {s.values.map(v => {
                                    const onChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                                        this.setState(prevState => {
                                            const searchInjury = prevState.injuries.filter(i => i === injury)[0];
                                            const searchSection = searchInjury.sections.filter(ss => ss === s)[0];
                                            if (searchSection.inputType == SectionInputType.single) {
                                                searchSection.values.forEach(sv => sv.checked = false);
                                            }
                                            const searchValue = searchSection.values.filter(sv => sv === v)[0];
                                            searchValue.checked = data.checked || false;
                                            return prevState;
                                        });
                                    };
                                    switch (s.inputType) {
                                        case SectionInputType.single:
                                            return (
                                                <Form.Radio key={v.title}
                                                            label={v.title}
                                                            value={v.title}
                                                            checked={v.checked}
                                                            onChange={onChange}/>
                                            );
                                        case SectionInputType.multi:
                                            return (
                                                <Form.Checkbox
                                                    key={v.title}
                                                    label={v.title}
                                                    value={v.title}
                                                    checked={v.checked}
                                                    onChange={onChange}
                                                />
                                            );
                                    }
                                })}
                            </Form>
                        );
                    })}
                </div>
            </Modal.Content>
        );
    }

    private static previousStageInfo(prevState: PatientCreationModalState): PreviousStageInfo {
        if (prevState.stage.key == "initial") {
            // There is no stage before the initial
            return {key: "cancel"};
        } else {
            const previousInjury = prevState.injuries[prevState.injuries.map(i => i.type).indexOf(prevState.stage.injury.type) - 1];
            if (previousInjury == undefined) {
                // This is the first injury, previous is initial
                return {
                    key: "previous",
                    previousStage: {key: "initial"},
                };
            } else if (previousInjury.sections.length == 0) {
                // Skip injuries without sections
                return PatientCreationModal.previousStageInfo({
                    age: prevState.age,
                    sex: prevState.sex,
                    injuries: prevState.injuries,
                    stage: {key: "injuryDetail", injury: previousInjury},
                    requestPhase: prevState.requestPhase,
                });
            } else {
                // Previous injury
                return {
                    key: "previous",
                    previousStage: {
                        key: "injuryDetail",
                        injury: previousInjury,
                    },
                };
            }
        }
    }

    private static nextStageInfo(prevState: PatientCreationModalState): NextStageInfo {
        let nextInjury;
        if (prevState.stage.key == "initial") {
            if (prevState.injuries.length == 0 || prevState.age == null || prevState.sex == null) {
                // Initial is incomplete
                return {key: "next", status: {key: "incomplete"}};
            }
            // Next injury is simply the first
            nextInjury = prevState.injuries[0];
        } else {
            if (prevState.stage.injury.sections.filter(s => s.isComplete == false).length > 0) {
                // An injury stage is incomplete
                return {key: "next", status: {key: "incomplete"}};
            }
            // Next stage is the next in list
            nextInjury = prevState.injuries[prevState.injuries.map(i => i.type).indexOf(prevState.stage.injury.type) + 1];
        }

        if (nextInjury == undefined) {
            // There are no more injuries
            return {key: "done"};
        } else if (nextInjury.sections.length == 0) {
            // Skip injuries without sections
            return PatientCreationModal.nextStageInfo({
                age: prevState.age,
                sex: prevState.sex,
                injuries: prevState.injuries,
                stage: {key: "injuryDetail", injury: nextInjury},
                requestPhase: prevState.requestPhase,
            });
        } else {
            // Next injury
            return {
                key: "next",
                status: {
                    key: "complete",
                    nextStage: {
                        key: "injuryDetail",
                        injury: nextInjury,
                    },
                },
            };
        }
    }

    render() {
        let content;
        if (this.state.stage.key == "initial") {
            content = this.renderInitial();
        } else {
            content = this.renderInjury(this.state.stage.injury);
        }
        const previousStageInfo = PatientCreationModal.previousStageInfo(this.state);
        const nextStageInfo = PatientCreationModal.nextStageInfo(this.state);

        return (
            <Modal
                className="patient-creation-modal"
                open={this.props.open}
                size="small"
                onClose={this.props.onCancel}
                closeOnDimmerClick={false}
            >
                <Modal.Header>{i18n.t("patients.newPatient")}</Modal.Header>
                {content}
                {this.state.requestPhase.key == "failed" ? (
                    <Message className="error-message" error content={this.state.requestPhase.message}/>
                ) : null}
                <Modal.Actions>
                    <Button
                        content={previousStageInfo.key == "previous" ? i18n.t("general.back") : i18n.t("general.cancel")}
                        onClick={() => {
                            if (previousStageInfo.key == "previous") {
                                this.setState({stage: previousStageInfo.previousStage});
                            } else {
                                this.props.onCancel();
                            }
                        }}/>
                    <Button
                        primary
                        content={nextStageInfo.key != "done" ? i18n.t("general.next") : i18n.t("general.done")}
                        disabled={nextStageInfo.key == "next" && nextStageInfo.status.key == "incomplete"}
                        loading={this.state.requestPhase.key == "loading"}
                        onClick={() => {
                            if (nextStageInfo.key == "next" && nextStageInfo.status.key == "complete") {
                                this.setState({stage: nextStageInfo.status.nextStage});
                            } else if (nextStageInfo.key == "done") {
                                this.setState({requestPhase: {key: "loading"}});
                                const {injuries, sex, age} = this.state;
                                if (sex == null || age == null) {
                                    config.error("Cannot create patient with `null` age or sex");
                                    return;
                                }
                                BryxApi.createPatient(injuries, sex, age, this.props.job.id, result => {
                                    if (result.success == true) {
                                        const prompts = injuries.map(i => i.prompts).filter(p => p != null) as string[];
                                        this.props.onCreatePatient(result.value, prompts.length > 0 ? prompts : null);
                                    } else {
                                        config.error(`Failed to create patient: ${result.debugMessage}`);
                                        this.setState({requestPhase: {key: "failed", message: result.message}});
                                    }
                                });
                            }
                        }}/>
                </Modal.Actions>
            </Modal>
        );
    }
}
