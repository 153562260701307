import * as i18n from "i18next";
import * as React from 'react';
import {FormEvent, SyntheticEvent} from "react";
import {Button, Form, Header, Input, InputProps, Message, Modal} from 'semantic-ui-react';
import {config} from "../../config";
import {BryxApi} from "../../utils/bryxApi";

interface ApparatusSignOutModalProps {
    open: boolean;
    onSignOut: () => void;
    onCancel: () => void;
}

interface ApparatusSignOutModalState {
    status: { key: "ready" } | { key: "loading" } | { key: "error", message: string };
    password: string;
}

export class ApparatusSignOutModal extends React.Component<ApparatusSignOutModalProps, ApparatusSignOutModalState> {
    constructor(props: ApparatusSignOutModalProps, context: any) {
        super(props, context);

        this.state = {
            status: {key: "ready"},
            password: "",
        };
    }

    private onPasswordChange(e: SyntheticEvent<any>, inputProps: InputProps) {
        this.setState({
            password: inputProps.value,
        });
    }

    private onSubmitSignOut(e: FormEvent<any>) {
        e.preventDefault();
        this.signOutApparatus();
    }

    private signOutApparatus() {
        this.setState({
            status: {
                key: "loading",
            },
        });

        BryxApi.signOutApparatus(this.state.password, result => {
            if (result.success == true) {
                config.info("Apparatus successfully signed out");
                this.props.onSignOut();
            } else {
                config.warn(`Apparatus failed to sign out: ${result.debugMessage}`);
                this.setState({
                    status: {key: "error", message: result.message},
                });
            }
        });
    }

    render() {
        return (
            <Modal
                className="apparatus-signout-modal"
                size="small"
                open={this.props.open}
                onClose={this.props.onCancel}
            >
                <Header icon="unlock alternate" content={i18n.t("nav.apparatusSignOut.title")}/>

                <Modal.Content className="modal-content">
                    <Form onSubmit={this.onSubmitSignOut.bind(this)}>
                        <Form.Field>
                            <Input placeholder={i18n.t("nav.apparatusSignOut.passwordPlaceholder")}
                                   autoFocus
                                   type="password"
                                   loading={this.state.status.key == "loading"}
                                   onChange={this.onPasswordChange.bind(this)}/>
                        </Form.Field>
                    </Form>
                    {this.state.status.key == "error" && <Message negative content={this.state.status.message}/>}
                </Modal.Content>

                <Modal.Actions>
                    <Button color="blue"
                            content={i18n.t("nav.apparatusSignOut.signOutButton")}
                            onClick={this.signOutApparatus.bind(this)}
                            disabled={this.state.status.key == "loading"}/>
                    <Button color="red"
                            content={i18n.t("general.cancel")}
                            disabled={this.state.status.key == "loading"}
                            onClick={this.props.onCancel}/>
                </Modal.Actions>
            </Modal>
        );
    }
}
