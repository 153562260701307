import {BryxLocal} from "./bryxLocal";
import {DeviceUtils} from "./deviceUtils";

export enum BryxNavigationApp {
    googleMaps, bingMaps, appleMaps, windowsMaps,
}

export enum BryxDateFormat {
    yearMonthDay, monthDayYear, dayMonthYear,
}

export interface BryxPreferences {
    useJobShortCode: boolean;
    useMetricUnits: boolean;
    use24HourTime: boolean;
    useWebNotifications: boolean;
    preferredNavigation: BryxNavigationApp;
    preferredDateFormat: BryxDateFormat;
}

export interface PreferenceManagerObserver {
    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void;
}

export class PreferenceManager {
    private observers: PreferenceManagerObserver[] = [];
    private prefCache: BryxPreferences | null = null;
    static shared = new PreferenceManager();

    public static readonly navigationAppOptions: BryxNavigationApp[] =
        Object.keys(BryxNavigationApp)
            .map(k => BryxNavigationApp[k as any] as any)
            .filter(v => typeof v == "number")
            .filter(o => {
                if (o == BryxNavigationApp.appleMaps) {
                    return DeviceUtils.deviceInfo.os.family == "OS X";
                } else if (o == BryxNavigationApp.windowsMaps) {
                    return DeviceUtils.deviceInfo.os.family == "Windows";
                }
                return true;
            });
    public static readonly dateFormatOptions: BryxDateFormat[] = Object.keys(BryxDateFormat).map(k => BryxDateFormat[k as any] as any).filter(v => typeof v == "number");

    private static readonly defaultPreferences: BryxPreferences = {
        useJobShortCode: true,
        useMetricUnits: false,
        use24HourTime: false,
        useWebNotifications: true,
        preferredNavigation: BryxNavigationApp.googleMaps,
        preferredDateFormat: BryxDateFormat.monthDayYear,
    };

    get preferences(): BryxPreferences {
        const existingPreferences: BryxPreferences | null = this.prefCache || BryxLocal.getItem("preferences");
        if (existingPreferences == null) {
            const newPreferences = PreferenceManager.defaultPreferences;
            this.prefCache = newPreferences;
            BryxLocal.setItem("preferences", newPreferences);
            return newPreferences;
        } else {
            return existingPreferences;
        }
    }

    public updatePreferences<K extends keyof BryxPreferences>(preferences: Pick<BryxPreferences, K>) {
        const newPreferences: any = this.preferences;
        Object.keys(preferences).forEach((k: any) => {
            // @ts-ignore
            newPreferences[k] = preferences[k];
        });
        this.prefCache = newPreferences;
        BryxLocal.setItem("preferences", newPreferences);
        this.observers.forEach(o => o.preferencesManagerDidUpdatePreferences(newPreferences));
    }

    public reset() {
        this.updatePreferences(PreferenceManager.defaultPreferences);
    }

    // PreferenceManagerObservers

    public registerObserver(observer: PreferenceManagerObserver) {
        if (this.observers.filter(o => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }

    public unregisterObserver(observer: PreferenceManagerObserver) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }
}
