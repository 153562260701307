import {config} from "../config";
import {BryxApi} from "./bryxApi";
import {BryxLocal} from "./bryxLocal";

export interface LocationManagerObserver {
    locationManagerDidUpdatePosition(position: Position): void;
    locationManagerDidClearPosition(): void;
}

export interface LocationStateObserver {
    locationManagerDidUpdateLocationState(state: LocationState): void;
}

type LocationResult = { key: "success", position: Position } | { key: "error", message: string };

export type LocationState = "default" | "granted" | "denied" | "disabled" | "unsupported";

export class LocationManager {
    public static readonly onDemandLocationTimeout = 1000 * 60 * 2;

    private locationObservers: LocationManagerObserver[] = [];
    private stateObservers: LocationStateObserver[] = [];
    public currentPosition: Position | null;
    public state: LocationState;
    private positionWatchId: number | null;
    static shared = new LocationManager();

    // Initialization / Deinitialization

    constructor() {
        const currentState = BryxLocal.getLocationState();
        if (!LocationManager.locationSupported()) {
            this.state = "unsupported";
        } else if (currentState == null) {
            this.state = "default";
            BryxLocal.setLocationState(this.state);
        } else {
            this.state = currentState;
            this.currentPosition = BryxLocal.getCurrentPosition();
        }
    }

    startWatch() {
        if (this.state != "granted") {
            config.debug(`Not starting LocationManager watch, state is '${this.state}'`);
            return;
        }
        config.debug("Starting watch on user location");
        this.positionWatchId = navigator.geolocation.watchPosition(position => {
            config.debug("navigator.geolocation position update");
            this.onGetPosition(position);
        }, error => {
            config.warn(`Failed to get user's watch position: CODE: ${error.code}, ${error.message}`);
            this.onFailPosition(error);
        });
    }

    private onGetPosition(position: Position) {
        this.currentPosition = position;
        BryxLocal.setCurrentPosition(position);
        if (localStorage.getItem("global.bryx.usingAvl") === 'true' && BryxLocal.getLocationDevices().filter(d => d.useForLocation).length > 0) return
        BryxApi.updateLocation(position, result => {
            if (result.success == true) {
                config.info("Successfully updated user's location");
            } else {
                const reason = result.message;
                if (reason != "This device is forbidden from updating your location.") {
                    config.warn(`Failed to update user's location: ${result.debugMessage || result.message}`);
                } else {
                    config.info("This is not the device for location");
                }
            }
        });
        this.locationObservers.forEach(o => o.locationManagerDidUpdatePosition(position));
    }

    private onFailPosition(error: PositionError) {
        if (error.code == 1) {
            this.updateState("denied");
        }
    }

    private updateState(state: LocationState) {
        this.state = state;
        BryxLocal.setLocationState(state);
        if ((state == "denied" || state == "disabled") && this.positionWatchId != null) {
            this.stopWatch();
            config.info(`LocationManager state changed to ${state}, stopping watch`);
        } else if (state == "granted" && this.positionWatchId == null) {
            this.startWatch();
            config.info(`LocationManager state changed to ${state}, starting watch`);
        }
        this.stateObservers.forEach(o => o.locationManagerDidUpdateLocationState(state));
    }

    stopWatch() {
        if (this.positionWatchId == null) {
            config.debug("Ignoring LocationManager.stopWatch, no watch in place");
            return;
        }
        config.info("Clearing watch on location");
        navigator.geolocation.clearWatch(this.positionWatchId);
        this.positionWatchId = null;
    }

    reset() {
        this.stopWatch();
        this.currentPosition = null;
        BryxLocal.removeCurrentPosition();
        this.locationObservers.forEach(o => o.locationManagerDidClearPosition());
    }

    // Location States

    static locationSupported(): boolean {
        return "geolocation" in navigator;
    }

    requestAccess(callback: (state: LocationState) => void) {
        if (this.state == "unsupported") {
            callback(this.state);
            return;
        }
        navigator.geolocation.getCurrentPosition(position => {
            this.updateState("granted");
            callback(this.state);
            this.onGetPosition(position);
        }, error => {
            this.updateState(error.code == 1 ? "denied" : "granted");
            callback(this.state);
        }, {
            // Set the timeout to 1 millisecond.
            // The request will almost-certainly timeout, but we just use this call to get the permission status.
            timeout: 1,
        });
    }

    requestUpdate(callback?: (result: LocationResult) => void) {
        if (this.state != "granted") {
            config.debug(`Not handling update request, state is '${this.state}'`);
            if (callback != null) {
                callback({
                    key: "error",
                    message: "Not granted access to location",
                });
            }
            return;
        }

        config.debug(`Requesting location update: ${new Date().getTime() / 1000}`);

        navigator.geolocation.getCurrentPosition(position => {
            config.debug(`Got additional position update on request: ${new Date().getTime() / 1000}`);
            if (callback != null) {
                callback({
                    key: "success",
                    position: position,
                });
            }
            this.onGetPosition(position);
        }, error => {
            config.warn(`Failed to get additional position update: CODE: ${error.code}, ${error.message}`);
            if (callback != null) {
                callback({
                    key: "error",
                    message: error.message,
                });
            }
        }, {
            enableHighAccuracy: true,
            timeout: LocationManager.onDemandLocationTimeout,
            maximumAge: 0,
        });
    }

    disableAccess() {
        this.currentPosition = null;
        this.updateState("disabled");
        BryxLocal.removeCurrentPosition();
        this.locationObservers.forEach(o => o.locationManagerDidClearPosition());
    }

    // LocationManagerObservers

    public registerObserver(observer: LocationManagerObserver) {
        if (this.locationObservers.filter(o => o === observer).length == 0) {
            this.locationObservers.push(observer);
        }
    }

    public unregisterObserver(observer: LocationManagerObserver) {
        const observerIndex = this.locationObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.locationObservers.splice(observerIndex, 1);
        }
    }

    public registerStateObserver(observer: LocationStateObserver) {
        if (this.stateObservers.filter(o => o === observer).length == 0) {
            this.stateObservers.push(observer);
        }
    }

    public unregisterStateObserver(observer: LocationStateObserver) {
        const observerIndex = this.stateObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.stateObservers.splice(observerIndex, 1);
        }
    }
}
