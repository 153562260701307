import * as i18n from "i18next";
import * as React from 'react';
import {Button, Header, Icon, Loader, Menu, Popup} from "semantic-ui-react";
import {config} from "../config";
import {BryxColors} from "../utils/bryxColors";
import {DateUtils} from "../utils/dateUtils";
import {NotificationManager, NotificationStatus} from "../utils/notificationManager";
import {UpdateManager} from "../utils/updateManager";
import {NotificationItem} from "./notificationItem";
import {ZeroStateView} from "./views/zeroStateView";

interface NotificationMenuProps {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
    onClickSettings: () => void;
    status: NotificationStatus;
}

interface NotificationMenuState {
    currentTime: Date;
}

export class NotificationMenu extends React.Component<NotificationMenuProps, NotificationMenuState> {
    private timerId: NodeJS.Timer | null = null;

    constructor(props: NotificationMenuProps, context: any) {
        super(props, context);
        this.state = {
            currentTime: DateUtils.bryxNow(),
        };
    }

    componentDidMount() {
        this.timerId = setInterval(
            () => this.setState({currentTime: DateUtils.bryxNow()}),
            1000,
        );
    }

    componentWillUnmount() {
        if (this.timerId != null) {
            clearInterval(this.timerId);
        }
    }

    render() {
        const {status} = this.props;
        const hasNotifications = status.key == "active" && status.notifications.length != 0;
        let content;
        if (status.key == "loading") {
            content = (
                <div className="flexCenteredContainer">
                    <Loader inline active/>
                </div>
            );
        } else if (status.key == "failed") {
            content = (
                <div className="flexCenteredContainer" style={{height: "100%"}}>
                    <ZeroStateView
                        header={i18n.t("notifications.failedToLoad")}
                        subheader={
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <span style={{marginBottom: "10px"}}>{status.message}</span>
                                <Button
                                    onClick={UpdateManager.shared.update}>{i18n.t("general.reload")}</Button>
                            </div>
                        }
                        icon="warning sign"/>
                </div>
            );
        } else if (status.notifications.length == 0) {
            content = (
                <div className="flexCenteredContainer">
                    <span style={{display: "block", color: BryxColors.gray, fontSize: "23px"}}>
                        {i18n.t("notifications.noNotifications")}
                    </span>
                </div>
            );
        } else {
            content = (
                <div style={{flex: 1}}>
                    {status.notifications.map(n => (
                        <NotificationItem
                            key={n.id}
                            notification={n}
                            currentTime={this.state.currentTime}
                            onClick={() => {
                                this.props.onClose();
                                NotificationManager.shared.notifyClickNotification(n);
                            }}
                            onClear={() => NotificationManager.shared.notifyClearNotification(n)}/>
                    ))}
                </div>
            );
        }
        return (
            <Popup
                id="notificationsMenu"
                on="click"
                position="bottom right"
                open={this.props.open}
                onOpen={() => {
                    this.props.onOpen();
                    config.debug("notificationMenu: onOpen");
                }}
                onClose={() => {
                    this.props.onClose();
                    config.debug("notificationMenu: onClose");
                }}
                trigger={
                    <Menu.Item
                        id="notification-group"
                        className={hasNotifications && !this.props.open ? "active" : undefined}>
                        <Icon link size="large" name="bell" style={{margin: 0}}/>
                    </Menu.Item>
                }>
                <Popup.Header className="notification-header">
                    <Header className="header-title" as="h3">{i18n.t("notifications.title")}</Header>
                    <div className="header-button-container">
                        {status.key == "active" && status.notifications.length != 0 ? (
                            <Button
                                className="clear-notifications-button"
                                negative
                                compact
                                content={i18n.t("notifications.clear")}
                                onMouseDown={() => NotificationManager.shared.notifyClearAll()}/>
                        ) : <div/>}
                        <Button
                            className="settings-button"
                            compact
                            content={i18n.t("nav.settings.settings")}
                            onMouseDown={this.props.onClickSettings}/>
                    </div>
                </Popup.Header>
                <Popup.Content className="notification-menu-content">
                    {content}
                </Popup.Content>
            </Popup>
        );
    }
}
