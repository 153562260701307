import { ExitToApp, SupervisedUserCircle, Add, Search, ExpandMore } from "@mui/icons-material";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { Label, Segment, Button, Tab, Input, Modal, Card } from "semantic-ui-react";
import { ClientAgency } from "../../../models/agency";
import { MinimalNamedModel } from "../../../models/minimal";
import { PaginatedResult } from "../../../models/paginatedResult";
import { BryxApi } from "../../../utils/bryxApi";
import { usePagination, PaginatedItems } from "../../paginatedItems";
import { ModalPaneProps, SettingsSection } from "./util";
import React from "react";
import {toast} from "react-toastify";
import { useConstCallback } from "@bryxinc/ts-utils";

function AgencyListItem(props: {
    agency: ClientAgency;
    onGroupLeave: (group: MinimalNamedModel) => void;
    onAgencyLeave: (agency: ClientAgency) => void;
}) {
    const [expanded, setExpanded] = useState(false);
    const { agency } = props;
    const onGroupLeave = useConstCallback(props.onGroupLeave);
    const onAgencyLeave = useConstCallback(props.onAgencyLeave);

    useEffect(() => setExpanded(false), [props.agency.id]);

    return (
        <div
            className="agency-item-wrapper"
            onClick={() => setExpanded((expanded) => !expanded)}
        >
            <div className="agency-header">
                <ExpandMore className={expanded ? "expanded" : ""} />
                <span className="agency-name">{agency.name}</span>
                {agency.manager && (
                    <Label className="client-role" color="blue">
                        {i18n.t("account.sections.myAgencies.manager")}
                    </Label>
                )}
            </div>

            {expanded && (
                <div
                    className="group-wrapper"
                    onClick={(event) => event.stopPropagation()}
                >
                    <Segment
                        placeholder
                        size="small"
                        className="dispatch-groups"
                    >
                        {agency.dispatchGroups.length > 0 ? (
                            agency.dispatchGroups.map((group) => (
                                <Card
                                    id={group.id}
                                    key={group.id}
                                    className="unit-card"
                                >
                                    <Card.Header>{group.name}</Card.Header>
                                    <Card.Meta>
                                        <Button
                                            className="leave-group"
                                            onClick={(event) => {
                                                onGroupLeave(group);
                                                event.stopPropagation();
                                            }}
                                        >
                                            <ExitToApp />
                                            <span>
                                                {i18n.t(
                                                    "account.sections.myAgencies.leave"
                                                )}
                                            </span>
                                        </Button>
                                    </Card.Meta>
                                </Card>
                            ))
                        ) : (
                            <span className="no-groups">
                                {i18n.t("account.sections.myAgencies.noGroups")}
                            </span>
                        )}
                    </Segment>
                </div>
            )}
            <Button
                className="leave-agency"
                onClick={(event) => {
                    onAgencyLeave(agency);
                    event.stopPropagation();
                }}
            >
                <ExitToApp />
                <span>{i18n.t("account.sections.myAgencies.leave")}</span>
            </Button>
        </div>
    );
}

export function AgencyPane({ section, onChangeSection }: ModalPaneProps) {
    const [agencyLeave, setAgencyLeave] = useState<null | ClientAgency>(null);
    const [groupLeave, setGroupLeave] = useState<null | MinimalNamedModel>(
        null
    );

    // progressSearch tracks current search value, search updates when the Search button is pressed or when Enter is pressed (ie it's the submitted value)
    const [progressSearch, setProgressSearch] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    const controls = usePagination<ClientAgency, string>(
        (page, pageSize, search) =>
            new Promise<PaginatedResult<ClientAgency>>((resolve, reject) => {
                BryxApi.searchAgencies(
                    (result) => {
                        if (result.success) {
                            resolve(result.value);
                        } else {
                            reject(result.message);
                        }
                    },
                    pageSize,
                    page,
                    search
                );
            }),
        {},
        search
    );

    return (
        <Tab.Pane className="settings-tab agencies" attached={false}>
            <Segment.Group>
                <SettingsSection
                    section="myAgencies"
                    icon={<SupervisedUserCircle />}
                    open={section == "agency.view"}
                    onToggle={(open) =>
                        onChangeSection(open ? "agency.view" : null)
                    }
                >
                    <Input
                        action={{
                            onClick: () => setSearch(progressSearch),
                            icon: <Search />,
                        }}
                        value={progressSearch}
                        onChange={(event) =>
                            setProgressSearch(event.target.value)
                        }
                        fluid
                        placeholder={i18n.t("general.search")}
                        input={{
                            onKeyDown: useConstCallback((event) => {
                                if (event.key == "Enter") {
                                    setSearch(progressSearch);
                                }
                            }),
                        }}
                    />
                    <PaginatedItems<ClientAgency>
                        item={(agency) => (
                            <AgencyListItem
                                agency={agency}
                                onGroupLeave={setGroupLeave}
                                onAgencyLeave={setAgencyLeave}
                            />
                        )}
                        control={controls}
                    />
                </SettingsSection>
                <SettingsSection
                    section="joinAgencies"
                    icon={<Add />}
                    open={section == "agency.join"}
                    onToggle={(open) =>
                        onChangeSection(open ? "agency.join" : null)
                    }
                ></SettingsSection>
            </Segment.Group>
            <Modal
                open={agencyLeave != null}
                onClose={useConstCallback(() => setAgencyLeave(null))}
                className="leave-modal"
            >
                <Modal.Header>
                    {i18n.t("account.sections.myAgencies.confirmTitle")}
                </Modal.Header>
                <Modal.Content>
                    {i18n.t("account.sections.myAgencies.confirmMessage", {
                        agencyOrGroup: agencyLeave?.name,
                    })}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        className="leave-cancel"
                        onClick={() => setAgencyLeave(null)}
                    >
                        {i18n.t("account.sections.myAgencies.confirmCancel")}
                    </Button>
                    <Button
                        className="leave-confirm"
                        onClick={() => {
                            BryxApi.leaveAgency(
                                agencyLeave?.id ?? "",
                                (result) => {
                                    if (result.success) {
                                        toast.success(
                                            i18n.t(
                                                "account.sections.myAgencies.leaveSuccess"
                                            )
                                        );
                                    } else {
                                        if (
                                            result.message ===
                                            "You cannot remove yourself from an agency you manage."
                                        ) {
                                            toast.error(
                                                i18n.t(
                                                    "account.sections.myAgencies.errors.leave403"
                                                )
                                            );
                                        } else {
                                            toast.error(
                                                i18n.t(
                                                    "account.sections.myAgencies.errors.leave"
                                                )
                                            );
                                        }
                                    }
                                    setAgencyLeave(null);
                                    controls.refresh();
                                }
                            );
                        }}
                    >
                        {i18n.t("account.sections.myAgencies.confirmSubmit")}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal
                open={groupLeave != null}
                onClose={useConstCallback(() => setGroupLeave(null))}
                className="leave-group-modal"
            >
                <Modal.Header>
                    {i18n.t("account.sections.myAgencies.confirmGroupTitle")}
                </Modal.Header>
                <Modal.Content>
                    {i18n.t("account.sections.myAgencies.confirmMessage", {
                        agencyOrGroup: groupLeave?.name ?? undefined,
                    })}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        className="leave-cancel"
                        onClick={useConstCallback(() => setGroupLeave(null))}
                    >
                        {i18n.t("account.sections.myAgencies.confirmCancel")}
                    </Button>
                    <Button
                        className="leave-confirm"
                        onClick={() => {
                            BryxApi.leaveGroup(
                                groupLeave?.id ?? "",
                                (result) => {
                                    if (result.success) {
                                        toast.success(
                                            i18n.t(
                                                "account.sections.myAgencies.groupLeaveSuccess"
                                            )
                                        );
                                    } else {
                                        toast.error(
                                            i18n.t(
                                                "account.sections.myAgencies.errors.leaveGroup"
                                            )
                                        );
                                    }
                                    setGroupLeave(null);
                                    controls.refresh();
                                }
                            );
                        }}
                    >
                        {i18n.t("account.sections.myAgencies.confirmSubmit")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Tab.Pane>
    );
}
