import {GeometryObject, Point} from "geojson";
import {config} from "../config";
import {ApiResult, BryxApi} from "../utils/bryxApi";
import {ParseResult, ParseUtils} from "../utils/cerealParse";
import {arraysEqual} from "../utils/functions";
import {Address} from "./address";
import {Complainant} from "./complainant";
import {MinimalHospital} from "./hospital";
import {Hydrant} from "./hydrant";
import {JobTypeInformation} from "./jobTypeInformation";
import {Patient} from "./patient";
import {Responder, ResponseOption} from "./responder";
import {SiteSurvey} from "./siteSurvey";
import {Supplemental} from "./supplemental";

export enum Disposition {
    open, old, closed,
}

export enum RespondPermission {
    on, off, forced,
}

export class MinimalJob {
    public constructor(
        public id: string,
        public creationTime: Date,
        public typeInformation: JobTypeInformation,
        public unitShortNames: string[],
        public synopsis: string,
        public centroid: Point | null,
        public address: Address | null,
        public disposition: Disposition,
    ) {}

    static parse(o: any): ParseResult<MinimalJob> {
        try {
            return ParseUtils.parseSuccess(new MinimalJob(
                ParseUtils.getString(o, "id"),
                ParseUtils.getUNIXTimestampDate(o, "ts"),
                ParseUtils.getSubobject(o, "type", JobTypeInformation.parse),
                ParseUtils.getArray(o, "unitShortNames"),
                ParseUtils.getString(o, "synopsis"),
                o["centroid"] || null,
                ParseUtils.getSubobject(o, "address", Address.parse),
                ParseUtils.getEnum(o, "disposition", Disposition),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<MinimalJob>(`Invalid Minimal Job Model: ${e.message}`);
        }
    }

    get shortDepartment(): string { return this.unitShortNames.join(', '); }
    get isOpen(): boolean { return this.disposition != Disposition.closed; }
}

export class ListJob extends MinimalJob {
    private constructor(
        public id: string,
        public creationTime: Date,
        public typeInformation: JobTypeInformation,
        public unitShortNames: string[],
        public synopsis: string,
        public centroid: Point | null,
        public address: Address | null,
        public disposition: Disposition,
        public hasResponded: boolean,
    ) { super(id, creationTime, typeInformation, unitShortNames, synopsis, centroid, address, disposition); }

    static parse(o: any): ParseResult<ListJob> {
        try {
            const minimalParse = MinimalJob.parse(o);
            if (minimalParse.success == false) {
                return ParseUtils.parseFailure<ListJob>(`Invalid List Job: ${minimalParse.justification}`);
            }

            const minimal = minimalParse.value;

            return ParseUtils.parseSuccess(new ListJob(
                minimal.id,
                minimal.creationTime,
                minimal.typeInformation,
                minimal.unitShortNames,
                minimal.synopsis,
                minimal.centroid,
                minimal.address,
                minimal.disposition,
                ParseUtils.getBoolean(o, "hasResponded"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<ListJob>(`Invalid ListJob Model: ${e.message}`);
        }
    }

    static compare(j1: ListJob, j2: ListJob): number {
        return j2.creationTime.getTime() - j1.creationTime.getTime() || j1.id.localeCompare(j2.id);
    }
}

export class FullJob extends MinimalJob {
    public historicalJobs: ListJob[] | null = null;
    public siteSurvey: SiteSurvey | null = null;

    private constructor(
        public id: string,
        public creationTime: Date,
        public typeInformation: JobTypeInformation,
        public unitShortNames: string[],
        public synopsis: string,
        public centroid: Point | null,
        public address: Address | null,
        public disposition: Disposition,
        public complainant: Complainant | null,
        public supplementals: Supplemental[],
        public box: string | null,
        public canHospitalAlert: boolean,
        public crossStreets: string | null,
        public cadId: string | null,
        public patients: Patient[],
        public hasHistorical: boolean,
        public hospitals: MinimalHospital[],
        public hydrants: Hydrant[] | null,
        public incidentId: string | null,
        public location: GeometryObject,
        public priority: string | null,
        public responders: Responder[],
        public responseOptions: ResponseOption[],
        public respondPermission: RespondPermission,
        public criticalWarning: string | null,
    ) { super(id, creationTime, typeInformation, unitShortNames, synopsis, centroid, address, disposition); }

    static parse(o: any): ParseResult<FullJob> {
        try {
            const minimalParse = MinimalJob.parse(o);
            if (minimalParse.success == false) {
                return ParseUtils.parseFailure<FullJob>(`Invalid FullJob Model: ${minimalParse.justification}`);
            }

            const minimal = minimalParse.value;

            return ParseUtils.parseSuccess(new FullJob(
                minimal.id,
                minimal.creationTime,
                minimal.typeInformation,
                minimal.unitShortNames,
                minimal.synopsis,
                minimal.centroid,
                minimal.address,
                minimal.disposition,
                ParseUtils.getSubobjectOrNull(o, "comp", Complainant.parse),
                ParseUtils.getArrayOfSubobjects(o, "supplementals", Supplemental.parse, "warn"),
                ParseUtils.getStringOrNull(o, "box"),
                ParseUtils.getBoolean(o, "canHospitalAlert"),
                ParseUtils.getStringOrNull(o, "crossStreets"),
                ParseUtils.getStringOrNull(o, "cadId"),
                ParseUtils.getArrayOfSubobjects(o, "patients", Patient.parse, "warn"),
                ParseUtils.getBoolean(o, "hasHistorical"),
                ParseUtils.getArrayOfSubobjects(o, "hospitals", MinimalHospital.parse, "warn"),
                ParseUtils.getArrayOfSubobjectsOrNull(o, "hydrants", Hydrant.parse, "warn"),
                ParseUtils.getStringOrNull(o, "incidentId"),
                o["location"],
                ParseUtils.getStringOrNull(o, "priority"),
                ParseUtils.getArrayOfSubobjects(o, "responders", Responder.parse, "warn"),
                ParseUtils.getArrayOfSubobjects(o, "responseOptions", ResponseOption.parse, "warn").sort(ResponseOption.compare),
                ParseUtils.getEnum(o, "respondPermission", RespondPermission),
                ParseUtils.getStringOrNull(o, "criticalWarning"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<FullJob>(`Invalid FullJob Model: ${e.message}`);
        }
    }

    updateResponders(responders: Responder[]) {
        if (arraysEqual(this.responders, responders, (a, b) => a.isEqual(b))) {
            config.info("Not updating identical responders");
            return;
        }
        this.responders = responders;
    }

    updateSupplementals(supplementals: Supplemental[]) {
        if (arraysEqual(this.supplementals, supplementals, (a, b) => a.isEqual(b))) {
            config.info("Not updating identical supplementals");
            return;
        }
        this.supplementals = supplementals;
    }

    updatePatients(patients: Patient[]) {
        this.patients = patients;
    }

    updateUnitShortNames(unitShortNames: string[]) {
        if (arraysEqual(this.unitShortNames, unitShortNames, (a, b) => a == b)) {
            config.info("Not updating identical unitShortNames");
            return;
        }
        this.unitShortNames = unitShortNames;
    }

    updateHydrants(hydrants: Hydrant[]) {
        // FIXME: Update hydrants; Waiting on API implementation
    }

    updateWithJob(job: FullJob) {
        if (job.id != this.id) {
            config.error("Attempted to update job with wrong job");
            return;
        }
        this.updateResponders(job.responders);
        const newSupplementals = job.supplementals;
        if (newSupplementals != null) {
            this.updateSupplementals(newSupplementals);
        }
        this.updatePatients(job.patients);
        this.updateUnitShortNames(job.unitShortNames);
        const newHydrants = job.hydrants;
        if (newHydrants != null) {
            this.updateHydrants(newHydrants);
        }
    }

    loadSiteSurvey(callback: (result: ApiResult<SiteSurvey | null>) => void) {
        BryxApi.loadSiteSurvey(this.id, result => {
            if (result.success == true) {
                this.siteSurvey = result.value;
            }
            callback(result);
        });
    }

    loadHistoricalJobs(callback: (result: ApiResult<ListJob[]>) => void) {
        BryxApi.loadHistoricalJobs(this.id, result => {
            if (result.success == true) {
                this.historicalJobs = result.value;
            }
            callback(result);
        });
    }

    get userResponder(): Responder | null { return this.responders.filter(r => r.isMe).shift() || null; }
    get hasResponded(): boolean { return this.userResponder != null; }
}
