import {ParseResult, ParseUtils} from "../utils/cerealParse";

export enum MessageContentType {
    text, image, bryx,
}
export type MessageContentText = {type: MessageContentType.text, text: string};
export type MessageContentImage = {type: MessageContentType.image, imageId: string, width: number, height: number};
export type MessageContentBryx = {type: MessageContentType.bryx, text: string};
export type MessageContent = MessageContentText | MessageContentImage | MessageContentBryx;

export type MessageParameterText = {type: MessageContentType.text, text: string};
export type MessageParameterImage = {type: MessageContentType.image, image: string};
export type MessageParameter = MessageParameterText | MessageParameterImage;

export enum MessageStatusType {
    loaded, sending, failed,
}
export type MessageStatus = {key: MessageStatusType.loaded} | {key: MessageStatusType.sending} | {key: MessageStatusType.failed, error: string};
export type MessageSender = {id: string, name: string, isMe: boolean};

export class Message {
    constructor(
        public id: string,
        public timeSent: Date,
        public sender: MessageSender,
        public content: MessageContent,
        public status: MessageStatus,
    ) {}

    static parse(o: any): ParseResult<Message> {
        try {
            let content: MessageContent;
            const contentType = ParseUtils.getEnum(o, "type", MessageContentType);
            switch (contentType) {
                case MessageContentType.text:
                    content = {type: MessageContentType.text, text: ParseUtils.getString(o["content"], "text")};
                    break;
                case MessageContentType.image:
                    content = {
                        type: MessageContentType.image,
                        imageId: ParseUtils.getString(o["content"], "imageId"),
                        width: ParseUtils.getNumber(o["content"], "width"),
                        height: ParseUtils.getNumber(o["content"], "height"),
                    };
                    break;
                case MessageContentType.bryx:
                    content = {type: MessageContentType.bryx, text: ParseUtils.getString(o["content"], "text")};
                    break;
                default:
                    return ParseUtils.parseFailure<Message>(`Invalid Message Model: invalid content type '${contentType}'`);
            }
            return ParseUtils.parseSuccess(new Message(
                ParseUtils.getString(o, "id"),
                ParseUtils.getUNIXTimestampDate(o["timeSent"], "sec"),
                {
                    id: ParseUtils.getString(o, "from"),
                    name: ParseUtils.getString(o, "name"),
                    isMe: ParseUtils.getBoolean(o, "isMe"),
                },
                content,
                {key: MessageStatusType.loaded},
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Message>(`Invalid Message Model: ${e.message}`);
        }
    }

    static compare(m1: Message, m2: Message): number {
        return (m1.timeSent.getTime() - m2.timeSent.getTime()) || (m1.id.localeCompare(m2.id));
    }
}
