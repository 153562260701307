import {Point} from "geojson";
import {ParseResult, ParseUtils} from "../utils/cerealParse";

export enum MapClientType {
    user, truck,
}

type MapClientInfo = {
    type: MapClientType.user,
    firstName: string,
    lastName: string,
} | {
    type: MapClientType.truck,
    name: string,
    shortName: string,
};

export class MapClient {
    private constructor(
        public id: string,
        public info: MapClientInfo,
        public location: Point,
        public lastUpdated: Date,
    ) {}

    get name(): string {
        if (this.info.type == MapClientType.user) {
            return `${this.info.firstName} ${this.info.lastName}`;
        } else {
            return this.info.name;
        }
    }

    get initials(): string {
        if (this.info.type == MapClientType.user) {
            return `${this.info.firstName.slice(0, 1)}${this.info.lastName.slice(0, 1)}`.toUpperCase();
        } else {
            return this.info.shortName.slice(0, 4);
        }
    }

    static parse(o: any): ParseResult<MapClient> {
        try {
            const type = ParseUtils.getEnum(o, "type", MapClientType);
            let info: MapClientInfo;
            if (type == MapClientType.user) {
                info = {
                    type: MapClientType.user,
                    firstName: ParseUtils.getString(o, "firstName"),
                    lastName: ParseUtils.getString(o, "lastName"),
                };
            } else {
                info = {
                    type: MapClientType.truck,
                    name: ParseUtils.getString(o, "name"),
                    shortName: ParseUtils.getString(o, "shortName"),
                };
            }
            return ParseUtils.parseSuccess(new MapClient(
                ParseUtils.getString(o, "id"),
                info,
                o["location"],
                ParseUtils.getUNIXTimestampDate(o["location"]["properties"]["lastUpdated"], "sec"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<MapClient>(`Invalid MapClient Model: ${e.message}`);
        }
    }
}
