import * as i18n from "i18next";
import * as React from "react";
import {Checkbox, Form, Icon, Message, Popup} from "semantic-ui-react";
import {config} from "../../config";
import {BryxApi} from "../../utils/bryxApi";
import {BryxLocal} from "../../utils/bryxLocal";
import {
    NotificationManager,
    NotificationPermissionObserver,
    WebNotificationPermission,
} from "../../utils/notificationManager";
import {BryxDateFormat, BryxNavigationApp, BryxPreferences, PreferenceManager} from "../../utils/preferenceManager";

interface GeneralTabState {
    preferences: BryxPreferences;
    notificationPermission: WebNotificationPermission;
    allowNotificationsState: {
        allowNotifications: boolean,
        subState: { key: "ready" } | { key: "loading" } | { key: "failed", message: string },
    };
    darkModeEnabled: boolean;
}

export class GeneralTab extends React.Component<{}, GeneralTabState> implements NotificationPermissionObserver {
    constructor(props: {}, context: any) {
        super(props, context);
        this.state = {
            preferences: PreferenceManager.shared.preferences,
            notificationPermission: NotificationManager.shared.permission,
            allowNotificationsState: {
                allowNotifications: BryxLocal.getAllowNotifications() || false,
                subState: {key: "ready"},
            },
            darkModeEnabled: document.body.classList.contains('dark') || localStorage.getItem('darkMode') == 'true',
        };
    }

    componentDidMount() {
        NotificationManager.shared.registerPermissionObserver(this);
    }

    componentWillUnmount() {
        NotificationManager.shared.unregisterPermissionObserver(this);
    }

    // NotificationPermissionObserver Functions

    notificationManagerDidUpdateNotificationPermission(permission: WebNotificationPermission): void {
        this.setState({notificationPermission: permission});
    }

    render() {
        const prefs = this.state.preferences;
        let additionalInfoLine = null;

        if (NotificationManager.shared.permission == "unsupported") {
            additionalInfoLine = i18n.t("settings.general.webNotificationsDescription.unsupported");
        } else if (NotificationManager.shared.permission == "denied") {
            additionalInfoLine = i18n.t("settings.general.webNotificationsDescription.denied");
        }
        const pushNotificationsLabel = (
            <Popup trigger={<Icon className="notifications-label" name="help circle"/>}>
                <Popup.Header>{i18n.t("settings.general.receivePushNotificationsHeader")}</Popup.Header>
                <Popup.Content className="bryx-text">
                    <p>{i18n.t("settings.general.receivePushNotificationsDescription")}</p>
                    {additionalInfoLine != null ? <p>{additionalInfoLine}</p> : null}
                </Popup.Content>
            </Popup>
        );
        const desktopNotificationsLabel = (
            <Popup trigger={<Icon className="notifications-label" name="help circle"/>}>
                <Popup.Header className="bryx-text">{i18n.t("settings.general.webNotificationsHeader")}</Popup.Header>
                <Popup.Content className="bryx-text">
                    <p>{i18n.t("settings.general.webNotificationsDescription.basic")}</p>
                    {additionalInfoLine != null ? <p>{additionalInfoLine}</p> : null}
                </Popup.Content>
            </Popup>
        );
        return (
            <Form className="general-tab bryx-text">
                <Form.Field>
                    <Checkbox
                        label={i18n.t("settings.general.preferences.useJobShortCode")}
                        checked={prefs.useJobShortCode}
                        onChange={(e, d) => {
                            const useJobShortCode = d.checked || false;
                            PreferenceManager.shared.updatePreferences({
                                useJobShortCode: useJobShortCode,
                            });
                            this.setState((prevState => {
                                prevState.preferences.useJobShortCode = useJobShortCode;
                                return prevState;
                            }));
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        label={i18n.t("settings.general.preferences.useMetricUnits")}
                        checked={prefs.useMetricUnits}
                        onChange={(e, d) => {
                            const useMetricUnits = d.checked || false;
                            PreferenceManager.shared.updatePreferences({
                                useMetricUnits: useMetricUnits,
                            });
                            this.setState((prevState => {
                                prevState.preferences.useMetricUnits = useMetricUnits;
                                return prevState;
                            }));
                        }}/>
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        label={i18n.t("settings.general.preferences.use24HourTime")}
                        checked={prefs.use24HourTime}
                        onChange={(e, d) => {
                            const use24HourTime = d.checked || false;
                            PreferenceManager.shared.updatePreferences({
                                use24HourTime: use24HourTime,
                            });
                            this.setState((prevState => {
                                prevState.preferences.use24HourTime = use24HourTime;
                                return prevState;
                            }));
                        }}/>
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        label={i18n.t("settings.general.preferences.receivePushNotifications")}
                        checked={this.state.allowNotificationsState.allowNotifications}
                        disabled={this.state.allowNotificationsState.subState.key == "loading"}
                        onChange={(e, d) => {
                            const allowNotifications = d.checked || false;
                            this.setState(prevState => ({
                                allowNotificationsState: {
                                    allowNotifications: prevState.allowNotificationsState.allowNotifications,
                                    subState: {key: "loading"},
                                },
                            }));
                            BryxApi.setAllowNotifications(allowNotifications, result => {
                                if (result.success == true) {
                                    BryxLocal.setAllowNotifications(allowNotifications);
                                    this.setState({
                                        allowNotificationsState: {
                                            allowNotifications: allowNotifications,
                                            subState: {key: "ready"},
                                        },
                                    });
                                } else {
                                    config.warn(`Failed to enable device push notifications: ${result.debugMessage}`);
                                    this.setState(prevState => ({
                                        allowNotificationsState: {
                                            allowNotifications: prevState.allowNotificationsState.allowNotifications,
                                            subState: {key: "failed", message: result.message},
                                        },
                                    }));
                                }
                            });
                        }}/>
                    {pushNotificationsLabel}
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        label={i18n.t("settings.general.preferences.webNotifications")}
                        checked={this.state.notificationPermission == "granted" && prefs.useWebNotifications}
                        disabled={this.state.notificationPermission == "denied" || this.state.notificationPermission == "unsupported"}
                        onChange={(e, d) => {
                            const useWebNotifications = d.checked || false;
                            PreferenceManager.shared.updatePreferences({
                                useWebNotifications: useWebNotifications,
                            });
                            this.setState((prevState => {
                                prevState.preferences.useWebNotifications = useWebNotifications;
                                return prevState;
                            }));
                            if (useWebNotifications && NotificationManager.shared.permission == "default") {
                                Notification.requestPermission().then(permission => {
                                    this.setState({notificationPermission: permission});
                                });
                            }
                        }}/>
                    {desktopNotificationsLabel}
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        className='dark-mode-toggle'
                        label={i18n.t(`settings.general.darkModeToggle`)}
                        checked={this.state.darkModeEnabled}
                        onChange={() => {
                            const darkModeOn = !this.state.darkModeEnabled;
                            this.setState((prevState) => {
                                const newState = {...prevState};
                                newState.darkModeEnabled = darkModeOn;
                                return newState;
                            });
                            if (darkModeOn) {
                                document.body.classList.remove('light');
                                document.body.classList.add('dark');
                                localStorage.setItem('darkMode', 'true');
                            } else {
                                document.body.classList.add('light');
                                document.body.classList.remove('dark');
                                localStorage.setItem('darkMode', 'false');
                            }
                        }}
                    />
                </Form.Field>
                <Form.Select
                    label={i18n.t("settings.general.preferences.preferredNavigation")}
                    value={PreferenceManager.shared.preferences.preferredNavigation}
                    options={PreferenceManager.navigationAppOptions.map(o => ({
                        key: o,
                        text: i18n.t(`settings.general.navigationApps.${BryxNavigationApp[o] as string}`),
                        value: o,
                    }))}
                    onChange={(e, d) => {
                        const preferredNavigation = (d.value != null ? d.value : prefs.preferredNavigation) as BryxNavigationApp;
                        PreferenceManager.shared.updatePreferences({
                            preferredNavigation: preferredNavigation,
                        });
                        this.setState((prevState => {
                            prevState.preferences.preferredNavigation = preferredNavigation;
                            return prevState;
                        }));
                    }}/>
                <Form.Select
                    label={i18n.t("settings.general.preferences.preferredDateFormat")}
                    value={PreferenceManager.shared.preferences.preferredDateFormat}
                    options={PreferenceManager.dateFormatOptions.map(o => ({
                        key: o,
                        text: i18n.t(`settings.general.dateFormats.${BryxDateFormat[o] as string}`),
                        value: o,
                    }))}
                    onChange={(e, d) => {
                        const preferredDateFormat = (d.value != null ? d.value : prefs.preferredDateFormat) as BryxDateFormat;
                        PreferenceManager.shared.updatePreferences({
                            preferredDateFormat: preferredDateFormat,
                        });
                        this.setState((prevState => {
                            prevState.preferences.preferredDateFormat = preferredDateFormat;
                            return prevState;
                        }));
                    }}
                />
                {this.state.allowNotificationsState.subState.key == "failed" ? (
                    <Message
                        negative
                        header={i18n.t("settings.general.receivePushNotificationsError")}
                        content={this.state.allowNotificationsState.subState.message}/>
                ) : null}
            </Form>
        );
    }
}
