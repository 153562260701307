export class BryxColors {
    static readonly red = "#c61a1b";
    static readonly brandRed = "#8f0808";
    static readonly darkRed = "#ab1a1c";
    static readonly green = "#30ae33";

    static readonly darkGray = "#595453";
    static readonly gray = "#828282";
    static readonly lightGray = "#b0b0b0";
    static readonly extraLightGray = "#cacaca";
    static readonly offWhite = "#f1f1f1";

    static readonly emsBlue = "#1d2ec2";
    static readonly infoOrange = "#f5a623";
    static readonly policeBlue = "#001d5f";
    static readonly waterBlue = "#54bdec";
}
