import * as i18n from "i18next";
import * as React from 'react';
import Linkify from "react-linkify";
import {Header, Segment} from "semantic-ui-react";

interface JobAdvisoryViewProps {
    jobAdvisory: string;
    style?: any;
}

export class JobAdvisoryView extends React.Component<JobAdvisoryViewProps, any> {
    render() {
        return (
            <div className="job-advisory-view" style={this.props.style}>
                <Header
                    className="job-advisory-header"
                    as="h3"
                    attached="top"
                >
                    {i18n.t("jobs.jobInfo.advisory")}
                </Header>
                <Segment className="job-advisory-container" attached>
                    <Linkify properties={{target: "_blank"}}>
                        {this.props.jobAdvisory}
                    </Linkify>
                </Segment>
            </div>
        );
    }
}
