import { JobType } from "../models/jobTypeInformation";
import { BryxWebSocket, BryxWebSocketMessage } from "./bryxWebSocket";
import { Geometry } from "geojson";
import { useEffect, useId, useState } from "react";

export type LayerType =
    | "hydrants"
    | "users"
    | "apparatus"
    | "self"
    | "jobs"
    | "stations"
    | "agencies"
    | `agency:${string}`;

interface LayerItem {
    id: string;
    location: Geometry;
}

export interface HydrantLayerItem extends LayerItem {
    mainSize?: number;
    color: string;
}

export interface ClientLayerItem extends LayerItem {
    initials: string;
    name: string;
    ts: number;
    type: "users" | "apparatus";
}

export interface JobLayerItem extends LayerItem {
    synopsis: string;
    ts: number;
    type: JobType;
    unitShortNames: string[];
    locationOfIncident: string[];
}

export interface StationLayerItem extends LayerItem {
    name: string;
}

export interface AgencyLayerItem extends LayerItem {
    name: string;
}

export type LayerItemAny =
    | HydrantLayerItem
    | ClientLayerItem
    | JobLayerItem
    | StationLayerItem
    | AgencyLayerItem;
export type SubscriptionLayers = Partial<{
    hydrants: HydrantLayerItem[];
    users: ClientLayerItem[];
    apparatus: ClientLayerItem[];
    self: ClientLayerItem[];
    jobs: JobLayerItem[];
    stations: StationLayerItem[];
    agencies: AgencyLayerItem[];
}>;

export type MapSubscription = {
    layers: LayerType[];
    corners: {
        ne: {
            latitude: number;
            longitude: number;
        };
        sw: {
            latitude: number;
            longitude: number;
        };
    };
    currentData: SubscriptionLayers;
    callback: (layers: SubscriptionLayers) => void;
};

type MapUpdate =
    | {
          type: "update";
          layer: LayerType;
          item: LayerItemAny;
      }
    | {
          type: "remove";
          layer: LayerType;
          remove: string;
      };

type MapWSMessage =
    | {
          key: "subscribeResponse";
          successful: boolean;
          topic: string;
          errorReason: null | string;
          initialData: {
              type: LayerType;
              success: boolean;
              items: LayerItemAny[];
          }[];
      }
    | {
          key: "serverUpdate";
          topic: string;
          data: MapUpdate;
      };

export class MapManager {
    public static shared: MapManager = new MapManager();
    private subscriptions: { [key: string]: MapSubscription };

    private constructor() {
        this.subscriptions = {};
    }

    public subscribe(
        id: string,
        layers: LayerType[],
        ne: {
            latitude: number;
            longitude: number;
        },
        sw: {
            latitude: number;
            longitude: number;
        },
        callback: (layers: SubscriptionLayers) => void
    ): void {
        if (this.subscriptions[id]) {
            return;
        }

        BryxWebSocket.shared.addSubscriber(
            "map-" + id,
            "maps",
            this.handleGenerator(id),
            undefined,
            {
                ne: [ne.longitude, ne.latitude],
                sw: [sw.longitude, sw.latitude],
                layers,
            }
        );
        this.subscriptions[id] = {
            layers,
            corners: {
                ne,
                sw,
            },
            currentData: {},
            callback,
        };
    }

    public updateSubscription(
        id: string,
        ne: {
            latitude: number;
            longitude: number;
        },
        sw: {
            latitude: number;
            longitude: number;
        }
    ): void {
        if (!this.subscriptions[id]) {
            return;
        }
        this.subscriptions[id].corners = { ne, sw };
        BryxWebSocket.shared.changeSubscription("map-" + id, "maps", {
            ne: [ne.longitude, ne.latitude],
            sw: [sw.longitude, sw.latitude],
            layers: this.subscriptions[id].layers,
        });
    }

    public removeSubscription(id: string): void {
        if (!this.subscriptions[id]) {
            return;
        }
        delete this.subscriptions[id];
        BryxWebSocket.shared.removeSubscriber("map-" + id);
    }

    private dedupeLayers(id: string): void {
        const newLayers: SubscriptionLayers = {};
        for (const layer of Object.keys(this.subscriptions[id].currentData)) {
            const processed: string[] = [];
            newLayers[layer] = [];
            for (const item of this.subscriptions[id].currentData[layer]) {
                if (!processed.includes(item.id)) {
                    processed.push(item.id);
                    newLayers[layer].push(item);
                }
            }
        }
        this.subscriptions[id].currentData = newLayers;
    }

    private handleGenerator(
        id: string
    ): (message: BryxWebSocketMessage) => void {
        return ((message: MapWSMessage) => {
            if (!this.subscriptions[id]) {
                return;
            }
            switch (message.key) {
                case "subscribeResponse":
                    if (!message.successful) {
                        break;
                    }
                    for (const l of message.initialData) {
                        if (l.success) {
                            this.subscriptions[id].currentData[l.type] =
                                l.items;
                        } else {
                            this.subscriptions[id].currentData[l.type] = [];
                        }
                    }
                    break;
                case "serverUpdate":
                    switch (message.data.type) {
                        case "remove":
                            const toRemove = message.data.remove;
                            this.subscriptions[id].currentData[
                                message.data.layer
                            ] = (this.subscriptions[id].currentData[
                                message.data.layer
                            ] ?? []).filter((v) => v.id !== toRemove);
                            break;
                        case "update":
                            const updateData = message.data;
                            this.subscriptions[id].currentData[
                                message.data.layer
                            ] = (this.subscriptions[id].currentData[
                                message.data.layer
                            ] ?? []).map((v) =>
                                v.id === updateData.item.id
                                    ? updateData.item
                                    : v
                            );
                            break;
                    }
                    break;
                default:
                    break;
            }
            this.dedupeLayers(id);
            this.subscriptions[id].callback(this.subscriptions[id].currentData);
        }).bind(this);
    }
}

const subscribedLayers: LayerType[] = [
    "users",
    "apparatus",
    "jobs",
    "hydrants",
    "stations",
    "agencies",
];

export function useLayers(
    ne: {
        latitude: number;
        longitude: number;
    },
    sw: {
        latitude: number;
        longitude: number;
    }
): SubscriptionLayers {
    const id = useId();
    const [layers, setLayers] = useState<SubscriptionLayers>({});

    useEffect(() => {
        MapManager.shared.subscribe(id, subscribedLayers, ne, sw, setLayers);
        return () => MapManager.shared.removeSubscription(id);
    }, []);

    useEffect(() => {
        MapManager.shared.updateSubscription(id, ne, sw);
    }, [ne.latitude, ne.longitude, sw.latitude, sw.longitude]);
    return layers;
}
