import { Dangerous, Password, VisibilityOff, Visibility } from "@mui/icons-material";
import i18n from "i18next";
import { useState } from "react";
import {
    Tab,
    Segment,
    Button,
    Modal,
    Input,
} from "semantic-ui-react";
import { BryxApi } from "../../../utils/bryxApi";
import { BryxLocal } from "../../../utils/bryxLocal";
import { ModalPaneProps, SettingsSection } from "./util";
import React from "react";

export function AccountPane({ section, onChangeSection }: ModalPaneProps) {
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [passwordVisibility, setPasswordVisibility] = useState<{
        current: boolean;
        new: boolean;
        confirm: boolean;
    }>({ current: false, new: false, confirm: false });
    const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
    const [disableValue, setDisableValue] = useState<string>("");
    return (
        <Tab.Pane className="settings-tab account" attached={false}>
            <Segment.Group>
                <SettingsSection
                    section="disable"
                    icon={<Dangerous />}
                    open={section == "account.disable"}
                    onToggle={(open) =>
                        onChangeSection(open ? "account.disable" : null)
                    }
                >
                    <Button
                        fluid
                        className="disable-button"
                        onClick={() => {
                            setDisableValue("");
                            setDisableConfirm(true);
                        }}
                    >
                        {i18n.t("account.sections.disable.button")}
                    </Button>
                    <div className="disable-alert">
                        {i18n.t("account.sections.disable.warning")}
                    </div>
                    <Modal
                        dimmer={"blurring"}
                        size="small"
                        open={disableConfirm}
                        onClose={() => setDisableConfirm(false)}
                        className="confirm-disable-dialog"
                    >
                        <Modal.Header>
                            {i18n.t("account.sections.disable.dialogTitle")}
                        </Modal.Header>
                        <Modal.Content>
                            <div className="message">
                                {i18n.t(
                                    "account.sections.disable.dialogMessage"
                                )}
                            </div>
                            <Input
                                className="confirm-disable-input"
                                value={disableValue}
                                onChange={(event) =>
                                    setDisableValue(event.target.value)
                                }
                                fluid
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                className="disable-cancel"
                                onClick={() => setDisableConfirm(false)}
                            >
                                {i18n.t(
                                    "account.sections.disable.dialogCancel"
                                )}
                            </Button>
                            <Button
                                className="disable-confirm"
                                disabled={disableValue != BryxLocal.getEmail()}
                                onClick={() => {
                                    BryxApi.signOut((result) => {
                                        if (result.success) {
                                            window.location.pathname = "";
                                        }
                                    });
                                }}
                            >
                                {i18n.t(
                                    "account.sections.disable.dialogConfirm"
                                )}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </SettingsSection>
                <SettingsSection
                    section="password"
                    icon={<Password />}
                    open={section == "account.password"}
                    onToggle={(open) =>
                        onChangeSection(open ? "account.password" : null)
                    }
                >
                    <span className="password-label">
                        {i18n.t("account.sections.password.current")}
                    </span>
                    <Input
                        placeholder={i18n.t(
                            "account.sections.password.current"
                        )}
                        fluid
                        type={passwordVisibility.current ? "text" : "password"}
                        icon={
                            <Button
                                icon
                                onClick={() =>
                                    setPasswordVisibility({
                                        ...passwordVisibility,
                                        current: !passwordVisibility.current,
                                    })
                                }
                            >
                                {passwordVisibility.current ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </Button>
                        }
                        value={currentPassword}
                        onChange={(event, data) =>
                            setCurrentPassword(event.target.value)
                        }
                    />
                    <span className="password-label">
                        {i18n.t("account.sections.password.new")}
                    </span>
                    <Input
                        placeholder={i18n.t("account.sections.password.new")}
                        fluid
                        type={passwordVisibility.new ? "text" : "password"}
                        icon={
                            <Button
                                icon
                                onClick={() =>
                                    setPasswordVisibility({
                                        ...passwordVisibility,
                                        new: !passwordVisibility.new,
                                    })
                                }
                            >
                                {passwordVisibility.new ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </Button>
                        }
                        value={newPassword}
                        onChange={(event, data) =>
                            setNewPassword(event.target.value)
                        }
                    />
                    <span className="password-label">
                        {i18n.t("account.sections.password.confirm")}
                    </span>
                    <Input
                        placeholder={i18n.t(
                            "account.sections.password.confirm"
                        )}
                        fluid
                        type={passwordVisibility.confirm ? "text" : "password"}
                        icon={
                            <Button
                                icon
                                onClick={() =>
                                    setPasswordVisibility({
                                        ...passwordVisibility,
                                        confirm: !passwordVisibility.confirm,
                                    })
                                }
                            >
                                {passwordVisibility.confirm ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </Button>
                        }
                        value={confirmPassword}
                        onChange={(event, data) =>
                            setConfirmPassword(event.target.value)
                        }
                    />
                    <Button className="password-change" color="green">
                        {i18n.t("account.sections.password.button")}
                    </Button>
                </SettingsSection>
            </Segment.Group>
        </Tab.Pane>
    );
}
