import * as i18n from "i18next";
import * as React from 'react';
import {Header, Segment} from "semantic-ui-react";
import {Complainant} from "../../models/complainant";
import {KeyValueView} from "./keyValueView";

interface JobComplainantViewProps {
    jobComplainant: Complainant | null;
    style?: any;
}

export class JobComplainantView extends React.Component<JobComplainantViewProps, any> {
    render() {
        const complainant = this.props.jobComplainant;
        return (
            <div className="job-complainant-view" style={this.props.style}>
                <Header
                    className="job-complainant-header"
                    as="h3"
                    attached="top"
                >
                    {i18n.t("jobs.jobInfo.complainant.title")}
                </Header>
                {
                    complainant == null ? (
                        <Segment className="job-complainant-container" attached>
                            <Header className="job-complainant-header-none" as="h4" color="grey">
                                {i18n.t("jobs.jobInfo.complainant.noComplainant")}
                            </Header>
                        </Segment>
                    ) : (
                        <Segment className="job-complainant-container" attached>
                            {
                                complainant.name != null ? (
                                    <KeyValueView
                                        keyNode={i18n.t("jobs.jobInfo.complainant.name")}
                                        valueNode={complainant.name}
                                    />
                                ) : null
                            }
                            {
                                complainant.phoneNumber != null ? (
                                    <KeyValueView
                                        keyNode={i18n.t("jobs.jobInfo.complainant.phoneNumber")}
                                        valueNode={complainant.phoneNumber}
                                    />
                                ) : null
                            }
                            {
                                complainant.address != null ? (
                                    <KeyValueView
                                        keyNode={i18n.t("jobs.jobInfo.complainant.address")}
                                        valueNode={complainant.address}
                                    />
                                ) : null
                            }
                        </Segment>
                    )
                }
            </div>
        );
    }
}
