import {Feature, FeatureCollection, GeoJsonObject, GeometryCollection, GeometryObject, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon} from "geojson";
import {GeoJSON, Icon} from "leaflet";
import * as React from 'react';
import {LayerGroup, Marker, Polygon as LeafletPolygon, Polyline} from "react-leaflet";
import {BryxMap, BryxMapUtil} from "./bryxMap";

interface BryxGeoJSONLayerProps {
    geojson: GeoJsonObject;
    icon?: Icon;
}

export class BryxGeoJSONLayer extends React.Component<BryxGeoJSONLayerProps, any> {
    render() {
        function geoJSONToLayer(geojson: GeoJsonObject, icon?: Icon | null): JSX.Element | null {
            switch (geojson.type) {
                case 'Point':
                    const point = geojson as Point;
                    return (
                        <Marker key={`Point+${point.coordinates}`}
                                position={GeoJSON.coordsToLatLng(point.coordinates as [number, number] | [number, number, number])}
                                icon={icon || BryxMapUtil.genericIcon}/>
                    );

                case 'MultiPoint':
                    const multipoint = geojson as MultiPoint;
                    return (
                        <LayerGroup key={`MultiPoint+${multipoint.coordinates}`}>
                            {multipoint.coordinates.map((c: number[]) => {
                                return (
                                    <Marker key={c.toString()}
                                            position={GeoJSON.coordsToLatLng(c as [number, number] | [number, number, number])}
                                            icon={icon || BryxMapUtil.genericIcon}/>
                                );
                            })}
                        </LayerGroup>
                    );

                case 'LineString':
                    const linestring = geojson as LineString;
                    return (
                        <Polyline key={`LineString+${linestring.coordinates}`}
                                  positions={GeoJSON.coordsToLatLngs(linestring.coordinates, 0, GeoJSON.coordsToLatLng)}/>
                    );

                case 'MultiLineString':
                    const multilinestring = geojson as MultiLineString;
                    return (
                        <Polyline key={`MultiLineString+${multilinestring.coordinates.toString()}`}
                            positions={GeoJSON.coordsToLatLngs(multilinestring.coordinates, 1, GeoJSON.coordsToLatLng)}/>
                    );

                case 'Polygon':
                    const polygon = geojson as Polygon;
                    return (
                        <LeafletPolygon key={`Polygon+${polygon.coordinates}`}
                            positions={GeoJSON.coordsToLatLngs(polygon.coordinates, 1, GeoJSON.coordsToLatLng)}/>
                    );

                case 'MultiPolygon':
                    const multipolygon = geojson as MultiPolygon;
                    return (
                        <LeafletPolygon key={`MultiPolygon+${multipolygon.coordinates.toString()}`}
                            positions={GeoJSON.coordsToLatLngs(multipolygon.coordinates, 2, GeoJSON.coordsToLatLng)}/>
                    );

                case 'GeometryCollection':
                    const geometrycollection = geojson as GeometryCollection;
                    return (
                        <LayerGroup>
                            {geometrycollection.geometries.map((g: GeometryObject) => {
                                return geoJSONToLayer({
                                    geometry: g,
                                    type: 'Feature',
                                    properties: null,
                                } as Feature<GeometryObject>, icon);
                            }).filter(Boolean) as JSX.Element[]}
                        </LayerGroup>
                    );

                case 'Feature':
                    const feature = geojson as Feature<GeometryObject>;
                    return geoJSONToLayer(feature.geometry, icon);

                case 'FeatureCollection':
                    const featurecollection = geojson as FeatureCollection<GeometryObject>;
                    return (
                        <LayerGroup>
                            {featurecollection.features.map((f: Feature<GeometryObject>) => {
                                return geoJSONToLayer(f, icon);
                            }).filter(Boolean) as JSX.Element[]}
                        </LayerGroup>
                    );

                default:
                    return null;
            }
        }

        return (
            <LayerGroup>
                {geoJSONToLayer(this.props.geojson, this.props.icon || null)}
            </LayerGroup>
        );
    }
}
