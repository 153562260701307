import * as i18n from "i18next";
import * as React from 'react';
import {Icon, SemanticICONS} from "semantic-ui-react";
import {BryxNotification, BryxNotificationType} from "../models/bryxNotification";
import {DateUtils} from "../utils/dateUtils";

interface NotificationItemProps {
    notification: BryxNotification;
    currentTime: Date;
    onClear: () => void;
    onClick: () => void;
}

export class NotificationItem extends React.Component<NotificationItemProps, {}> {
    render() {
        const notification = this.props.notification;
        const timeDuration = DateUtils.duration(notification.date, this.props.currentTime).humanize(true);
        let iconName: SemanticICONS;
        let typeString;
        let iconMargin;
        let iconSize: "big" | "large" = "big";

        switch (notification.content.type) {
            case BryxNotificationType.job:
                typeString = i18n.t("notifications.type.job");
                iconName = "marker";
                iconMargin = "0 12px 0 0";
                iconSize = "big";
                break;
            case BryxNotificationType.supplemental:
                typeString = i18n.t("notifications.type.supplemental");
                iconName = "info circle";
                iconMargin = "0 12px 0 0";
                iconSize = "big";
                break;
            case BryxNotificationType.message:
                typeString = i18n.t("notifications.type.message");
                iconName = "mail";
                iconMargin = "0 16px 0 4px";
                iconSize = "large";
                break;
            default:
                // Impossible case
                typeString = i18n.t("notifications.type.message");
                iconName = "mail";
                iconMargin = "0 16px 0 4px";
                iconSize = "large";
                break;
        }
        return (
            <div className="notificationItem" onMouseDown={this.props.onClick}>
                <div className="notification-item-container">
                    <div className="icon-container">
                        <Icon name={iconName}
                              size={iconSize}
                              color="grey"
                              style={{margin: iconMargin}}/>
                    </div>
                    <div className="notification-content">
                        <span className="notification-title">
                            {notification.title}
                        </span>
                        <span className="notification-message">
                            {notification.message}
                        </span>
                        <span className="notification-time">
                            {typeString} &bull; {timeDuration}
                        </span>
                    </div>
                    <div className="notification-remove-icon-container">
                        <Icon className="notificationItemX"
                              name="x"
                              link
                              color="grey"
                              onMouseDown={(e: Event) => {
                                  e.stopPropagation();
                                  this.props.onClear();
                              }}/>
                    </div>
                </div>
            </div>
        );
    }
}
