import * as i18n from "i18next";
import * as React from "react";
import {Button, Checkbox, Divider, Form, Header, Icon, Message, Progress, Segment} from "semantic-ui-react";
import {config} from "../../config";
import {LocationPrivacy} from "../../models/auth";
import {LocationDevice} from "../../models/locationDevice";
import {BryxApi} from "../../utils/bryxApi";
import {BryxLocal} from "../../utils/bryxLocal";
import {GeoUtils} from "../../utils/geoUtils";
import {LocationManager, LocationState} from "../../utils/locationManager";
import {PreferenceManager} from "../../utils/preferenceManager";

interface LocationTabState {
    devices: LocationDevice[];
    deviceForLocation: LocationDevice | null;
    privacy: LocationPrivacy;
    locationStatus: { key: "ready" } | { key: "success", position: Position } | { key: "error", message: string };
    locationLoadingStatus: { key: "none" } | { key: "loading", start: Date };
    currentTime: Date;
    locationState: LocationState;
    status: { key: "ready" } | { key: "loading" } | { key: "error", message: string };
    usingAvl: boolean;
}

export class LocationTab extends React.Component<{}, LocationTabState> {
    private timerId: NodeJS.Timer | null = null;

    constructor(props: {}, context: any) {
        super(props, context);

        const devices = BryxLocal.getLocationDevices();
        const validDevices = devices.filter(d => d.useForLocation == true);

        this.state = {
            devices: devices,
            deviceForLocation: validDevices.length > 0 ? validDevices[0] : null,
            privacy: BryxLocal.getLocationPrivacy(),
            locationStatus: {key: "ready"},
            locationLoadingStatus: {key: "none"},
            currentTime: new Date(),
            locationState: LocationManager.shared.state,
            status: {key: "ready"},
            usingAvl: false,
        };
    }

    componentDidMount() {
        this.timerId = setInterval(
            () => this.setState({currentTime: new Date()}),
            1000,
        );
        this.setState({usingAvl: localStorage.getItem("global.bryx.usingAvl") === 'true'});
    }

    componentWillUnmount() {
        if (this.timerId != null) {
            clearInterval(this.timerId);
        }
    }

    private getLocation() {
        this.setState({
            locationLoadingStatus: {key: "loading", start: new Date()},
        });

        LocationManager.shared.requestUpdate(result => {
            if (result.key != "success") {
                config.warn(`Failed to test location: ${result.message}`);
            }

            this.setState({
                locationStatus: result,
                locationLoadingStatus: {key: "none"},
            });
        });
    }

    render() {
        const {
            devices,
            deviceForLocation,
            locationLoadingStatus,
            locationStatus,
            locationState,
            privacy,
            status,
            currentTime,
        } = this.state;

        let testLocationHeader;
        if (locationStatus.key == "success") {
            testLocationHeader = (
                <Header as="h3" style={{margin: "0 30px 0 0", flex: 1}}>
                    <Icon name="check circle" color="green"/>
                    <Header.Content>
                        {i18n.t("settings.location.testSuccessful")}
                        <Header.Subheader>
                            <a
                                target="_blank"
                                style={{color: "inherit"}}
                                href={GeoUtils.directionsUrl(GeoUtils.positionToPoint(locationStatus.position), PreferenceManager.shared.preferences).url}>
                                {`${locationStatus.position.coords.latitude.toFixed(8)}, ${locationStatus.position.coords.longitude.toFixed(8)}`}
                            </a> {i18n.t("settings.location.accuracy", {replace: {accuracy: locationStatus.position.coords.accuracy}})}
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            );
        } else if (locationStatus.key == "error") {
            testLocationHeader = (
                <Header as="h3" style={{margin: "0 30px 0 0", flex: 1}}>
                    <Icon name="attention" color="red"/>
                    <Header.Content>
                        {i18n.t("settings.location.testFailed")}
                        <Header.Subheader>{locationStatus.message}</Header.Subheader>
                    </Header.Content>
                </Header>
            );
        } else {
            testLocationHeader = (
                <Header as="h3" style={{margin: "0 30px 0 0", flex: 1}}>
                    <Icon name="location arrow"/>
                    <Header.Content>
                        {i18n.t("settings.location.testReady")}
                        <Header.Subheader>{i18n.t("settings.location.testReadySub")}</Header.Subheader>
                    </Header.Content>
                </Header>
            );
        }

        const locationProgress = locationLoadingStatus.key == "loading" ? (
            (currentTime.getTime() - locationLoadingStatus.start.getTime()) / LocationManager.onDemandLocationTimeout * 100
        ) : null;

        return (
            <div className="location-tab bryx-text">
                {this.state.usingAvl ? (
                    <div className="avl-text-container">
                        <h3>
                            {i18n.t(`settings.location.avlInformation`)} <a
                            href={"mailto:support@bryx.com?subject=Disable%20AVL%20for%20Account"}>support@bryx.com</a>.
                        </h3>
                    </div>
                ) : (
                    <div>
                        <Form className="location-service-form">
                            <Form.Field>
                                <Checkbox
                                    label={i18n.t("settings.location.useLocationServices")}
                                    checked={locationState == "granted"}
                                    disabled={status.key == "loading"}
                                    onChange={(e, d) => {
                                        const enabled = d.checked || false;
                                        if (enabled) {
                                            this.setState({
                                                status: {key: "loading"},
                                            });
                                            LocationManager.shared.requestAccess(state => {
                                                this.setState({
                                                    locationState: state,
                                                    status: state == "denied" ? {
                                                        key: "error",
                                                        message: i18n.t("settings.location.locationDenied"),
                                                    } : {key: "ready"},
                                                });
                                            });
                                        } else {
                                            LocationManager.shared.disableAccess();
                                            this.setState({locationState: "disabled"});
                                        }
                                    }}
                                />
                            </Form.Field>
                            <Form.Select
                                label={i18n.t("settings.location.deviceForLocation")}
                                value={deviceForLocation != null ? deviceForLocation.id : undefined}
                                disabled={status.key == "loading"}
                                options={devices.map(d => ({
                                    key: d.id,
                                    text: d.name,
                                    value: d.id,
                                }))}
                                onChange={(e, d) => {
                                    if (d.value == null || (deviceForLocation != null && d.value == deviceForLocation.id)) {
                                        return;
                                    }

                                    this.setState({
                                        status: {key: "loading"},
                                    });

                                    BryxApi.setDeviceForLocation(d.value.toString(), result => {
                                        if (result.success == true) {
                                            config.info(`Changed device for location to: ${d.value}`);
                                            this.setState(prevState => {
                                                const newState = {...prevState};
                                                newState.devices.forEach(device => device.useForLocation = device.id == d.value);
                                                newState.deviceForLocation = prevState.devices.filter(device => device.id == d.value)[0];
                                                newState.status = {key: "ready"};

                                                return newState;
                                            }, () => BryxLocal.setLocationDevices(devices));
                                        } else {
                                            config.warn(`Could not set device for location. Error from server: ${result.debugMessage}`);
                                            this.setState({
                                                status: {key: "error", message: result.message},
                                            });
                                        }
                                    });
                                }}
                            />
                            <Form.Select
                                label={i18n.t("settings.location.privacy.label")}
                                value={LocationPrivacy[privacy]}
                                disabled={status.key == "loading"}
                                options={[LocationPrivacy.always, LocationPrivacy.responding, LocationPrivacy.openCall, LocationPrivacy.never].map(o => ({
                                    key: o,
                                    text: i18n.t(`settings.location.privacy.${LocationPrivacy[o]}`),
                                    value: LocationPrivacy[o],
                                }))}
                                onChange={(e, d) => {
                                    if (d.value == null || d.value == LocationPrivacy[privacy]) {
                                        return;
                                    }

                                    this.setState({
                                        status: {key: "loading"},
                                    });

                                    const newPrivacy = LocationPrivacy[d.value.toString() as keyof typeof LocationPrivacy];

                                    BryxApi.setLocationPrivacy(newPrivacy, result => {
                                        if (result.success == true) {
                                            config.info(`Changed location privacy to: ${d.value}`);
                                            this.setState(prevState => {
                                                const newState = {...prevState};
                                                newState.privacy = newPrivacy;
                                                newState.status = {key: "ready"};

                                                return newState;
                                            }, () => BryxLocal.setLocationPrivacy(newPrivacy));
                                        } else {
                                            config.warn(`Could not set location privacy. Error from server: ${result.debugMessage}`);
                                            this.setState({
                                                status: {key: "error", message: result.message},
                                            });
                                        }
                                    });
                                }}
                            />
                        </Form>
                        <Divider className="location-divider"/>
                        <div className="location-test">
                            <Segment>
                                <div className="test-location-container">
                                    {testLocationHeader}
                                    <Button
                                        content={i18n.t(locationStatus.key != "ready" ? "settings.location.rerun" : "settings.location.runTest")}
                                        loading={locationLoadingStatus.key == "loading"}
                                        onClick={() => this.getLocation()}
                                    />
                                </div>
                                <Progress
                                    attached="bottom"
                                    success={locationProgress != null || locationStatus.key == "success"}
                                    error={locationProgress == null && locationStatus.key == "error"}
                                    active={locationProgress != null}
                                    percent={locationProgress != null ? locationProgress : 100}
                                    style={{margin: 0}}
                                />
                            </Segment>
                        </div>
                        {status.key == "error" ? <Message negative content={status.message}/> : undefined}
                    </div>
                )}
            </div>
        );
    }
}
