export enum ResponseStatus {
    Success,
    Unauthorized,
    ClientFailure,
    ServerFailure,
    ConnectionFailure,
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export class HttpResponse {
    constructor(
        public status: ResponseStatus,
        public statusCode: number,
        public responseJson: {} | null,
        public responseText: string | null,
        public response: any,
    ) { }

    static responseFromRequest(request: XMLHttpRequest): HttpResponse {
        let responseText = null;
        let responseJson = null;

        if (request.responseType == '' || request.responseType == 'text') {
            responseText = request.responseText;
            try {
                responseJson = JSON.parse(request.responseText);
            } catch (e) {
                if (e instanceof TypeError) {
                    // Error in parsing the response
                    responseJson = null;
                }
            }
        }

        const status = (() => {
            if (request.status == 0) {
                // Internet or URL failure
                return ResponseStatus.ConnectionFailure;
            } if (request.status >= 200 && request.status < 300) {
                // Success!
                return ResponseStatus.Success;
            } if (request.status >= 400 && request.status < 500) {
                // Client Failure!!!!!
                if (request.status == 401) {
                    return ResponseStatus.Unauthorized;
                }
                return ResponseStatus.ClientFailure;
            } if (request.status >= 500 && request.status < 600) {
                // Server Failure!!!!!
                return ResponseStatus.ServerFailure;
            }
            console.error('¯\_(ツ)_/¯');
            return ResponseStatus.ServerFailure;
        })();

        return new HttpResponse(status, request.status, responseJson, responseText, request.response);
    }
}

export interface UrlParams {
    [index: string]: string | number;
}
export interface Headers {
    [index: string]: string;
}

export class HttpRequest {
    // Remains a class to allow for in-place changing
    constructor(
        public method: HttpMethod,
        public url: string,
        public urlParams: UrlParams | null,
        public body: any,
        public headers: Headers | null,
        public responseType: XMLHttpRequestResponseType | null = null,
    ) { }
}

export interface ClientConfig {
    baseUrl?: string;
    transformRequest?: (request: HttpRequest) => HttpRequest;
}

export class HttpClient {
    constructor(private config: ClientConfig) { }

    private static objectsToParams(data: UrlParams): string {
        return Object.keys(data).map((key: string) => `${key}=${encodeURIComponent(String(data[key]))}`).join('&');
    }

    private buildUrl(httpRequest: HttpRequest): string {
        let requestUrl = (() => {
            if (this.config.baseUrl == null) {
                return httpRequest.url;
            }
            const urlBase = this.config.baseUrl.replace(/\/($)/g, '$1');
            const providedUrl = httpRequest.url.replace(/(^)\//g, '$1');

            return `${urlBase}/${providedUrl}`;
        })();

        if (httpRequest.urlParams != null && Object.keys(httpRequest.urlParams).length > 0) {
            requestUrl += `?${HttpClient.objectsToParams(httpRequest.urlParams)}`;
        }

        return requestUrl;
    }

    private sendRequest(httpRequest: HttpRequest, callback: (request: HttpRequest, response: HttpResponse) => void): void {
        const request = new XMLHttpRequest();

        httpRequest = this.config.transformRequest ? this.config.transformRequest(httpRequest) : httpRequest;

        request.onreadystatechange = () => {
            if (request.readyState == request.DONE) {
                callback(httpRequest, HttpResponse.responseFromRequest(request));
            }
        };

        if (httpRequest.responseType != null) {
            request.responseType = httpRequest.responseType;
        }

        request.open(httpRequest.method, this.buildUrl(httpRequest), true);

        const headers = httpRequest.headers || {};
        Object.keys(headers).forEach((key) => {
            request.setRequestHeader(key, headers[key]);
        });

        function replacer(key: any, value: any): any {
            // https://stackoverflow.com/questions/29085197/how-do-you-json-stringify-an-es6-map
            if (value instanceof Map) {
                return Object.fromEntries(value);
            }
            return value;
        }

        if (httpRequest.body != null) {
            try {
                request.send(JSON.stringify(httpRequest.body, replacer));
            } catch (e) {
                if (e instanceof TypeError) {
                    callback(httpRequest, new HttpResponse(ResponseStatus.ClientFailure, -1, null, null, null));
                }
            }
        } else {
            request.send(null);
        }
    }

    public get(url: string, urlParams: UrlParams | null, callback: (request: HttpRequest, response: HttpResponse) => void, headers?: Headers, responseType?: XMLHttpRequestResponseType): void {
        this.sendRequest(new HttpRequest('GET', url, urlParams, null, headers || null, responseType), callback);
    }

    public put(url: string, urlParams: UrlParams | null, body: any, callback: (request: HttpRequest, response: HttpResponse) => void, headers?: Headers, responseType?: XMLHttpRequestResponseType): void {
        this.sendRequest(new HttpRequest('PUT', url, urlParams, body, headers || null, responseType), callback);
    }

    public post(url: string, urlParams: UrlParams | null, body: any, callback: (request: HttpRequest, response: HttpResponse) => void, headers?: Headers, responseType?: XMLHttpRequestResponseType): void {
        this.sendRequest(new HttpRequest(
            'POST',
            url,
            urlParams,
            body,
            headers || null,
            responseType,
        ), callback);
    }

    public del(url: string, urlParams: UrlParams | null, callback: (request: HttpRequest, response: HttpResponse) => void, headers?: Headers, responseType?: XMLHttpRequestResponseType): void {
        this.sendRequest(new HttpRequest('DELETE', url, urlParams, null, headers || null, responseType), callback);
    }
}
