import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {ParseUtils} from "../utils/cerealParse";
import {Group} from "./group";
import {Message} from "./message";

// GroupListUpdate

export type GroupListUpdateReplace = {
    key: "replace",
    groups: Group[],
};
export type GroupListUpdateAddedGroup = {
    key: "addedGroup",
    group: Group,
};
export type GroupListUpdateRemovedGroup = {
    key: "removedGroup",
    groupId: string,
};
export type GroupListUpdateMessage = {
    key: "message",
    groupId: string,
    message: Message,
};
export type GroupListUpdate = GroupListUpdateReplace | GroupListUpdateAddedGroup | GroupListUpdateRemovedGroup | GroupListUpdateMessage;

export function parseGroupListUpdate(message: BryxWebSocketMessage): ApiResult<GroupListUpdate | null> {
    try {
        switch (message.key) {
            case "serverUpdate":
                const type = ParseUtils.getString(message.data, "type");
                switch (type) {
                    case "addedGroup":
                        return apiSuccess({
                            key: "addedGroup",
                            group: ParseUtils.getSubobject(message.data, "group", Group.parse),
                        } as GroupListUpdateAddedGroup);
                    case "removedGroup":
                        return apiSuccess({
                            key: "removedGroup",
                            groupId: ParseUtils.getString(message.data, "groupId"),
                        } as GroupListUpdateRemovedGroup);
                    case "message":
                        return apiSuccess({
                            key: "message",
                            groupId: ParseUtils.getString(message.data, "groupId"),
                            message: ParseUtils.getSubobject(message.data, "message", Message.parse),
                        } as GroupListUpdateMessage);
                    default:
                        return apiFailure(null, `Invalid GroupListUpdate subscribe response type: ${type}`);
                }
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }
                return apiSuccess({
                    key: "replace",
                    groups: ParseUtils.getArrayOfSubobjects(message, "initialData", Group.parse, "warn"),
                } as GroupListUpdateReplace);
            default:
                return apiSuccess(null);
        }
    } catch (e) {
        return apiFailure(null, `Invalid GroupListUpdate Model: ${e.message}`);
    }
}
