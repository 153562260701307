import * as i18n from "i18next";
import * as React from "react";
import {Button, Header, Message, Modal} from "semantic-ui-react";
import {LocationManager} from "../../utils/locationManager";
import {NotificationManager} from "../../utils/notificationManager";
import {PreferenceManager} from "../../utils/preferenceManager";

interface PermissionsModalProps {
    open: boolean;
    onClose: () => void;
}

type PermissionStage = "notifications" | "location";

interface PermissionsModalState {
    activeStage: PermissionStage;
    readyState: {key: "ready"} | {key: "loading"} | {key: "error", message: string};
}

export class PermissionsModal extends React.Component<PermissionsModalProps, PermissionsModalState> {
    private static readonly stages: PermissionStage[] = ["notifications", "location"];

    constructor(props: PermissionsModalProps, context: any) {
        super(props, context);
        // Here's we use `useWebNotifications` directly from the PreferenceManager and there's no need to register for updates.
        // At this stage in the app, we are the only ones who can modify preferences.
        this.state = {
            activeStage: PermissionsModal.stages.filter(s => !PermissionsModal.stageIsComplete(s))[0],
            readyState: {key: "ready"},
        };
    }

    private static webNotificationsDefault(): boolean {
        return NotificationManager.shared.permission == "default" && PreferenceManager.shared.preferences.useWebNotifications;
    }

    private static locationDefault(): boolean {
        return LocationManager.shared.state == "default";
    }

    static anyIncompleteStages(): boolean {
        return PermissionsModal.stages.filter(s => !PermissionsModal.stageIsComplete(s)).length > 0;
    }

    private static stageIsComplete(stage: PermissionStage): boolean {
        switch (stage) {
            case "notifications":
                return !PermissionsModal.webNotificationsDefault();
            case "location":
                return !PermissionsModal.locationDefault();
        }
    }

    private static nextStage(stage: PermissionStage): PermissionStage | null {
        const stages = PermissionsModal.stages;
        return stages.slice(stages.indexOf(stage) + 1).filter(s => !PermissionsModal.stageIsComplete(s))[0];
    }

    private onNext() {
        switch (this.state.activeStage) {
            case "notifications":
                this.setState({readyState: {key: "loading"}});
                NotificationManager.shared.requestPermission(permission => {
                    if (permission == "denied") {
                        this.setState({readyState: {key: "error", message: i18n.t("permissions.notifications.denied")}});
                        return;
                    }
                    const nextStage = PermissionsModal.nextStage(this.state.activeStage);
                    if (nextStage == null) {
                        this.props.onClose();
                    } else {
                        this.setState(prevState => ({
                            readyState: {key: "ready"},
                            activeStage: nextStage,
                        }));
                    }
                });
                break;
            case "location":
                this.setState({readyState: {key: "loading"}});
                LocationManager.shared.requestAccess(state => {
                    if (state == "denied") {
                        this.setState({readyState: {key: "error", message: i18n.t("permissions.location.denied")}});
                        return;
                    }
                    const nextStage = PermissionsModal.nextStage(this.state.activeStage);
                    if (nextStage == null) {
                        this.props.onClose();
                    } else {
                        this.setState(prevState => ({
                            readyState: {key: "ready"},
                            activeStage: nextStage,
                        }));
                    }
                });
                break;
        }
    }

    private onSkip() {
        switch (this.state.activeStage) {
            case "notifications":
                PreferenceManager.shared.updatePreferences({useWebNotifications: false});
                break;
            case "location":
                LocationManager.shared.disableAccess();
                break;
        }
        const nextStage = PermissionsModal.nextStage(this.state.activeStage);
        if (nextStage == null) {
            this.props.onClose();
        } else {
            this.setState(prevState => ({
                readyState: {key: "ready"},
                activeStage: nextStage,
            }));
        }
    }

    render() {
        const {activeStage, readyState} = this.state;

        let iconName;
        let title;
        switch (activeStage) {
            case "notifications":
                iconName = "bell";
                title = i18n.t("permissions.notifications.stageTitle");
                break;
            case "location":
                iconName = "location arrow";
                title = i18n.t("permissions.location.stageTitle");
                break;
        }

        return (
            <Modal open={this.props.open}>
                <Header icon={iconName}
                        content={title}/>
                <Modal.Content>
                    <p>{i18n.t(`permissions.${activeStage}.body1`)}</p>
                    <p>{i18n.t(`permissions.${activeStage}.body2`)}</p>
                    {readyState.key == "error" ? <Message error content={readyState.message} /> : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button content={i18n.t("permissions.skip")}
                            disabled={readyState.key == "loading"}
                            onClick={this.onSkip.bind(this)}/>
                    <Button primary
                            loading={readyState.key == "loading"}
                            disabled={readyState.key == "loading"}
                            content={i18n.t("permissions.enable")}
                            onClick={this.onNext.bind(this)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}
