import {ParseResult, ParseUtils} from "../utils/cerealParse";

export enum SectionInputType {
    single, multi,
}

export interface InjuryValue {
    title: string;
    checked: boolean;
}

export class InjurySection {
    constructor(
        public header: string,
        public inputType: SectionInputType,
        public values: InjuryValue[],
    ) {}

    get isComplete(): boolean {
        switch (this.inputType) {
            case SectionInputType.single:
                return this.values.filter(v => v.checked).length == 1;
            case SectionInputType.multi:
                return true;
        }
    }

    static parse(o: any): ParseResult<InjurySection> {
        try {
            return ParseUtils.parseSuccess(new InjurySection(
                ParseUtils.getString(o, "header"),
                ParseUtils.getEnum(o, "inputType", SectionInputType),
                (o.selected as string[]).map(item => ({title: item, checked: true})),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<InjurySection>(`Invalid InjurySection Model: ${e.message}`);
        }
    }

    toObject(): {[index: string]: any} {
        return {
            inputType: SectionInputType[this.inputType],
            header: this.header,
            selected: this.values.filter(v => v.checked).map(v => v.title),
        };
    }
}

export class Injury {
    constructor(
        public type: string,
        public sections: InjurySection[],
        public prompts: string | null,
    ) {}

    static parse(o: any): ParseResult<Injury> {
        try {
            return ParseUtils.parseSuccess(new Injury(
                ParseUtils.getString(o, "type"),
                ParseUtils.getArrayOfSubobjects(o, "sections", InjurySection.parse, "warn"),
                null,
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Injury>(`Invalid Injury Model: ${e.message}`);
        }
    }

    toObject(): {[index: string]: any} {
        return {
            type: this.type,
            sections: this.sections.map(s => s.toObject()),
        };
    }
}
