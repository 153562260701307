import * as i18n from "i18next";
import * as React from "react";
import Linkify from "react-linkify";
import {Dimmer, Icon, Image, Loader} from "semantic-ui-react";
import {Group} from "../../models/group";
import {Message, MessageContentType, MessageStatusType} from "../../models/message";
import {BryxApi} from "../../utils/bryxApi";
import {DateUtils} from "../../utils/dateUtils";
import {BryxPreferences} from "../../utils/preferenceManager";

export interface MessageViewProps {
    group: Group;
    message: Message;
    preferences: BryxPreferences;
    isFirstInCluster: boolean;
    onClickResend: () => void;
    onClickShowSeenList: () => void;
    // Only apply to image messages
    onClickImage: () => void;
    isLoadingFullImage: boolean;
}

export class MessageView extends React.Component<MessageViewProps, {}> {
    render() {
        const {
            group,
            message,
            preferences,
            isFirstInCluster,
            onClickShowSeenList,
            onClickImage,
            isLoadingFullImage,
            onClickResend,
        } = this.props;
        const senderLine = isFirstInCluster ? (
            <div className="message-sender">{message.sender.name}</div>
        ) : null;

        const containerClassNames = ["message-container"];
        const bodyClassNames = ["message-body"];
        if (message.sender.isMe) {
            bodyClassNames.push("message-me");
            containerClassNames.push("message-wrapper-right");
        } else if (message.content.type == MessageContentType.bryx) {
            bodyClassNames.push("message-bryx");
            containerClassNames.push("message-wrapper-center");
        }

        if (message.content.type == MessageContentType.image) {
            bodyClassNames.push("message-img");
        }

        const imageStyle = {
            width: message.content.type == MessageContentType.image ? message.content.width : undefined,
            height: message.content.type == MessageContentType.image ? message.content.height : undefined,
            borderRadius: "8px",
        };

        let readIndicator = null;
        if (message.sender.isMe) {
            if (message.status.key == MessageStatusType.sending) {
                readIndicator = (
                    <div className="message-loader-container">
                        <Loader active inline inverted size="mini"/>
                    </div>
                );
            } else if (message.status.key == MessageStatusType.failed) {
                containerClassNames.push("message-wrapper-failed");
                readIndicator = (
                    <Image
                        height="20px"
                        src="/public/assets/error_x.svg"
                        style={{display: "inline"}}
                    />
                );
            } else {
                readIndicator = group.messageIsRead(message) ? (
                    <Image
                        height="20px"
                        src="/public/assets/seen_doubleCheck.svg"
                        style={{display: "inline"}}
                    />
                ) : (
                    <Image
                        height="20px"
                        src="/public/assets/seen_singleCheck.svg"
                        style={{display: "inline"}}
                    />
                );
            }
        }

        const timeString = DateUtils.formatMessageDateTime(message.timeSent, preferences);

        let bubbleContent;
        switch (message.content.type) {
            case MessageContentType.text:
            case MessageContentType.bryx:
                bubbleContent = (
                    <div className={bodyClassNames.join(" ")}>
                        <Linkify properties={{
                            target: "_blank",
                            className: !message.sender.isMe ? "lightBackgroundLink" : undefined,
                        }}>
                            {message.content.text}
                        </Linkify>
                        <div className="message-time message-time-text">{timeString} {readIndicator}</div>
                    </div>
                );
                break;
            case MessageContentType.image:
                bubbleContent = (
                    <div className={bodyClassNames.join(" ")} onClick={onClickImage}>
                        <img
                            style={imageStyle}
                            src={BryxApi.getImageUrl(message.content.imageId, true)}
                        />
                        <div className="message-time message-time-img">{timeString} {readIndicator}</div>
                        <Dimmer active={isLoadingFullImage}
                                style={{zIndex: 0}}>
                            <Loader/>
                        </Dimmer>
                    </div>
                );
        }
        const messageContextIcon = (
            <Icon
                link
                className={`message-context-icon message-context-icon-${message.sender.isMe ? "left" : "right"}`}
                name="address book"
                color="grey"
                inverted
                circular
                onClick={onClickShowSeenList}
            />
        );
        return (
            <div className={containerClassNames.join(" ")}>
                {senderLine}
                <div className="message-bubble-container">
                    {bubbleContent}
                    {messageContextIcon}
                </div>
                {message.status.key == MessageStatusType.failed ? (
                    <span>{message.status.error}
                        <span
                            style={{cursor: "pointer", fontWeight: "bold"}}
                            onClick={onClickResend}>{i18n.t("general.retry")}
                        </span>
                    </span>
                ) : null}
            </div>
        );
    }
}
