import { ReactNode, useEffect, useMemo, useState } from "react";
import { Button, Segment, Select } from "semantic-ui-react";
import React from "react";
import { PaginatedResult } from "../models/paginatedResult";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import i18n from "i18next";
import "../../sass/components/_paginatedItems.scss";
import { useConstCallback } from "@bryx-inc/ts-utils";

export type PaginationState<TDataType> = {
    page: number;
    pageSize: number;
    displayedData: TDataType[];
};
export type PaginationControls<TDataType> = {
    state: PaginationState<TDataType>;
    count: number;
    next: () => Promise<TDataType[]>;
    previous: () => Promise<TDataType[]>;
    refresh: () => Promise<TDataType[]>;
    setPageSize: (size: number) => Promise<TDataType[]>;
};

export function usePagination<TDataType, TSearchType>(
    loadFunction: (
        page: number,
        pageSize: number,
        search: TSearchType
    ) => Promise<PaginatedResult<TDataType>>,
    initialState?: Partial<PaginationState<TDataType>>,
    searchValue?: TSearchType
): PaginationControls<TDataType> {
    const [paginationState, setPaginationState] = useState<
        PaginationState<TDataType>
    >({
        page: initialState?.page ?? 0,
        pageSize: initialState?.pageSize ?? 10,
        displayedData: initialState?.displayedData ?? [],
    });
    const [count, setCount] = useState<number>(0);
    const load = useConstCallback(loadFunction);

    async function updateState(state: {
        page?: number;
        pageSize?: number;
    }): Promise<TDataType[]> {
        const result = await load(
            state.page ?? paginationState.page,
            state.pageSize ?? paginationState.pageSize,
            searchValue ?? "" as TSearchType
        );
        setCount(result.count);
        setPaginationState({
            page: state.page ?? paginationState.page,
            pageSize: state.pageSize ?? paginationState.pageSize,
            displayedData: result.items,
        });
        return result.items;
    }

    useEffect(() => {
        updateState({});
    }, [searchValue]);

    return {
        state: paginationState,
        count: count,
        next: async function () {
            return await updateState({ page: paginationState.page + 1 });
        },
        previous: async function () {
            return await updateState({ page: paginationState.page - 1 });
        },
        refresh: async function () {
            return await updateState({});
        },
        setPageSize: async function (size: number) {
            return await updateState({ pageSize: size });
        },
    };
}

const selectOptions = [10, 25, 50].map((value) => {
    return { text: value.toString(), value };
});

export function PaginatedItems<TDataType>(props: {
    control: PaginationControls<TDataType>;
    item: (props: TDataType) => ReactNode;
}) {
    const { state, count, next, previous, refresh, setPageSize } =
        props.control;
    const pages = useMemo(
        () => Math.max(1, Math.ceil(count / state.pageSize)),
        [count, state.pageSize]
    );
    return (
        <div className="paginated-items">
            <Segment.Group className="paginated-item-container">
                {state.displayedData.map((item, i) => (
                    <Segment className="paginated-item" key={i}>
                        {props.item(item)}
                    </Segment>
                ))}
            </Segment.Group>
            <div className="paginator">
                <span className="page-number">
                    {i18n.t("pagination.of", {
                        page: (state.page + 1).toString(),
                        max: pages.toString(),
                    })}
                </span>
                <Select
                    upward
                    options={selectOptions}
                    value={state.pageSize}
                    onChange={useConstCallback((event, data) =>
                        setPageSize(data.value as number)
                    )}
                    className="page-size-select"
                />
                <Button.Group className="page-buttons">
                    <Button
                        icon
                        disabled={state.page == 0}
                        onClick={useConstCallback(() => previous())}
                    >
                        <ChevronLeft />
                    </Button>
                    <Button
                        icon
                        disabled={state.page == Math.max(0, pages - 1)}
                        onClick={useConstCallback(() => next())}
                    >
                        <ChevronRight />
                    </Button>
                </Button.Group>
            </div>
        </div>
    );
}
