import { ParseResult, ParseUtils } from "../utils/cerealParse";

export class MinimalNamedModel {
    public constructor(public id: string, public name: string) {}
    public static parse(o: any): ParseResult<MinimalNamedModel> {
        try {
            return ParseUtils.parseSuccess(new MinimalNamedModel(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "name")));
        } catch (e) {
            return ParseUtils.parseFailure<MinimalNamedModel>(`Failed to parse NamedModel: ${e.message}`);
        }
    }
}