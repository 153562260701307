import * as i18n from "i18next";
import * as React from 'react';
import {Button, Header, Icon, Modal} from 'semantic-ui-react';
import {Group} from "../../models/group";
import {GroupMember} from "../../models/groupMember";
import {Message} from "../../models/message";
import {BryxColors} from "../../utils/bryxColors";

export type SeenListModalStatus = { key: "unset" } | { key: "active", message: Message, group: Group };

interface SeenListModalProps {
    status: SeenListModalStatus;
    onClose: () => void;
}

export class SeenListModal extends React.Component<SeenListModalProps, {}> {
    render() {
        const {status, onClose} = this.props;
        return (
            <Modal
                className="seen-list-modal"
                open={status.key == "active"}
                size="tiny"
                onClose={onClose}>
                <Modal.Header>
                    <Header as="h2">
                        <Header.Content>
                            {i18n.t("messages.seenList.title")}
                        </Header.Content>
                        {status.key == "active" && status.group.members != null ? (
                            <Header.Subheader>
                                {i18n.t("messages.seenList.XOutOfXMembers", {
                                    count: status.group.members.length,
                                    num: status.group.members.filter(m => m.hasSeen(status.message)).length,
                                })}
                            </Header.Subheader>
                        ) : null}
                    </Header>
                </Modal.Header>
                <Modal.Content className="modal-content-container">
                    {status.key == "active" ?
                        status.group.members.slice().sort(GroupMember.getCompare(status.message)).map(member => {
                            const hasSeen = member.hasSeen(status.message);
                            return (
                                <div key={member.id} className="seen-list-item">
                                    <div className="seen-list-item-icon-container">
                                        {hasSeen ? (
                                            <Icon
                                                className="seen-list-item-icon"
                                                color='green'
                                                name='checkmark'
                                                size='large'
                                            />
                                        ) : null}
                                    </div>
                                    <Header
                                        as='h4'
                                        style={{
                                            margin: 0,
                                            color: !hasSeen ? BryxColors.lightGray : undefined,
                                        }}>
                                        {member.name}
                                    </Header>
                                </div>
                            );
                        }) : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button content={i18n.t("general.done")} onClick={onClose}/>
                </Modal.Actions>
            </Modal>
        );
    }
}
