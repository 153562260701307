import {Point} from "geojson";
import {latLng, LatLng, LatLngBounds, LatLngTuple} from "leaflet";
import {Address} from "../models/address";
import {BryxNavigationApp, BryxPreferences} from "./preferenceManager";

export interface DirectionsURL {
    url: string;
    newWindow: boolean;
}

export class GeoUtils {
    public static geoJsonToLatLng(point: Point): LatLng {
        const flippedCoords = point.coordinates.slice(0, 2).reverse();
        return latLng(flippedCoords as [number, number]);
    }

    public static geoJsonPointIsEqual(a: Point | null, b: Point | null): boolean {
        if (a == null || b == null) {
            return false;
        }
        return latLng(a.coordinates as LatLngTuple).distanceTo(latLng(b.coordinates as LatLngTuple)) < 0.5;
    }

    /**
     * Takes in the startingLocation, endingLocation, and the navigation to format them to be inserted into the url
     *
     * @param startingLocation The current location of the user, as either an array of coordinates or null if we don't have their location
     * @param endingLocation The location to navigate to, as either an array of coordinates or a string address
     * @param navigationService The navigation service that the user has set in their preferences
     * @private
     */
    private static formatDirectionEndpoints(startingLocation: number[] | null, endingLocation: number[] | string, navigationService: BryxNavigationApp): {start: string, end: string} {
        const result = {
            start: '',
            end: '',
        };

        // Based on the navigation service that they will be using, the format for the start/end strings will be a bit different
        switch (navigationService) {
            case BryxNavigationApp.googleMaps:
                if (startingLocation != null) {
                    result.start = `origin=${startingLocation.slice(0, 2).reverse().join(',')}`;
                }
                result.end = `destination=${typeof endingLocation !== "string" ? endingLocation.join(',') : endingLocation}`;
                break;
            case BryxNavigationApp.appleMaps:
                if (startingLocation != null) {
                    result.start = `saddr=${startingLocation.slice(0, 2).reverse().join(',')}`;
                }
                result.end = `daddr=${typeof endingLocation !== "string" ? endingLocation.join(',') : endingLocation}`;
                break;
            case BryxNavigationApp.windowsMaps:
            case BryxNavigationApp.bingMaps:
                if (startingLocation != null) {
                    result.start = `pos.${startingLocation.slice(0, 2).reverse().join("_")}`;
                }
                result.end = typeof endingLocation !== "string" ? `pos.${endingLocation.join('_')}` : `adr.${endingLocation}`;
        }

        return result;
    }

    /**
     * Takes in the destination point, the BryxPreferences object, an optional Address object, and an optional
     * startingLocation array and returns a DirectionsURL object that holds the url for navigation and whether to open it in a new window.
     *
     * @param point The destination coordinates as a Point object
     * @param preferences The BryxPreferences object that has the property preferredNavigation
     * @param jobAddress The job address as an Address object, if we have the municipality and state we will use that to map to the location
     * @param startingLocation The starting location as a number array of coordinates
     */
    public static directionsUrl(point: Point, preferences: BryxPreferences, jobAddress?: Address | null, startingLocation?: number[]): DirectionsURL {
        let destination: number[] | string;
        // To make the destination more accurate, if we have an address with a municipality and a state, we will use that to route to the job, otherwise we will use the coordinates
        if (jobAddress && jobAddress.municipality && jobAddress.state) {
            // URI encoding the destination string allows us to stick it into the url in a correctly formatted manner
            destination = encodeURIComponent(`${jobAddress.street} ${jobAddress.municipality} ${jobAddress.state}${jobAddress.postalCode ? ` ${jobAddress.postalCode}` : ``}`);
        } else {
            destination = point.coordinates.slice(0, 2).reverse();
        }

        // Getting the start/end formatted strings
        const {start, end} = this.formatDirectionEndpoints(startingLocation || null, destination, preferences.preferredNavigation);

        // Returning the correct url based on the user's preferred navigation app
        switch (preferences.preferredNavigation) {
            case BryxNavigationApp.googleMaps:
                return {
                    url: `https://www.google.com/maps/dir/?api=1&${start}&${end}`,
                    newWindow: true,
                };
            case BryxNavigationApp.appleMaps:
                return {
                    url: `https://maps.apple.com/?${start}&${end}&dirflg=d`,
                    newWindow: false,
                };
            case BryxNavigationApp.bingMaps:
                return {
                    url: `https://bing.com/maps/default.aspx?rtp=${start}~${end}`,
                    newWindow: true,
                };
            case BryxNavigationApp.windowsMaps:
                return {
                    url: `bingmaps:?rtp=${start}~${end}`,
                    newWindow: false,
                };
        }
    }

    public static positionToPoint(position: Position): Point {
        return {
            type: "Point",
            coordinates: [position.coords.longitude, position.coords.latitude],
        };
    }

    public static boundsFromLatLngs(latLngs: [[number, number]]): LatLngBounds | null {
        const uniqueLatLngs: Array<[number, number]> = [];
        latLngs.forEach(original => {
            const matchs = uniqueLatLngs.filter(searchLatLng => latLng(searchLatLng).distanceTo(latLng(original)) < 0.5);
            if (matchs.length == 0) {
                uniqueLatLngs.push(original);
            }
        });

        if (uniqueLatLngs.length == 0) {
            return null;
        } else if (uniqueLatLngs.length == 1) {
            const coord = uniqueLatLngs[0];
            return new LatLng(coord[0], coord[1]).toBounds(100);
        } else {
            const swLat = Math.min(...uniqueLatLngs.map(ll => ll[0]));
            const swLng = Math.min(...uniqueLatLngs.map(ll => ll[1]));
            const neLat = Math.max(...uniqueLatLngs.map(ll => ll[0]));
            const neLng = Math.max(...uniqueLatLngs.map(ll => ll[1]));
            return new LatLngBounds(new LatLng(swLat, swLng), new LatLng(neLat, neLng));
        }
    }
}
