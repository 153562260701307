import {v4 as uuidgen} from "uuid";
import {config} from "../config";
import {Auth as AuthModel, LocationPrivacy} from "../models/auth";
import {LocationDevice} from "../models/locationDevice";
import {Stream} from "../models/stream";
import {SupplementalSortOrder} from "../models/supplemental";
import {ParseUtils} from "./cerealParse";
import {LocationState} from "./locationManager";
import {Agency} from "../models/agency";

export class BryxLocal {
    private static readonly prefix: string = `com.bryx.`;
    private static readonly globalPrefix: string = "global.bryx.";
    private static readonly requiredKeysForSignIn: string[] = [ 'apiKey', 'email', 'clientType' ];

    private static get(key: string, prefix: string = BryxLocal.prefix): any {
        const item = localStorage.getItem(prefix + key);
        return item ? JSON.parse(item) : null;
    }

    private static set(key: string, value: any, prefix: string = BryxLocal.prefix) {
        localStorage.setItem(prefix + key, JSON.stringify(value));
    }

    private static remove(key: string, prefix: string = BryxLocal.prefix) {
        localStorage.removeItem(prefix + key);
    }

    public static getBryxItems(): any {
        const bryxItems: any = {};

        Object.keys(localStorage)
            .filter(key => key.indexOf(BryxLocal.prefix) == 0 || key.indexOf(BryxLocal.globalPrefix) == 0)
            .forEach(key => { bryxItems[key] = localStorage.getItem(key); });

        bryxItems.appVersion = config.version;

        return bryxItems;
    }

    public static getItem<T>(key: string): T | null {
        return this.get(key) as T | null;
    }

    public static setItem<T>(key: string, value: T) {
        return this.set(key, value);
    }

    public static clear() {
        Object.keys(localStorage)
            .filter(key => key.indexOf(BryxLocal.prefix) == 0)
            .forEach(key => { localStorage.removeItem(key); });
    }

    public static initializeFromAuthModel(authModel: AuthModel): void {
        this.set('apiKey', authModel.apiKey);
        this.set('email', authModel.email);
        this.set('name', authModel.name);
        this.set('clientType', authModel.type);
        this.set('locationDevices', authModel.devices);
        this.set('locationPrivacy', authModel.locationPrivacy);
        this.set('streams', authModel.streams);
        this.set('showEula', authModel.showEula);
        this.set('allowNotifications', authModel.allowNotifications);
        this.set('usingAvl', authModel.usingAvl, BryxLocal.globalPrefix);
        this.set('agencies', authModel.agencies);
        this.set('clientId', authModel.clientId);
    }

    public static getAgencies(): Agency[] {
        return this.get("agencies").map((agency: Agency) => (Agency.parse(agency) as any).value ?? false).filter(Boolean);
    }

    public static getDeviceId(): string {
        let currentId = this.get("deviceId", BryxLocal.globalPrefix);
        if (currentId == null) {
            currentId = uuidgen();
            this.set("deviceId", currentId, BryxLocal.globalPrefix);
        }
        return currentId;
    }

    public static getLocationState(): LocationState | null {
        return this.get("locationState", BryxLocal.globalPrefix);
    }

    public static setLocationState(state: LocationState) {
        this.set("locationState", state, BryxLocal.globalPrefix);
    }

    public static getForceDeviceSupport(): boolean | null {
        return this.get("forceDeviceSupport");
    }

    public static setForceDeviceSupport(force: boolean) {
        this.set("forceDeviceSupport", force);
    }

    public static getCurrentPosition(): Position | null {
        return this.get("currentPosition");
    }

    public static setCurrentPosition(position: Position) {
        // A `Position` cannot be serialized with `JSON.stringify` but this fake position implements the same interface.
        const fakePosition = {
            timestamp: position.timestamp,
            coords: {
                accuracy: position.coords.accuracy,
                altitude: position.coords.altitude,
                altitudeAccuracy: position.coords.altitudeAccuracy,
                heading: position.coords.heading,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                speed: position.coords.speed,
            },
        };
        this.set("currentPosition", fakePosition);
    }

    public static removeCurrentPosition() {
        return this.remove("currentPosition");
    }

    public static getEmail(): string | null {
        return this.get('email');
    }

    public static getName(): string | null {
        return this.get('name');
    }

    public static getApiKey(): string | null {
        return this.get('apiKey');
    }

    public static getAllowNotifications(): boolean | null {
        return this.get('allowNotifications');
    }

    public static setAllowNotifications(allowNotifications: boolean) {
        return this.set('allowNotifications', allowNotifications);
    }

    public static getLocationDevices(): LocationDevice[] {
        return this.get('locationDevices') as LocationDevice[];
    }

    public static getLocationPrivacy(): LocationPrivacy {
        return this.get('locationPrivacy') as LocationPrivacy;
    }

    public static getShowEula(): boolean | null {
        return this.get('showEula');
    }

    public static getShowReleaseNotes(): string | null {
        return this.get("showReleaseNotes");
    }

    public static setShowReleaseNotes(show: string) {
        this.set("showReleaseNotes", show);
    }

    public static getCurrentBaseLayerId() {
        return this.get("currentBaseLayerId");
    }

    public static setCurrentBaseLayerId(layerId: string) {
        this.set("currentBaseLayerId", layerId);
    }

    public static getSupplementalSortOrder(): SupplementalSortOrder | null {
        return this.get("supplementalSortOrder");
    }

    public static setSupplementalSortOrder(order: SupplementalSortOrder) {
        this.set("supplementalSortOrder", order);
    }

    public static setLocationDevices(newDevices: LocationDevice[]) {
        this.set("locationDevices", newDevices);
    }

    public static setLocationPrivacy(newPrivacy: LocationPrivacy) {
        this.set("locationPrivacy", newPrivacy);
    }

    public static setShowEula(show: boolean) {
        this.set('showEula', show);
    }

    public static getStreams(): Stream[] {
        return ParseUtils.getArrayOfSubobjects({s: this.get("streams")}, "s", Stream.parseLocal, "warn");
    }

    public static isSignedIn(): boolean {
        return this.requiredKeysForSignIn.every(k => BryxLocal.get(k) != null);
    }

    public static isApparatus(): boolean {
        return this.get('clientType') == 'truck';
    }

    public static getClientId(): string {
        return this.get('clientId');
    }
}
