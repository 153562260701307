import * as i18n from "i18next";
import * as React from "react";
import {Loader, Message} from "semantic-ui-react";
import {JobListItem} from "../../components/jobListItem";
import {config} from "../../config";
import {FullJob, ListJob} from "../../models/job";
import {BryxPreferences, PreferenceManager, PreferenceManagerObserver} from "../../utils/preferenceManager";

interface JobHistoricalProps {
    job: FullJob;
}

interface JobHistoricalState {
    status: { key: "loading" } | { key: "success", historicalJobs: ListJob[] } | { key: "failure", message: string };
    preferences: BryxPreferences;
}

export class JobHistoricalTab extends React.Component<JobHistoricalProps, JobHistoricalState> implements PreferenceManagerObserver {
    constructor(props: JobHistoricalProps, context: any) {
        super(props, context);

        if (props.job.historicalJobs != null) {
            this.state = {
                status: {
                    key: "success",
                    historicalJobs: props.job.historicalJobs,
                },
                preferences: PreferenceManager.shared.preferences,
            };
        } else {
            this.loadHistoricalJobs(props, true);
            this.state = {
                status: {
                    key: "loading",
                },
                preferences: PreferenceManager.shared.preferences,
            };
        }
    }

    componentDidMount() {
        PreferenceManager.shared.registerObserver(this);
    }

    componentWillUnmount() {
        PreferenceManager.shared.unregisterObserver(this);
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void {
        this.setState({preferences: newPrefs});
    }

    UNSAFE_componentWillReceiveProps(nextProps: JobHistoricalProps, nextContext: any) {
        if (nextProps.job.id != this.props.job.id) {
            this.loadHistoricalJobs(nextProps, false);
        }
    }

    private loadHistoricalJobs(props: JobHistoricalProps, initial: boolean) {
        if (!initial) {
            this.setState({status: {key: "loading"}});
        }
        this.props.job.loadHistoricalJobs(result => {
            if (result.success == true) {
                this.setState({
                    status: {
                        key: "success",
                        historicalJobs: result.value,
                    },
                });
            } else {
                config.warn(`Failed to load historical jobs for job@${this.props.job.id}`);
                this.setState({
                    status: {
                        key: "failure",
                        message: result.message,
                    },
                });
            }
        });
    }

    render() {
        const job = this.props.job;
        if (!job.hasHistorical) {
            // This case should never happen, the historical tab will not even be presented if the job has no historical jobs
            return <span>{i18n.t("jobs.jobInfo.historical.noHistoricalJobs")}</span>;
        }
        let content;
        if (this.state.status.key == "loading") {
            content = <div className="flexCenteredContainer"><Loader active inline/></div>;
        } else if (this.state.status.key == "failure") {
            content = <Message negative content={this.state.status.message}/>;
        } else {
            content = this.state.status.historicalJobs.map(historicalJob => (
                <JobListItem
                    key={historicalJob.id}
                    job={historicalJob}
                    selected={false}
                    preferences={this.state.preferences}
                />
            ));
        }
        return (
            <div className="job-historical-tab">
                {content}
            </div>
        );
    }
}
