import * as i18n from "i18next";
import * as React from 'react';
import {Header, Segment} from "semantic-ui-react";
import {config} from "../../config";
import {FullJob} from "../../models/job";
import {DateUtils} from "../../utils/dateUtils";
import {BryxPreferences, PreferenceManager, PreferenceManagerObserver} from "../../utils/preferenceManager";
import {KeyValueView} from "./keyValueView";

interface JobBasicInfoViewProps {
    job: FullJob;
    style?: any;
}

interface JobBasicInfoViewState {
    preferences: BryxPreferences;
}

export class JobBasicInfoView extends React.Component<JobBasicInfoViewProps, JobBasicInfoViewState> implements PreferenceManagerObserver {
    constructor(props: JobBasicInfoViewProps, context: any) {
        super(props, context);
        this.state = {
            preferences: PreferenceManager.shared.preferences,
        };
    }

    componentDidMount() {
        PreferenceManager.shared.registerObserver(this);
    }

    componentWillUnmount() {
        PreferenceManager.shared.unregisterObserver(this);
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void {
        this.setState({preferences: newPrefs});
    }

    render() {
        const job = this.props.job;
        return (
            <div className="job-basic-info-view" style={this.props.style}>
                <Header className="basic-info-header" as="h3" attached="top">
                    {i18n.t("jobs.jobInfo.information.title")}
                </Header>
                <Segment className="basic-info-container" attached>
                    {
                        config.configType != "prod" ? (<KeyValueView keyNode="_id" valueNode={job.id}/>) : null
                    }
                    {
                        job.address != null ? (
                            <KeyValueView
                                keyNode={i18n.t("jobs.jobInfo.information.location")}
                                valueNode={job.address.originalWithLocationInfo}
                            />
                        ) : null
                    }
                    {
                        job.crossStreets != null ? (
                            <KeyValueView
                                keyNode={i18n.t("jobs.jobInfo.information.crossStreets")}
                                valueNode={job.crossStreets}
                            />
                        ) : null
                    }
                    {
                        job.priority != null ? (
                            <KeyValueView
                                keyNode={i18n.t("jobs.jobInfo.information.priority")}
                                valueNode={job.priority}
                            />
                        ) : null
                    }
                    <KeyValueView
                        keyNode={i18n.t("jobs.jobInfo.information.assignment")}
                        valueNode={job.shortDepartment}
                    />
                    {
                        job.box != null ? (
                            <KeyValueView
                                keyNode={i18n.t("jobs.jobInfo.information.box")}
                                valueNode={job.box}
                            />
                        ) : null
                    }
                    {
                        job.incidentId != null ? (
                            <KeyValueView
                                keyNode={i18n.t("jobs.jobInfo.information.incidentId")}
                                valueNode={job.incidentId}
                            />
                        ) : null
                    }
                    {
                        job.cadId != null ? (
                            <KeyValueView
                                keyNode={i18n.t("jobs.jobInfo.information.cadId")}
                                valueNode={job.cadId}
                            />
                        ) : null
                    }
                    <KeyValueView
                        keyNode={i18n.t("jobs.jobInfo.information.time")}
                        valueNode={DateUtils.formatDateTime(job.creationTime, this.state.preferences)}
                    />
                </Segment>
            </div>
        );
    }
}
