import * as i18n from "i18next";
import {ParseResult, ParseUtils} from "../utils/cerealParse";
import {JobType} from "./jobTypeInformation";

export enum BryxNotificationType {
    job, supplemental, message,
}

export type BryxNotificationContent = { type: BryxNotificationType.job, jobId: string, jobType: JobType } |
    { type: BryxNotificationType.supplemental, jobId: string} |
    { type: BryxNotificationType.message, groupId: string};

export enum BryxNotificationAlertType {
    none, sound,
}

export type BryxNotificationAlert = { type: BryxNotificationAlertType.none } |
    { type: BryxNotificationAlertType.sound, soundId: string };

function parseBryxNotificationAlert(o: any): ParseResult<BryxNotificationAlert> {
    try {
        const type = ParseUtils.getEnum(o, "type", BryxNotificationAlertType);
        switch (type) {
            case BryxNotificationAlertType.none:
                return ParseUtils.parseSuccess({
                    type: BryxNotificationAlertType.none,
                } as BryxNotificationAlert);
            case BryxNotificationAlertType.sound:
                return ParseUtils.parseSuccess({
                    type: BryxNotificationAlertType.sound,
                    soundId: ParseUtils.getString(o, "soundId"),
                } as BryxNotificationAlert);
            default:
                return ParseUtils.parseFailure("Case not possible");
        }
    } catch (e) {
        return ParseUtils.parseFailure<BryxNotificationAlert>(`Invalid BryxNotificationAlert Model: ${e.message}`);
    }
}

export class BryxNotification {
    private constructor(
        public id: string,
        public date: Date,
        public expirationDate: Date,
        public content: BryxNotificationContent,
        public title: string,
        public message: string,
        public alert: BryxNotificationAlert,
    ) {}

    static parse(o: any): ParseResult<BryxNotification> {
        try {
            const type: BryxNotificationType = ParseUtils.getEnum(o, "type", BryxNotificationType);
            let title = ParseUtils.getString(o, "title");
            let content: BryxNotificationContent;
            switch (type) {
                case BryxNotificationType.job:
                    content = {
                        type: BryxNotificationType.job,
                        jobId: ParseUtils.getString(o["content"], "id"),
                        jobType: ParseUtils.getEnum(o["content"], "type", JobType),
                    };
                    break;
                case BryxNotificationType.supplemental:
                    if (title == "") {
                        title = i18n.t("notifications.supplementalUpdate");
                    }
                    content = {
                        type: BryxNotificationType.supplemental,
                        jobId: ParseUtils.getString(o["content"], "id"),
                    };
                    break;
                case BryxNotificationType.message:
                    content = {
                        type: BryxNotificationType.message,
                        groupId: ParseUtils.getString(o["content"], "id"),
                    };
                    break;
                default:
                    return ParseUtils.parseFailure("Case not possible");
            }

            return ParseUtils.parseSuccess(new BryxNotification(
                ParseUtils.getString(o, "id"),
                ParseUtils.getUNIXTimestampDate(o, "ts"),
                ParseUtils.getUNIXTimestampDate(o, "expirationTs"),
                content,
                title,
                ParseUtils.getString(o, "message"),
                ParseUtils.getSubobject(o, "alert", parseBryxNotificationAlert),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<BryxNotification>(`Invalid BryxNotification Model: ${e.message}`);
        }
    }

    static compare(n1: BryxNotification, n2: BryxNotification): number {
        return n2.date.getTime() - n1.date.getTime() || n1.id.localeCompare(n2.id);
    }
}
