import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {ParseResult, ParseUtils} from "../utils/cerealParse";
import {ListJob} from "./job";

// JobsListServerUpdate

export type JobsListServerUpdateNew = {
    key: "new",
    id: string,
    job: ListJob,
};
export type JobsListServerUpdateOld = {
    key: "old",
    id: string,
    jobId: string,
};
export type JobsListServerUpdateClosed = {
    key: "closed",
    id: string,
    job: ListJob,
};
export type JobsListServerUpdateHasResponded = {
    key: "hasResponded",
    id: string,
    jobId: string,
};
export type JobsListServerUpdateAssignments = {
    key: "assignments",
    id: string,
    jobId: string,
    unitShortNames: string[],
};
export type JobsListServerUpdate = JobsListServerUpdateNew | JobsListServerUpdateOld | JobsListServerUpdateClosed | JobsListServerUpdateHasResponded | JobsListServerUpdateAssignments;

function parseJobsListServerUpdate(o: any): ParseResult<JobsListServerUpdate> {
    try {
        const type = ParseUtils.getString(o, "type");
        const id = ParseUtils.getString(o, "id");
        switch (type) {
            case "new":
                return ParseUtils.parseSuccess({
                    key: "new",
                    id: id,
                    job: ParseUtils.getSubobject(o, "job", ListJob.parse),
                } as JobsListServerUpdateNew);
            case "old":
                return ParseUtils.parseSuccess({
                    key: "old",
                    id: id,
                    jobId: ParseUtils.getString(o, "jobId"),
                } as JobsListServerUpdateOld);
            case "closed":
                return ParseUtils.parseSuccess({
                    key: "closed",
                    id: id,
                    job: ParseUtils.getSubobject(o, "job", ListJob.parse),
                } as JobsListServerUpdateClosed);
            case "hasResponded":
                return ParseUtils.parseSuccess({
                    key: "hasResponded",
                    id: id,
                    jobId: ParseUtils.getString(o, "jobId"),
                } as JobsListServerUpdateHasResponded);
            case "assignments":
                return ParseUtils.parseSuccess({
                    key: "assignments",
                    id: id,
                    jobId: ParseUtils.getString(o, "jobId"),
                    unitShortNames: ParseUtils.getArray(o, "unitShortNames"),
                } as JobsListServerUpdateAssignments);
            default:
                return ParseUtils.parseFailure<JobsListServerUpdate>(`Invalid JobsListServerUpdate type: ${type}`);
        }
    } catch (e) {
        return ParseUtils.parseFailure<JobsListServerUpdate>(`Invalid JobsListServerUpdate Model: ${e.message}`);
    }
}

// JobsListUpdate

export type JobsListUpdateReplace = {
    key: "replace",
    openJobs: ListJob[],
    closedJobs: ListJob[],
};
export type JobsListUpdateFastForward = {
    key: "fastForward",
    updates: JobsListServerUpdate[],
};
export type JobsListUpdateUpdate = {
    key: "update",
    update: JobsListServerUpdate,
};
export type JobsListUpdate = JobsListUpdateReplace | JobsListUpdateFastForward | JobsListUpdateUpdate;

export function parseJobsListUpdate(message: BryxWebSocketMessage): ApiResult<JobsListUpdate | null> {
    try {
        switch (message.key) {
            case "serverUpdate":
                return apiSuccess({
                    key: "update",
                    update: ParseUtils.getSubobject({data: message.data}, "data", parseJobsListServerUpdate),
                } as JobsListUpdateUpdate);
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }
                const type = ParseUtils.getString(message.initialData, "type");
                switch (type) {
                    case "replace":
                        return apiSuccess({
                            key: "replace",
                            openJobs: ParseUtils.getArrayOfSubobjects(message.initialData, "open", ListJob.parse, "warn"),
                            closedJobs: ParseUtils.getArrayOfSubobjects(message.initialData, "closed", ListJob.parse, "warn"),
                        } as JobsListUpdateReplace);
                    case "fastForward":
                        return apiSuccess({
                            key: "fastForward",
                            updates: ParseUtils.getArrayOfSubobjects(message.initialData, "updates", parseJobsListServerUpdate, "warn"),
                        } as JobsListUpdateFastForward);
                    default:
                        return apiFailure(null, `Invalid JobsListUpdate subscribe response type: ${type}`);
                }
            default:
                return apiSuccess(null);
        }
    } catch (e) {
        return apiFailure(null, `Invalid JobsListUpdate Model: ${e.message}`);
    }
}
