import React, { useEffect, useState } from "react";
import { useAccountApi } from "../../../utils/lunchHooks";
import { ClientSMSFeature, SMSRegistration } from "@bryxinc/lunch/models";
import { Button, Dimmer, Loader, Modal, Segment } from "semantic-ui-react";
import { BryxSwitch } from "@bryxinc/ui";
import i18n from "i18next";
import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { Add, Close } from "@mui/icons-material";
import { generateRedirectionUrl } from "../../../pages/redirection";
import { PhoneInput } from "./util";
import { AlertItem } from "./alertPane";
import { formatTel } from "../../../utils/functions";
import { toast } from "react-toastify";

export function SmsAlertManager() {
    const [smsFeature, setSmsFeature] = useState<ClientSMSFeature | null>(null);
    const [purchasingCredits, setPurchasingCredits] = useState<boolean>(false);
    const [smsEnabled, setSmsEnabled] = useState<boolean>(false);
    const account = useAccountApi();

    const [newSms, setNewSms] = useState("");
    const [smsAlerts, setSmsAlerts] = useState<SMSRegistration[]>([]);
    const [deletingSms, setDeletingSms] = useState<SMSRegistration | null>(
        null
    );
    const [addLoading, setAddLoading] = useState(false);
    const [paymentProcessing, setPaymentProcessing] = useState(false);

    function loadSmsAlerts() {
        setNewSms("");
        account.getSMSRegistrations((result) => {
            if (result.success) {
                setSmsAlerts(result.value);
            }
            setAddLoading(false);
        });
    }

    useEffect(() => {
        account.getSmsFeature((result) => {
            if (result.success) {
                setSmsFeature(result.value);
            } else {
                console.error("Failed to load SMS feature");
            }
        });
        loadSmsAlerts();
    }, []);

    useEffect(() => {
        setPurchasingCredits(
            !smsFeature || (smsFeature && smsFeature.credits <= 0)
        );
        setSmsEnabled(smsFeature ? smsFeature.enabled : false);
    }, [smsFeature]);

    const stripe = useStripe();
    const elements = useElements();

    function handleSubmit() {
        if (!stripe || !elements) {
            return;
        }
        setPaymentProcessing(true);
        stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url:
                    window.location.origin +
                    generateRedirectionUrl("stripe", window.location.pathname, {
                        for: "smsAlerts",
                    }),
            },
        }).then(({error}) => {
            toast.error(i18n.t(`account.sections.sms.errors.stripe.${error.code}`));
            setPaymentProcessing(false);
        });
    }

    return (
        <div className="sms-alert-manager">
            {!smsFeature && <Loader inline="centered" active />}
            {smsFeature && (
                <div className="alerts-loaded">
                    <Dimmer.Dimmable dimmed={smsFeature.credits <= 0}>
                        <Dimmer active={smsFeature.credits <= 0}>
                            <span className="missing-credits">
                                {i18n.t(
                                    "account.sections.sms.errors.missingCredits"
                                )}
                            </span>
                        </Dimmer>
                        <Segment className="alert-switch">
                            <span className="title">
                                {i18n.t("account.sections.sms.enable")}
                            </span>
                            {smsFeature.credits > 0 && smsEnabled && (
                                <span className="credit-count">
                                    {i18n.t(
                                        "account.sections.sms.creditCount",
                                        { credits: smsFeature.credits }
                                    )}
                                </span>
                            )}
                            <BryxSwitch
                                checked={smsEnabled}
                                onClick={() => {
                                    account.editSmsFeature(
                                        !smsEnabled,
                                        undefined,
                                        (result) =>
                                            result.success &&
                                            setSmsFeature(result.value)
                                    );
                                    setSmsEnabled(!smsEnabled);
                                }}
                            />
                        </Segment>
                    </Dimmer.Dimmable>
                    {purchasingCredits && (
                        <Segment className="purchase-form">
                            {smsFeature.credits > 0 && (
                                <Button
                                    className="cancel-btn"
                                    circular
                                    icon
                                    onClick={() => setPurchasingCredits(false)}
                                >
                                    <Close />
                                </Button>
                            )}
                            <PaymentElement
                                options={{
                                    readOnly: paymentProcessing,
                                }}
                            />
                            <Button
                                fluid
                                className="purchase-btn"
                                onClick={handleSubmit}
                                disabled={paymentProcessing}
                                loading={paymentProcessing}
                            >
                                {i18n.t(
                                    "account.sections.sms.purchasing.submit"
                                )}
                            </Button>
                        </Segment>
                    )}
                    {!purchasingCredits && smsEnabled && (
                        <Button
                            fluid
                            className="purchase-btn"
                            onClick={() => setPurchasingCredits(true)}
                        >
                            {i18n.t("account.sections.sms.purchasing.submit")}
                        </Button>
                    )}
                </div>
            )}
            {smsEnabled &&
                smsFeature &&
                smsFeature.credits > 0 &&
                !purchasingCredits && (
                    <div className="sms-manager">
                        <PhoneInput
                            value={newSms}
                            onChange={setNewSms}
                            disabled={addLoading}
                        />
                        <Button
                            icon
                            className="alert-add"
                            disabled={newSms.length < 11 || addLoading}
                            loading={addLoading}
                            onClick={() => {
                                setAddLoading(true);
                                account.createSMSRegistration(
                                    newSms,
                                    (result) => {
                                        if (result.success) {
                                            loadSmsAlerts();
                                            toast.success(
                                                i18n.t(
                                                    "account.sections.sms.addSuccess"
                                                )
                                            );
                                        } else {
                                            toast.error(
                                                i18n.t(
                                                    "account.sections.sms.errors.addError"
                                                )
                                            );
                                            setAddLoading(false);
                                        }
                                    }
                                );
                            }}
                        >
                            <Add />
                        </Button>
                        <Segment.Group className="alert-list">
                            {smsAlerts.length > 0 &&
                                smsAlerts.map((registration) => (
                                    <AlertItem
                                        id={registration.id}
                                        address={formatTel(
                                            registration.phone
                                        )}
                                        onDelete={() =>
                                            setDeletingSms(registration)
                                        }
                                    />
                                ))}
                            {smsAlerts.length == 0 && (
                                <Segment className="alert-item no-alerts">
                                    {i18n.t("account.sections.alerts.none")}
                                </Segment>
                            )}
                        </Segment.Group>
                    </div>
                )}
            <Modal
                className="delete-sms-registration"
                open={Boolean(deletingSms)}
                onClose={() => setDeletingSms(null)}
            >
                <Modal.Header>
                    {i18n.t("account.sections.sms.delete.title")}
                </Modal.Header>
                <Modal.Content>
                    {i18n.t("account.sections.sms.delete.message", {
                        phone:
                            deletingSms &&
                            formatTel(deletingSms.phone),
                    })}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        className="cancel"
                        onClick={() => setDeletingSms(null)}
                    >
                        {i18n.t("general.cancel")}
                    </Button>
                    <Button
                        className="confirm"
                        onClick={() => {
                            if (deletingSms) {
                                account.removeSMSRegistration(
                                    deletingSms.id,
                                    () => {
                                        toast.success(
                                            i18n.t(
                                                "account.sections.sms.delete.toast"
                                            )
                                        );
                                        loadSmsAlerts();
                                    }
                                );
                                setDeletingSms(null);
                            }
                        }}
                    >
                        {i18n.t("general.ok")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}
