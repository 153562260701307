import {config} from "../config";
import {Auth} from "../models/auth";
import {BryxApi} from "./bryxApi";

export interface SessionManagerObserver {
    sessionManagerDidUpdateSession(sessionObject: Auth): void;
}

export class SessionManager {
    private static SESSION_CHECK_INTERVAL = 24 * 60 * 60 * 1000; // 24 hours
    private static SESSION_MAX_AGE = 7 * 24 * 60 * 60 * 1000; // 7 days

    private observers: SessionManagerObserver[] = [];
    static shared = new SessionManager();
    private lastSessionUpdate: Date | null;
    private updateSessionTimerId: NodeJS.Timer | null = null;

    public start() {
        if (this.updateSessionTimerId != null) {
            config.error("Attempting to start SessionManager timer when already started");
            return;
        }
        this.updateSessionTimerId = setInterval(() => this.updateSessionIfRequired(), SessionManager.SESSION_CHECK_INTERVAL);
        // Does not need to be bryxNow, `lastSessionUpdate` is only compared against other system times
        this.lastSessionUpdate = new Date();
    }

    private updateSessionIfRequired() {
        const now = new Date();
        if (this.lastSessionUpdate != null && this.lastSessionUpdate.getTime() + SessionManager.SESSION_MAX_AGE <= now.getTime()) {
            config.info("Session max age has expired; updating session");
            BryxApi.session(result => {
                if (result.success == true) {
                    config.info("SessionManager successfully updated session");
                    this.lastSessionUpdate = new Date();
                    this.observers.forEach(o => o.sessionManagerDidUpdateSession(result.value));
                } else {
                    config.warn(`SessionManager failed to update session: ${result.debugMessage}`);
                }
            });
        }
    }

    public reset() {
        if (this.updateSessionTimerId == null) {
            config.error("Attempting to reset SessionManager timer when already stopped");
            return;
        }
        clearInterval(this.updateSessionTimerId);
        this.updateSessionTimerId = null;
        this.lastSessionUpdate = null;
    }

    // SessionManagerObservers

    public registerObserver(observer: SessionManagerObserver) {
        if (this.observers.filter(o => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }

    public unregisterObserver(observer: SessionManagerObserver) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }
}
