import { ParseResult, ParseUtils } from "../utils/cerealParse";

export class PaginatedResult<T> {
    public constructor(public count: number, public items: T[]) {}

    public static parse<T>(
        o: any,
        key: string,
        parser: (o: any) => ParseResult<T>
    ): ParseResult<PaginatedResult<T>> {
        try {
            return ParseUtils.parseSuccess(
                new PaginatedResult<T>(
                    ParseUtils.getNumber(o, "count"),
                    ParseUtils.getArrayOfSubobjects(o, key, parser, "ignore")
                )
            );
        } catch (e) {
            return ParseUtils.parseFailure<PaginatedResult<T>>(
                `Pagination failure: ${e.message}`
            );
        }
    }

    public static parserFactory<T>(
        key: string,
        parser: (o: any) => ParseResult<T>
    ): (o: any) => ParseResult<PaginatedResult<T>> {
        return (o: any) => {
            return PaginatedResult.parse<T>(o, key, parser);
        }
    }
}