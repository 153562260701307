import {ParseResult, ParseUtils} from "../utils/cerealParse";
import {Message} from "./message";

export class GroupMember {
    private constructor(
        public id: string,
        public isMe: boolean,
        public name: string,
        public phone: string | null,
        public readUntil: Date,
        public marksRead: boolean,
    ) {}

    static parse(o: any): ParseResult<GroupMember> {
        try {
            return ParseUtils.parseSuccess(new GroupMember(
                ParseUtils.getString(o, "id"),
                ParseUtils.getBoolean(o, "isMe"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getStringOrNull(o, "phone"),
                ParseUtils.getUNIXTimestampDate(o, "readUntil"),
                ParseUtils.getBoolean(o, "marksRead"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<GroupMember>(`Invalid GroupMember Model: ${e.message}`);
        }
    }

    public hasSeen(message: Message): boolean {
        return this.readUntil >= message.timeSent;
    }

    static getCompare(message: Message): (m1: GroupMember, m2: GroupMember) => number {
        return (m1, m2) => {
            const m1HasReadPoint = m1.hasSeen(message) ? 1 : 0;
            const m2HasReadPoint = m2.hasSeen(message) ? 1 : 0;
            return (m2HasReadPoint - m1HasReadPoint) || GroupMember.compare(m1, m2);
        };
    }

    static compare(m1: GroupMember, m2: GroupMember): number {
        return m1.name.localeCompare(m2.name) || m1.id.localeCompare(m2.id);
    }
}
