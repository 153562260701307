import {ParseResult, ParseUtils} from "../utils/cerealParse";

export class ApiError {
    private constructor(
        public realCause: string | null,
        public message: string,
    ) { }

    static parse(o: any): ParseResult<ApiError> {
        try {
            return ParseUtils.parseSuccess(new ApiError(
                ParseUtils.getStringOrNull(o, "realCause"),
                ParseUtils.getString(o, "message"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<ApiError>(`Invalid Api Error: ${e.message}`);
        }
    }
}

export type SortingDirection = "asc" | "desc";

export type SortingConfig<T> = {
    column: T,
    direction: SortingDirection,
};

export class PageResult<T> {
    private constructor(
        public count: number,
        public items: T[],
    ) {}

    static parse<T>(o: any, itemsKey: string, itemParser: (o: any) => ParseResult<T>): ParseResult<PageResult<T>> {
        try {
            return ParseUtils.parseSuccess(new PageResult<T>(
                ParseUtils.getNumber(o, "count"),
                ParseUtils.getArrayOfSubobjects(o, itemsKey, itemParser, "throw"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<PageResult<T>>(`Invalid PageResult Model: ${e.message}`);
        }
    }
}
