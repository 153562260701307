import { Icon, latLngBounds, DivIcon } from "leaflet";
import { Hydrant } from "../models/hydrant";
import { JobType } from "../models/jobTypeInformation";
import { MapClient, MapClientType } from "../models/mapClient";
import { BryxApi } from "../utils/bryxApi";

export class BryxMapUtil {
  public static readonly genericIcon = new Icon({
    iconUrl: "/public/assets/generic_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
  });

  public static readonly stationIcon = new Icon({
    iconUrl: "/public/assets/station_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
  });

  public static readonly fireIcon = new Icon({
    iconUrl: "/public/assets/job_map_pins/fire_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
  });

  public static readonly emsIcon = new Icon({
    iconUrl: "/public/assets/job_map_pins/ems_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
  });

  public static readonly infoIcon = new Icon({
    iconUrl: "/public/assets/job_map_pins/info_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
  });

  public static readonly waterIcon = new Icon({
    iconUrl: "/public/assets/job_map_pins/water_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
  });

  public static readonly policeIcon = new Icon({
    iconUrl: "/public/assets/job_map_pins/police_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
  });

  public static readonly myLocationIcon = new Icon({
    iconUrl: "/public/assets/my_location.gif",
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5],
  });

  private static readonly unitedStatesBounds = latLngBounds(
    [24.7433195, -124.7844079],
    [49.3457868, -66.9513812]
  );

  public static readonly ACCESS_TOKEN =
    "pk.eyJ1IjoidGVicm93biIsImEiOiIzb0xlTjlzIn0.qRGtH1Q-ZAViez0fTPX9fg";
  public static readonly MB_ATTRIBUTION =
    'Data &copy; <a href="http://bryx.com">Bryx, Inc.</a> | Imagery from <a href="http://mapbox.com/about/maps/">MapBox</a> | Map &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
  public static readonly MB_STYLE_URL =
    "https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}";

  static jobTypeToIcon(type: JobType): Icon {
    switch (type) {
      case JobType.fire:
        return BryxMapUtil.fireIcon;
      case JobType.ems:
        return BryxMapUtil.emsIcon;
      case JobType.info:
        return BryxMapUtil.infoIcon;
      case JobType.water:
        return BryxMapUtil.waterIcon;
      case JobType.police:
        return BryxMapUtil.policeIcon;
    }
  }

  static hydrantIconCache: { [index: string]: DivIcon } = {};

  static hydrantIcon(hydrant: Hydrant): DivIcon {
    const cleanedSize = (
      (hydrant.mainSize && hydrant.mainSize.toString()) ||
      ""
    ).slice(0, 2);
    const cacheKey = `hydrant:${hydrant.color}:${cleanedSize}`;
    const cachedIcon = BryxMapUtil.hydrantIconCache[cacheKey];
    if (cachedIcon != null) {
      return cachedIcon;
    }
    const icon = new DivIcon({
      iconUrl: "/public/assets/hydrant_pin.svg",
      iconSize: [20, 30],
      iconAnchor: [10, 30],
      className: "hydrantIcon",
      html: `<img src='${BryxApi.getHydrantSvgUrl(
        hydrant.color
      )}' height="30px" width="20px"/><span style='position: absolute; bottom: 2px; left: 0; width: 20px; text-align: center; color: white;'>${cleanedSize}</span>`,
    });
    BryxMapUtil.hydrantIconCache[cacheKey] = icon;
    return icon;
  }

  static clientIconCache: { [index: string]: DivIcon } = {};

  static mapClientIcon(mapClient: MapClient): DivIcon {
    const cachedIcon = BryxMapUtil.clientIconCache[mapClient.id];
    if (cachedIcon != null) {
      return cachedIcon;
    }
    const topOffset = mapClient.initials.length > 3 ? "20px" : "19px";
    const textSize = mapClient.initials.length > 3 ? "8px" : "10px";
    if (mapClient.info.type == MapClientType.user) {
      const icon = new DivIcon({
        iconAnchor: [11.5, 45],
        className: "userIcon",
        html: `<img src='/public/assets/client_map_pins/user_pin.png' height="45px" width="24px"/><span style='position: absolute; top: ${topOffset}; left: 0; width: 24px; text-align: center; font-size: ${textSize}; font-weight: bold;'>${mapClient.initials}</span>`,
      });
      BryxMapUtil.clientIconCache[mapClient.id] = icon;
      return icon;
    } else {
      const icon = new DivIcon({
        iconAnchor: [11.5, 45],
        className: "truckIcon",
        html: `<img src='/public/assets/client_map_pins/truck_pin.png' height="45px" width="24px"/><span style='position: absolute; top: ${topOffset}; left: 0; width: 24px; text-align: center; font-size: ${textSize}; font-weight: bold;'>${mapClient.initials}</span>`,
      });
      BryxMapUtil.clientIconCache[mapClient.id] = icon;
      return icon;
    }
  }
}