import {ParseResult, ParseUtils} from "../utils/cerealParse";

export type StreamingStatus = {key: "unselected"} | {key: "selected", stream: Stream, playback: { key: "playing", loading: boolean} | { key: "paused" } | {key: "error"} };

export type StreamSource = {type: "bryx"} | {type: "external", name: string | null};

export class Stream {
    private constructor(
        public id: string,
        public name: string,
        public url: string,
    ) {}

    get source(): StreamSource {
        if (this.url.indexOf("bryx.com") != -1) {
            return {type: "bryx"};
        } else if (this.url.indexOf("broadcastify") != -1) {
            return {type: "external", name: "Broadcastify"};
        } else {
            return {type: "external", name: null};
        }
    }

    isSupported(): boolean {
        const isBroadcastify = this.source.type == "external" && this.source.name == "Broadcastify";
        return !isBroadcastify;
    }

    static parse(o: any): ParseResult<Stream> {
        try {
            const name = ParseUtils.getString(o, "name");
            const url = ParseUtils.getString(o, "stream");
            return ParseUtils.parseSuccess(new Stream(
                `${name}:${url}`,
                name,
                url,
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Stream>(`Invalid Stream: ${e.message}`);
        }
    }

    static parseLocal(o: any): ParseResult<Stream> {
        try {
            return ParseUtils.parseSuccess(new Stream(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getString(o, "url"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Stream>(`Invalid Stream: ${e.message}`);
        }
    }

    static compare(s1: Stream, s2: Stream) {
        return s1.name.localeCompare(s2.name) || s1.id.localeCompare(s2.id);
    }
}
