import * as i18n from "i18next";
import * as React from "react";
import Linkify from "react-linkify";
import {KeyValueView} from "../../components/views/keyValueView";
import {FullJob} from "../../models/job";

interface JobSiteSurveyProps {
    job: FullJob;
}

export class JobSiteSurveyTab extends React.Component<JobSiteSurveyProps, {}> {
    render() {
        const job = this.props.job;
        if (job.siteSurvey == null) {
            // This case should never happen, the site survey tab will not even be presented if the job has no site survey record
            return <span>{i18n.t("jobs.jobInfo.siteSurvey.noSiteSurvey")}</span>;
        }

        return (
            <div className="job-site-survey-tab">
                {job.siteSurvey.critical != null ? (
                    <KeyValueView
                        keyWidth="200px"
                        keyNode={i18n.t("jobs.jobInfo.siteSurvey.critical")}
                        valueNode={
                            <Linkify properties={{target: "_blank", className: "lightBackgroundLink"}}>
                                {job.siteSurvey.critical}
                            </Linkify>
                        }
                    />
                ) : null}
                {job.siteSurvey.advisory != null ? (
                    <KeyValueView
                        keyWidth="200px"
                        keyNode={i18n.t("jobs.jobInfo.siteSurvey.advisory")}
                        valueNode={
                            <Linkify properties={{target: "_blank", className: "lightBackgroundLink"}}>
                                {job.siteSurvey.advisory.join("\n")}
                            </Linkify>
                        }
                    />
                ) : null}
                {job.siteSurvey.informational.map(i => (
                    <KeyValueView
                        keyWidth="200px"
                        key={i.key}
                        keyNode={i.key}
                        valueNode={
                            <Linkify properties={{target: "_blank", className: "lightBackgroundLink"}}>
                                {i.value}
                            </Linkify>
                        }
                    />
                ))}
            </div>
        );
    }
}
