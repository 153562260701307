import * as i18n from "i18next";
import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Button, Card, Header, Icon, Label, Loader, Modal, Popup, SemanticCOLORS} from "semantic-ui-react";
import {ZeroStateView} from "../../components/views/zeroStateView";
import {Stream, StreamingStatus} from "../../models/stream";

export type StreamPlaybackCommand = "play" | "pause" | "stop";

interface StreamsPageProps extends RouteComponentProps<{}> {
    streams: Stream[];
    playStatus: StreamingStatus;
    onSelect: (stream: Stream) => void;
    onPlaybackCommand: (command: StreamPlaybackCommand) => void;
}

interface StreamsPageState {
    showUnsupportedStream: boolean;
}

export class StreamsPage extends React.Component<StreamsPageProps, StreamsPageState> {
    constructor(props: StreamsPageProps, context: any) {
        super(props, context);
        this.state = {
            showUnsupportedStream: false,
        };
    }

    render() {
        const {streams, playStatus, onSelect, onPlaybackCommand} = this.props;
        if (streams.length == 0) {
            return (
                <div className="flexCenteredContainer" style={{backgroundColor: "white"}}>
                    <ZeroStateView
                        icon="volume up"
                        header={i18n.t("streams.noStreams.title")}
                        subheader={i18n.t("streams.noStreams.body")}
                    />
                </div>
            );
        }
        return (
            <div id="streams-page">
                <Card.Group className="stream-card-group" itemsPerRow={3}>
                    {streams.map(s => {
                        return (
                            <StreamItem
                                key={s.id}
                                stream={s}
                                onClick={() => {
                                    if (s.isSupported()) {
                                        onSelect(s);
                                    } else {
                                        this.setState({showUnsupportedStream: true});
                                    }
                                }}
                            />
                        );
                    })}
                </Card.Group>
                <div className="playback-container">
                    {playStatus.key == "selected" ? (
                        <div id="playback-control">
                            <Header className="playback-control-header" as="h4">
                                {playStatus.stream.name}
                                {playStatus.playback.key == "error" ? (
                                    <Popup trigger={
                                        <Icon
                                            className="warning-icon"
                                            size="mini"
                                            color="orange"
                                            name='warning sign'
                                        />
                                    }>
                                        {i18n.t("streams.problemLoading")}
                                    </Popup>
                                ) : undefined}
                            </Header>
                            <div className="playback-controls">
                                <Icon
                                    className="playback-control"
                                    name="stop"
                                    onClick={() => onPlaybackCommand("stop")}
                                />
                                {playStatus.playback.key == "playing" && playStatus.playback.loading ? (
                                    <Loader size="tiny" inline active/>
                                ) : (
                                    <Icon
                                        className="playback-control"
                                        name={playStatus.playback.key == "playing" ? "pause" : "play"}
                                        disabled={playStatus.playback.key == "playing" && playStatus.playback.loading}
                                        onClick={() => playStatus.playback.key == "playing" ?
                                            onPlaybackCommand("pause") : onPlaybackCommand("play")}
                                    />
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
                <UnsupportedStreamModal
                    open={this.state.showUnsupportedStream}
                    onDismiss={() => this.setState({showUnsupportedStream: false})}
                />
            </div>
        );
    }
}

interface StreamItemProps {
    stream: Stream;
    onClick: () => void;
}

class StreamItem extends React.Component<StreamItemProps, {}> {
    render() {
        const {stream, onClick} = this.props;
        let sourceLabelText;
        let sourceLabelColor: SemanticCOLORS;
        if (stream.source.type == "bryx") {
            sourceLabelText = i18n.t("branding.bryx");
            sourceLabelColor = "red";
        } else if (stream.source.name != null) {
            sourceLabelText = stream.source.name;
            sourceLabelColor = "blue";
        } else {
            sourceLabelText = i18n.t("streams.unknownSource");
            sourceLabelColor = "grey";
        }
        return (
            <Card className='streams-card' onClick={onClick}>
                <Card.Content>
                    <Card.Header className="truncate">{stream.name}</Card.Header>
                    <Card.Description>
                        <Label size="tiny" color={sourceLabelColor}>
                            {sourceLabelText}
                        </Label>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }
}

interface UnsupportedStreamModalProps {
    open: boolean;
    onDismiss: () => void;
}

const UnsupportedStreamModal: React.SFC<UnsupportedStreamModalProps> = props => (
    <Modal open={props.open}
           onClose={props.onDismiss}>
        <Modal.Header>{i18n.t("streams.unsupportedStream.title")}</Modal.Header>
        <Modal.Content>{i18n.t("streams.unsupportedStream.body")}</Modal.Content>
        <Modal.Actions>
            <Button content={i18n.t("general.close")} onClick={props.onDismiss}/>
        </Modal.Actions>
    </Modal>
);
