import { ReactNode, createContext, useContext, useState } from "react";
import React from "react";
import { AccountManagementModal } from "./accountManagement/accountManagementModal";
import { AboutUsModal } from "./aboutUsModal";
import { ApparatusSignOutModal } from "./apparatusSignoutModal";
import { ContactSupportModal } from "./contactSupportModal";
import {
    ForcedResponseModal,
    ForcedResponsePresentedStatus,
} from "./forcedResponseModal";
import { ForgotPasswordModal } from "./forgotPasswordModal";
import { ReleaseNotesModal } from "./releaseNotesModal";
import { FullJob } from "../../models/job";
import { Patient } from "../../models/patient";
import { PatientCreationModal } from "./patientCreationModal";
import { SeenListModal, SeenListModalStatus } from "./seenListModal";
import { SignOutType } from "../..";
import { SettingsTabType } from "../../pages/settings/settingsModal";
import { SettingsModal } from "../../pages/settings/settingsModal";
import { AccountSections } from "./accountManagement/util";

// Documentation @ /docs/ModalManager.md

type ModalTypes =
    | "aboutUs"
    | "apparatusSignOut"
    | "contactSupport"
    | "releaseNotes"
    | "accountManagement"
    | "forcedResponse"
    | "forgotPassword"
    | "patientCreation"
    | "seenList"
    | "settings";

export type AccountManagementModalState = {
    openTab: "account" | "agency" | "alert";
    openSection?: AccountSections | null;
};

export type ForcedResponseModalState = {
    status: ForcedResponsePresentedStatus;
};

export type ForgotPasswordModalState = {
    email: string;
    onEmailChange: (newEmail: string) => void;
    onSuccess: () => void;
};

export type PatientCreationModalState = {
    job: FullJob;
    onCreatePatient: (newPatient: Patient, prompts: string[] | null) => void;
};

export type SeenListModalState = {
    status: SeenListModalStatus;
};

export type ApparatusSignoutModalState = {
    onSignOut: (type: SignOutType) => void;
};

export type SettingsModalState = {
    onPasswordChanged: () => void;
    initialTab?: SettingsTabType;
};

export type ContactSupportModalState = {
    email?: string;
};

type TStateExtension =
    | {}
    | ForcedResponseModalState
    | ForgotPasswordModalState
    | AccountManagementModalState
    | PatientCreationModalState
    | SeenListModalState
    | ApparatusSignoutModalState
    | SettingsModalState
    | ContactSupportModalState;

export type ModalState<TStateType extends TStateExtension = {}> =
    | {
          modal: null;
      }
    | {
          modal: ModalTypes;
          options: TStateType;
      };

export type ModalOpenFunction = <TStateType extends TStateExtension = {}>(
    modal: ModalTypes,
    options?: TStateType | undefined
) => void;

export type ModalContextType = {
    state: ModalState;
    open: ModalOpenFunction;
    close: () => void;
};

export const ModalContext = createContext<ModalContextType | null>(null);

export type ModalManagerProps = {
    children: ReactNode;
};

export function ModalManager({ children }: ModalManagerProps) {
    const [modalState, setModalState] = useState<ModalState<any>>({
        modal: null,
    });

    function close() {
        setModalState({ modal: null });
    }

    function open<TStateType extends TStateExtension = {}>(
        modal: ModalTypes,
        options?: TStateType
    ): ModalState<TStateType> {
        setModalState({ modal: modal, options: options ?? {} });
        return modalState;
    }

    return (
        <ModalContext.Provider
            value={{
                state: modalState,
                open,
                close,
            }}
        >
            <AccountManagementModal
                open={modalState.modal == "accountManagement"}
                onClose={close}
                initialState={
                    modalState.modal == "accountManagement" &&
                    modalState.options
                }
            />
            <AboutUsModal
                open={modalState.modal == "aboutUs"}
                onClose={close}
                onClickReleaseNotes={() => open("releaseNotes")}
            />
            <ApparatusSignOutModal
                open={modalState.modal == "apparatusSignOut"}
                onCancel={close}
                onSignOut={
                    modalState.modal == "apparatusSignOut" &&
                    modalState.options.onSignOut
                }
            />
            <ContactSupportModal
                open={modalState.modal == "contactSupport"}
                onClose={close}
                email={
                    modalState.modal == "contactSupport" &&
                    modalState.options.email
                }
            />
            <ForcedResponseModal
                presentedStatus={
                    modalState.modal == "forcedResponse" &&
                    modalState.options.status &&
                    modalState.options.status.key == "presented"
                        ? modalState.options.status
                        : { key: "dismissed" }
                }
                onClose={close}
            />
            <ForgotPasswordModal
                open={modalState.modal == "forgotPassword"}
                email={
                    modalState.modal == "forgotPassword" &&
                    modalState.options.email
                }
                onCancel={close}
                onSuccess={
                    modalState.modal == "forgotPassword" &&
                    modalState.options.onSuccess
                }
                onEmailChange={
                    modalState.modal == "forgotPassword" &&
                    modalState.options.onEmailChange
                }
            />
            <ReleaseNotesModal
                open={modalState.modal == "releaseNotes"}
                onClose={close}
            />
            <PatientCreationModal
                open={modalState.modal == "patientCreation"}
                onCancel={close}
                onCreatePatient={
                    modalState.modal == "patientCreation" &&
                    modalState.options.onCreatePatient
                }
                job={
                    modalState.modal == "patientCreation" &&
                    modalState.options.job
                }
            />
            <SeenListModal
                status={
                    modalState.modal == "seenList" &&
                    modalState.options.status &&
                    modalState.options.status.key == "active"
                        ? modalState.options.status
                        : { key: "unset" }
                }
                onClose={close}
            />
            <SettingsModal
                open={modalState.modal == "settings"}
                onClose={close}
                onPasswordChanged={
                    modalState.modal == "settings" &&
                    modalState.options.onPasswordChanged
                }
                initialTab={
                    modalState.modal == "settings" &&
                    modalState.options.initialTab
                }
            />
            {children}
        </ModalContext.Provider>
    );
}

export function useModals(): ModalContextType {
    const context = useContext(ModalContext);
    return (
        context ?? { open: () => {}, close: () => {}, state: { modal: null } }
    );
}
