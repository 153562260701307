import * as React from 'react';
import {CSSProperties} from "react";
import {Icon, SemanticICONS} from 'semantic-ui-react';

interface ZeroStateViewProps {
    header: React.ReactNode;
    subheader?: React.ReactNode;
    icon?: SemanticICONS;
    keyTooltip?: string;
    maxWidth?: string;
    style?: CSSProperties;
}

export class ZeroStateView extends React.Component<ZeroStateViewProps, any> {
    render() {
        return (
            <div className="zero-state-view" style={{maxWidth: this.props.maxWidth, ...this.props.style}}>
                {
                    this.props.icon != null ? (
                        <Icon
                            className={'icon' + (document.body.classList.contains('dark') ? ' inverted' : '')}
                            circular
                            size="big"
                            name={this.props.icon}/>
                    ) : null
                }
                <span className="header bryx-text">
                    {this.props.header}
                </span>
                {
                    this.props.subheader != null ? (
                        <span className="subheader">
                            {this.props.subheader}
                        </span>
                    ) : null
                }
            </div>
        );
    }
}
