import * as i18n from "i18next";
import * as React from "react";
import * as InfiniteScroll from 'react-infinite-scroller';
import {Button, Loader} from "semantic-ui-react";
import {JobListItem} from "../../components/jobListItem";
import {ZeroStateView} from "../../components/views/zeroStateView";
import {ListJob} from "../../models/job";
import {JobListStatus, JobListType, JobManager} from "../../utils/jobManager";
import {BryxPreferences, PreferenceManager, PreferenceManagerObserver} from "../../utils/preferenceManager";
import {UpdateManager} from "../../utils/updateManager";

interface JobsListViewProps {
    listStatus: JobListStatus;
    listType: JobListType;
    selectedJobId: string | null;
    visible: boolean;
    onSelectJob: (job: ListJob) => void;
    onListRef: (ref: Element | null) => void;
}

interface JobListViewState {
    preferences: BryxPreferences;
}

export class JobsListView extends React.Component<JobsListViewProps, JobListViewState> implements PreferenceManagerObserver {
    constructor(props: JobsListViewProps, context: any) {
        super(props, context);
        this.state = {
            preferences: PreferenceManager.shared.preferences,
        };
    }

    componentDidMount() {
        PreferenceManager.shared.registerObserver(this);
    }

    componentWillUnmount() {
        PreferenceManager.shared.unregisterObserver(this);
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void {
        this.setState({preferences: newPrefs});
    }

    render() {
        const {listStatus} = this.props;
        let content;
        let displayMode;
        if (listStatus.key == "loading") {
            content = (
                <div className="loader-container">
                    <Loader inline active/>
                </div>
            );
            displayMode = this.props.visible ? "flex" : "none";
        } else if (listStatus.key == "failed") {
            content = (
                <div className="flexCenteredContainer" style={{height: "100%"}}>
                    <ZeroStateView
                        header={i18n.t("jobs.jobsList.failedToLoad")}
                        subheader={
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <span style={{marginBottom: "10px"}}>{listStatus.message}</span>
                                <Button
                                    onClick={UpdateManager.shared.update}>{i18n.t("general.reload")}</Button>
                            </div>
                        }
                        icon="warning sign"
                    />
                </div>
            );
            displayMode = this.props.visible ? "flex" : "none";
        } else if (listStatus.jobs.length == 0) {
            content = (
                <div className="empty-zero-state-view">
                    <ZeroStateView
                        header={i18n.t(`jobs.jobsList.noJobs.${JobListType[this.props.listType]}.header`)}
                        subheader={i18n.t(`jobs.jobsList.noJobs.${JobListType[this.props.listType]}.subheader`)}
                        icon="list"
                    />
                </div>
            );
            displayMode = this.props.visible ? "flex" : "none";
        } else {
            content = (
                <InfiniteScroll
                    initialLoad={false}
                    loadMore={page => JobManager.shared.loadMoreJobs(this.props.listType)}
                    loader={<div style={{margin: "10px 0 20px 0"}}><Loader active inline="centered"/></div>}
                    useWindow={false}
                    hasMore={listStatus.canLoadMoreJobs}>
                    {listStatus.jobs.map(j => (
                        <JobListItem
                            key={j.id}
                            job={j}
                            selected={this.props.selectedJobId == j.id}
                            width="350px"
                            preferences={this.state.preferences}
                            onClick={() => this.props.onSelectJob(j)}
                        />
                    ))}
                </InfiniteScroll>
            )
            ;
            displayMode = this.props.visible ? undefined : "none";
        }
        return (
            <div className="jobs-list-view" style={{display: displayMode}} ref={div => this.props.onListRef(div)}>
                {content}
            </div>
        );
    }
}
