import * as i18n from "i18next";
import * as moment from "moment";
import * as React from "react";
import * as InfiniteScroll from "react-infinite-scroller";
import {Redirect} from "react-router";
import {Button, Header, Icon, Image, Input, Loader, Modal} from "semantic-ui-react";
import {MembersPopup} from "../../components/membersPopup";
import {SeenListModal, SeenListModalStatus} from "../../components/modals/seenListModal";
import UploadFileModal from "../../components/modals/uploadFileModal";
import {ZeroStateView} from "../../components/views/zeroStateView";
import {config} from "../../config";
import {Group} from "../../models/group";
import {GroupUpdate} from "../../models/groupUpdate";
import {MessageContentImage, MessageContentType, MessageSender} from "../../models/message";
import {AudioUtils} from "../../utils/audioUtils";
import {ApiResult, BryxApi} from "../../utils/bryxApi";
import {DateUtils} from "../../utils/dateUtils";
import {MessageManager, MessageManagerActiveGroupObserver} from "../../utils/messageManager";
import {NotificationManager} from "../../utils/notificationManager";
import {BryxPreferences, PreferenceManager, PreferenceManagerObserver} from "../../utils/preferenceManager";
import {MessageView} from "./messageView";
import { ModalContext, ModalContextType, SeenListModalState } from "../../components/modals/modalManager";

interface GroupInfoProps {
    groupId: string | null;
    groupType: "messaging" | "patient";
    onClose?: () => void;
}

interface GroupInfoState {
    status: { key: "unset" } |
        { key: "loading" } |
        { key: "active", group: Group } |
        { key: "failed", message: string };
    scrollingMode: "userControlled" | "systemControlled";
    hasMore: boolean;
    message: string | File;
    preferences: BryxPreferences;
    displayedImage: { key: "unset" } | { key: "active", sender: MessageSender, timeSent: Date, content: MessageContentImage, loading: boolean };
    isOpen: boolean;
    fileMessageString: string | ArrayBuffer | null;
}

export class GroupInfoView extends React.Component<GroupInfoProps, GroupInfoState> implements MessageManagerActiveGroupObserver, PreferenceManagerObserver {
    private scrollBottom: HTMLElement | null = null;
    private messageListContainer: HTMLDivElement | null = null;
    static contextType?: React.Context<ModalContextType | null> | undefined = ModalContext;
    context: ModalContextType;

    constructor(props: GroupInfoProps, context: any) {
        super(props, context);
        this.state = {
            status: props.groupId == null ? {key: "unset"} : {key: "loading"},
            hasMore: false,
            message: "",
            scrollingMode: "systemControlled",
            preferences: PreferenceManager.shared.preferences,
            displayedImage: {key: "unset"},
            isOpen: false,
            fileMessageString: '',
        };
    }

    componentDidMount() {
        MessageManager.shared.registerActiveGroupObserver(this);
        if (this.props.groupId != null) {
            MessageManager.shared.setActiveGroup(this.props.groupId);
        }
        PreferenceManager.shared.registerObserver(this);
    }

    private scrollToBottom(animated: boolean) {
        if (this.scrollBottom != null) {
            this.scrollBottom.scrollIntoView({behavior: animated ? "smooth" : "auto"});
        }
    }

    private updateLastRead() {
        if (this.state.status.key != "active") {
            return;
        }
        const {group} = this.state.status;
        const latestMessage = group.latestMessage;
        if (latestMessage == null) {
            return;
        }
        const lastMessageTime = latestMessage.timeSent;
        const meMember = group.members.filter(m => m.isMe)[0];
        if (lastMessageTime.getTime() > meMember.readUntil.getTime()) {
            const oldReadUntil = meMember.readUntil;
            meMember.readUntil = lastMessageTime;
            BryxApi.updateLastReadTime(group.id, lastMessageTime, result => {
                if (result.success == true) {
                    config.debug(`Updated last read time to: ${lastMessageTime.toLocaleString()}`);
                } else {
                    meMember.readUntil = oldReadUntil;
                    config.warn(`Failed to update last read time for group@${group.id}: ${result.debugMessage}`);
                }
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: GroupInfoProps, nextContext: any) {
        if (nextProps.groupId == this.props.groupId) {
            return;
        }
        if (nextProps.groupId == null) {
            this.setState({status: {key: "unset"}, scrollingMode: "systemControlled"});
            if (this.props.groupId != null) {
                MessageManager.shared.clearActiveGroup(this.props.groupId);
            }
            NotificationManager.shared.setActiveGroup(nextProps.groupId);
        } else {
            this.setState({status: {key: "loading"}, scrollingMode: "systemControlled"});
            if (this.props.groupId != null) {
                MessageManager.shared.clearActiveGroup(this.props.groupId);
            }
            MessageManager.shared.setActiveGroup(nextProps.groupId);
            // `NotificationManager.setActiveGroup` will be called when group is loaded in `this.setGroup`
        }
    }

    componentWillUnmount() {
        NotificationManager.shared.setActiveGroup(null);
        if (this.props.groupId != null) {
            MessageManager.shared.clearActiveGroup(this.props.groupId);
        }
        MessageManager.shared.unregisterActiveGroupObserver(this);
        PreferenceManager.shared.unregisterObserver(this);
    }

    // MessageManagerActiveGroupObserver Functions

    messageManagerDidReceiveActiveGroup(group: Group): void {
        NotificationManager.shared.setActiveGroup(group.id);
        this.setState({hasMore: group.messages.length >= 30});
        this.setGroup(group, "instant");
        this.updateLastRead();
    }

    messageManagerDidUpdateActiveGroup(group: Group, update: GroupUpdate): void {
        this.setGroup(group, "animated");
        // Play click for new messages from others.
        // Messages sent from this device will not arrive through the active group updates
        // but if this client is using another device, messages sent from those devices will.
        if (update.key == "message" && !update.message.sender.isMe) {
            AudioUtils.clickSound.play();
            if (this.state.scrollingMode == "systemControlled") {
                this.updateLastRead();
            }
        }
    }

    messageManagerDidLoadOldMessagesForActiveGroup(group: Group): void {
        this.setGroup(group, "none");
    }

    messageManagerDidReachEndOfActiveGroupMessages(group: Group): void {
        this.setState({hasMore: false});
    }

    messageManagerDidFailToLoadActiveGroup(errorMessage: string): void {
        config.warn(`Failed to load group@${this.props.groupId}: ${errorMessage}`);
        this.setState({status: {key: "failed", message: errorMessage}});
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences) {
        this.setState({preferences: newPrefs});
    }

    private setGroup(group: Group, scrollBehavior: "none" | "instant" | "animated") {
        this.setState({status: {key: "active", group: group}}, () => {
            if (this.state.scrollingMode == "systemControlled" && scrollBehavior != "none") {
                this.scrollToBottom(scrollBehavior == "animated");
            }
        });
    }

    private messageCallback = (result: ApiResult<any>, group: Group) => {
        const newStatus = this.state.status;
        if (newStatus.key == "active" && newStatus.group.id == group.id) {
            this.setGroup(group, "instant");
        }
        if (result.success == false) {
            config.warn(`Failed to send message in group@${group.id}: ${result.debugMessage}`);
        }
    }

    /**
     * sendFileMessage allows encoding of a file to be done asynchronously and have the file sent after that encoding is done.
     */
    private sendFileMessage = () => {
        const {status} = this.state;
        if (status.key != "active") {
            return;
        }
        const group = status.group;
        this.setState({message: "", scrollingMode: "systemControlled"});
        group.sendMessage({
            type: MessageContentType.image,
            image: (typeof this.state.fileMessageString === "string") ? this.state.fileMessageString.split(',')[1] : '',
        }, (result: ApiResult<any>) => this.messageCallback(result, group));
        this.setGroup(group, "instant");
    }

    private sendMessage() {
        const {message, status} = this.state;
        if (status.key != "active") {
            return;
        }
        const group = status.group;
        if (typeof message === "string") {
            const cleanedText = message.trim();
            if (cleanedText == "" || group.id == null) {
                return;
            }
            this.setState({message: "", scrollingMode: "systemControlled"});
            group.sendMessage({
                type: MessageContentType.text,
                text: cleanedText,
            }, (result: ApiResult<any>) => this.messageCallback(result, group));
            this.setGroup(group, "instant");
        } else {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                this.setState({fileMessageString: reader.result}, () => {
                    this.sendFileMessage();
                });
            }, false);

            if (message) {
                reader.readAsDataURL(message);
            }
        }
    }

    render() {
        const {status} = this.state;
        let content: React.ReactNode;
        if (status.key == "loading") {
            content = (
                <div className="content-loading">
                    <Loader inline active/>
                </div>
            );
        } else if (status.key == "failed") {
            return <Redirect to="/messaging"/>;
        } else if (status.key == "unset") {
            content = (
                <div className="zero-state-content">
                    <ZeroStateView
                        header={i18n.t("messages.noSelectedGroup.title")}
                        subheader={i18n.t("messages.noSelectedGroup.body")}/>
                </div>
            );
        } else {
            const {message, hasMore, preferences, displayedImage} = this.state;
            const group = status.group;
            const messageContainer = (
                <div
                    className="message-list-container"
                    ref={r => this.messageListContainer = r}
                    onScroll={() => {
                        if (this.messageListContainer == null) {
                            return;
                        }
                        if (this.messageListContainer.scrollTop <= 100 && this.state.hasMore) {
                            this.messageListContainer.scrollTop = 100;
                        }
                        const isScrolledToPaddedBottom = this.messageListContainer.scrollHeight - this.messageListContainer.offsetHeight - this.messageListContainer.scrollTop < 50;
                        if (isScrolledToPaddedBottom) {
                            if (this.state.scrollingMode != "systemControlled") {
                                this.setState({scrollingMode: "systemControlled"});
                            }
                            this.updateLastRead();
                        } else if (!isScrolledToPaddedBottom && this.state.scrollingMode != "userControlled") {
                            this.setState({scrollingMode: "userControlled"});
                        }
                    }}>
                    <InfiniteScroll
                        initialLoad={false}
                        hasMore={hasMore}
                        loadMore={() => MessageManager.shared.loadMoreMessagesForActiveGroup(group.id)}
                        useWindow={false}
                        isReverse
                        loader={<div style={{margin: "10px 0 20px 0"}}><Loader active inline="centered"/></div>}
                    >
                        {group.messages.concat(group.sendingMessages).map((m, i) => {
                            const clusterDuration = moment.duration(1, "minutes");
                            const previousMessage = (i - 1 >= 0) ? group.messages[i - 1] : null;
                            const isFirst = previousMessage != null ? (
                                // isFirst if the senders are different or there has been a time gap between messages
                                // "senders different" means IDs are not equal and they are not both from me (IDs may not match because of sendingMessages)
                                (previousMessage.sender.id != m.sender.id && !(previousMessage.sender.isMe && m.sender.isMe)) || moment(previousMessage.timeSent).isSameOrBefore(moment(m.timeSent).subtract(clusterDuration))
                            ) : true;
                            const isLoading = m.content.type == MessageContentType.image && displayedImage.key == "active" && displayedImage.content.imageId == m.content.imageId && displayedImage.loading;
                            return (
                                <MessageView
                                    key={m.id}
                                    group={group}
                                    message={m}
                                    preferences={preferences}
                                    isFirstInCluster={isFirst}
                                    onClickResend={() => {
                                        group.resendMessage(m, result => this.setGroup(group, "instant"));
                                        this.setGroup(group, "instant");
                                    }}
                                    onClickShowSeenList={() => this.context.open<SeenListModalState>("seenList", {
                                        status: {
                                            key: "active",
                                            message: m,
                                            group: group,
                                        },
                                    })}
                                    isLoadingFullImage={isLoading}
                                    onClickImage={() => {
                                        if (m.content.type == MessageContentType.image) {
                                            this.setState({
                                                displayedImage: {
                                                    key: "active",
                                                    sender: m.sender,
                                                    timeSent: m.timeSent,
                                                    content: m.content,
                                                    loading: true,
                                                },
                                            });
                                        }
                                    }}/>
                            );
                        })}
                        <div ref={r => this.scrollBottom = r}/>
                    </InfiniteScroll>
                </div>
            );
            const noMessagesZeroState = (
                <ZeroStateView
                    header={i18n.t("messages.noMessages")}
                    icon="comments"
                    style={{flex: 1, justifyContent: "center"}}
                />
            );

            content = (
                <div className="group-container">
                    <div className="group-header-container">
                        <Header className="group-header" as="h2">
                            {this.props.groupType == "messaging" ? group.name : i18n.t("patients.timeline")}
                            <div>
                                <MembersPopup group={group}/>
                                {this.props.groupType == "patient" && this.props.onClose != undefined ? (
                                    <Icon
                                        className="close-icon"
                                        name="x"
                                        link
                                        onClick={this.props.onClose}
                                    />
                                ) : null}
                            </div>
                        </Header>
                    </div>
                    {group.messages.length > 0 ? messageContainer : noMessagesZeroState}
                    <div
                        className="scroll-down-button-container"
                        style={{display: this.state.scrollingMode == "systemControlled" ? "none" : undefined}}
                    >
                        <Button
                            className="scroll-down-button"
                            icon="chevron down"
                            circular
                            onClick={() => this.scrollToBottom(true)}
                        />
                    </div>
                    <div className="input-bar">
                        {group.canSend ? (
                            <Input
                                autoFocus
                                placeholder={i18n.t("messages.sendMessage")}
                                value={message}
                                onChange={(e, d) => this.setState({message: d.value})}
                                onKeyUp={(e: KeyboardEvent) => {
                                    if (e.key == "Enter") {
                                        this.sendMessage();
                                    }
                                }}>
                                <input/>
                                <UploadFileModal
                                    isOpen={this.state.isOpen}
                                    sendMessage={(messageFile: File) => {
                                        this.setState({message: messageFile}, () => {
                                            this.sendMessage();
                                        });
                                    }}
                                />
                                <Button primary icon onClick={() => this.sendMessage()}>
                                    <Icon name="send"/>
                                </Button>
                            </Input>
                        ) : (
                            <Input
                                disabled
                                value={i18n.t("messages.cannotSend")}
                                action={{color: 'teal', icon: 'send', disabled: true}}
                            />
                        )}
                    </div>
                    <Modal
                        open={displayedImage.key == "active" && !displayedImage.loading}
                        basic
                        size="large"
                        onClose={() => this.setState({displayedImage: {key: "unset"}})}>
                        <Icon
                            className="modal-close-button"
                            link
                            name="x"
                            inverted
                            size="huge"
                            onClick={() => this.setState({displayedImage: {key: "unset"}})}
                        />
                        <Modal.Header>
                            {displayedImage.key == "active" ? (
                                <div>
                                    {displayedImage.sender.name}
                                </div>
                            ) : null}
                            {displayedImage.key == "active" ? (
                                <div className="time-sent">
                                    {DateUtils.formatMessageDateTime(displayedImage.timeSent, preferences)}
                                </div>
                            ) : null}
                        </Modal.Header>
                        <Modal.Content>
                            {displayedImage.key == "active" ? (
                                <Image src={BryxApi.getImageUrl(displayedImage.content.imageId)} fluid/>
                            ) : null}
                        </Modal.Content>
                    </Modal>
                    {displayedImage.key == "active" ? (
                        <img
                            style={{display: "none"}}
                            src={BryxApi.getImageUrl(displayedImage.content.imageId)}
                            onLoad={() => this.setState(prevState => {
                                if (prevState.displayedImage.key == "active") {
                                    prevState.displayedImage.loading = false;
                                }
                                return prevState;
                            })}
                        />
                    ) : null}
                </div>
            );
        }
        return (
            <div className="group-info-view">
                {content}
            </div>
        );
    }
}
