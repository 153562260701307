import * as i18n from "i18next";
import * as React from 'react';
import {Header, Segment} from "semantic-ui-react";
import {JobType, JobTypeInformation} from "../../models/jobTypeInformation";
import {KeyValueView} from "./keyValueView";

interface JobTypeInfoViewProps {
    jobType: JobTypeInformation;
    style?: any;
}

export class JobTypeInfoView extends React.Component<JobTypeInfoViewProps, any> {
    private static jobTypeToLocaleString(type: JobType): string {
        switch (type) {
            case JobType.fire:
                return i18n.t("jobs.jobInfo.type.type.fire");
            case JobType.ems:
                return i18n.t("jobs.jobInfo.type.type.ems");
            case JobType.info:
                return i18n.t("jobs.jobInfo.type.type.info");
            case JobType.water:
                return i18n.t("jobs.jobInfo.type.type.water");
            case JobType.police:
                return i18n.t("jobs.jobInfo.type.type.police");
        }
    }

    render() {
        const jobType = this.props.jobType;
        return (
            <div className="job-type-info-view" style={this.props.style}>
                <Header className="job-type-info-header" as="h3" attached="top">
                    {i18n.t("jobs.jobInfo.type.title")}
                </Header>
                <Segment className="job-type-info-container" attached>
                    <KeyValueView keyNode={i18n.t("jobs.jobInfo.type.identifier")} valueNode={jobType.code}/>
                    {
                        jobType.description != null ? (
                            <KeyValueView keyNode={i18n.t("jobs.jobInfo.type.full")} valueNode={jobType.description}/>
                        ) : null
                    }
                    {
                        jobType.section != null ? (
                            <KeyValueView keyNode={i18n.t("jobs.jobInfo.type.section")} valueNode={jobType.section}/>
                        ) : null
                    }
                    <KeyValueView
                        keyNode={i18n.t("jobs.jobInfo.type.title")}
                        valueNode={JobTypeInfoView.jobTypeToLocaleString(jobType.type)}
                    />
                </Segment>
            </div>
        );
    }
}
