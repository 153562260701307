import * as i18n from "i18next";
import * as React from 'react';
import {SyntheticEvent} from "react";
import {ReCaptcha} from 'react-recaptcha-v3';
import {Button, Form, Header, Input, InputProps, Message, Modal} from 'semantic-ui-react';
import {config} from "../../config";
import {ApiResult, BryxApi} from "../../utils/bryxApi";
import {nullIfBlank} from "../../utils/functions";

interface ForgotPasswordModalProps {
    open: boolean;
    email: string | null;
    onEmailChange: (newEmail: string) => void;
    onCancel: () => void;
    onSuccess: () => void;
}

interface ForgotPasswordModalState {
    status: { key: "ready" } | { key: "loading" } | { key: "error", message: string };
    captchaResponse: string | null;
}

export class ForgotPasswordModal extends React.Component<ForgotPasswordModalProps, ForgotPasswordModalState> {
    private static readonly CAPTCHA_SITE_KEY = "6LeX7jQUAAAAAD_MbdOuRz7zCesILqiKLWQOkgus";
    private recaptchaInstance: ReCaptcha | null = null;

    constructor(props: ForgotPasswordModalProps, context: any) {
        super(props, context);

        this.state = {
            status: {key: "ready"},
            captchaResponse: null,
        };
    }

    private onEmailChange(e: SyntheticEvent<any>, inputProps: InputProps) {
        this.props.onEmailChange(inputProps.value);
    }

    private sendForgotPassword(): void {
        if (this.props.email == null || this.state.captchaResponse == null) {
            return;
        }

        this.setState({
            status: {key: "loading"},
        });

        BryxApi.forgotPassword(this.props.email, this.state.captchaResponse, (result: ApiResult<null>) => {
            if (result.success == true) {
                config.info("User successfully sent forgot password email");
                this.setState({
                    status: {key: "ready"},
                });
                this.props.onSuccess();
            } else {
                config.warn(`Failed to send forgot password email: ${result.debugMessage}`);
                if (this.recaptchaInstance != null) {
                    this.recaptchaInstance.execute();
                }
                this.setState({
                    status: {
                        key: "error",
                        message: result.message,
                    },
                });
            }
        });
    }

    render() {
        return (
            <Modal
                className="forgot-password-modal"
                size="tiny"
                open={this.props.open}
                onClose={this.props.onCancel}
            >
                <Header icon="question circle" content={i18n.t("login.forgotPassword")}/>

                <Modal.Content>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        this.sendForgotPassword();
                    }}>
                        <Form.Field>
                            <Input autoFocus
                                   type="email"
                                   placeholder={i18n.t("login.email")}
                                   disabled={this.state.status.key == "loading"}
                                   value={this.props.email}
                                   onChange={this.onEmailChange.bind(this)}/>
                        </Form.Field>
                    </Form>
                    <div className="recaptcha-container">
                        <ReCaptcha
                            ref={e => this.recaptchaInstance = e}
                            sitekey={ForgotPasswordModal.CAPTCHA_SITE_KEY}
                            action='recaptcha'
                            verifyCallback={response => this.setState({
                                captchaResponse: response,
                            })}
                        />
                    </div>
                    {this.state.status.key == "error" && (
                        <Message error>
                            {this.state.status.message}
                        </Message>
                    )}
                </Modal.Content>

                <Modal.Actions>
                    <Button disabled={this.state.status.key == "loading"}
                            type="button"
                            onClick={this.props.onCancel}>
                        {i18n.t("general.cancel")}
                    </Button>
                    <Button primary
                            type="button"
                            onClick={this.sendForgotPassword.bind(this)}
                            disabled={nullIfBlank(this.props.email) == null || this.state.captchaResponse == null}
                            loading={this.state.status.key == "loading"}>
                        {i18n.t("general.submit")}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
