import { Point } from "geojson";
import { ParseResult, ParseUtils } from "../utils/cerealParse";
import { MinimalNamedModel } from "./minimal";

export class Agency {
    public constructor(
        public id: string,
        public name: string,
        public manager: boolean,
        public dispatcher: boolean
    ) {}

    static parse(o: any): ParseResult<Agency> {
        try {
            return ParseUtils.parseSuccess(
                new Agency(
                    ParseUtils.getString(o, "id"),
                    ParseUtils.getString(o, "name"),
                    ParseUtils.getBoolean(o, "manager"),
                    ParseUtils.getBoolean(o, "dispatcher")
                )
            );
        } catch (e) {
            return ParseUtils.parseFailure<Agency>(
                `Invalid Agency: ${e.message}`
            );
        }
    }
}

export class ClientAgency {
    public constructor(
        public id: string,
        public name: string,
        public manager: boolean,
        public dispatchGroups: MinimalNamedModel[]
    ) {}
    public static parse(o: any): ParseResult<ClientAgency> {
        try {
            return ParseUtils.parseSuccess(
                new ClientAgency(
                    ParseUtils.getString(o, "id"),
                    ParseUtils.getString(o, "name"),
                    ParseUtils.getBoolean(o, "manager"),
                    ParseUtils.getArrayOfSubobjects(
                        o,
                        "dispatchGroups",
                        MinimalNamedModel.parse,
                        "ignore"
                    )
                )
            );
        } catch (e) {
            return ParseUtils.parseFailure<ClientAgency>(
                `Could not load agencies for client: ${e.message}`
            );
        }
    }
}
