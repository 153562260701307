import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {ParseUtils} from "../utils/cerealParse";
import {BryxNotification} from "./bryxNotification";

export type NotificationUpdateReplace = {
    key: "replace",
    notifications: BryxNotification[],
};
export type NotificationUpdateNew = {
    key: "new",
    notification: BryxNotification,
};
export type NotificationUpdate = NotificationUpdateReplace | NotificationUpdateNew;

export function parseNotificationUpdate(message: BryxWebSocketMessage): ApiResult<NotificationUpdate | null> {
    try {
        switch (message.key) {
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }
                return apiSuccess({
                    key: "replace",
                    notifications: ParseUtils.getArrayOfSubobjects({initialData: message.initialData}, "initialData", BryxNotification.parse, "warn"),
                } as NotificationUpdateReplace);
            case "serverUpdate":
                return apiSuccess({
                    key: "new",
                    notification: ParseUtils.getSubobject({data: message.data}, "data", BryxNotification.parse),
                } as NotificationUpdateNew);
            default:
                return apiSuccess(null);
        }
    } catch (e) {
        return apiFailure(null, `Invalid NotificationUpdate Model: ${e.message}`);
    }
}
