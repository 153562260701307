import * as i18n from "i18next";
import * as React from 'react';
import {Card, Icon} from 'semantic-ui-react';
import {BloodOxygen, BloodPressure, GCS, HeartRate} from "../../../models/patient";
import {BryxColors} from "../../../utils/bryxColors";
import {DateUtils} from "../../../utils/dateUtils";

const zeroStateVital = <Icon name="plus" size="big" style={{color: BryxColors.red}}/>;

interface VitalViewProps {
    currentTime: Date;
    onClick: () => void;
}

interface HeartRateProps extends VitalViewProps {
    vital: HeartRate | null;
}

export class HeartRateView extends React.Component<HeartRateProps, {}> {
    render() {
        const {vital, currentTime, onClick} = this.props;
        const vitalTime = vital != null ? (
            <div className="vital-time-ago">{DateUtils.duration(vital.time, currentTime).humanize(true)}</div>
        ) : null;

        return (
            <Card className="vitals-view" onClick={onClick}>
                <Card.Content>
                    <Card.Header>{i18n.t("patients.vitals.heartRate")}</Card.Header>
                    <Card.Meta className="vital-card-content">
                        {vital != null ? (
                            <div className="vital-single-value">
                                {vital.value}
                                <span className="vital-single-value-units">
                                    {i18n.t("patients.vitals.heartRateUnit")}
                                </span>
                            </div>
                        ) : zeroStateVital}
                    </Card.Meta>
                    {vitalTime}
                </Card.Content>
            </Card>
        );
    }
}

interface BloodPressureProps extends VitalViewProps {
    vital: BloodPressure | null;
}

export class BloodPressureView extends React.Component<BloodPressureProps, {}> {
    render() {
        const {vital, currentTime, onClick} = this.props;
        const vitalTime = vital != null ? (
            <div className="vital-time-ago">{DateUtils.duration(vital.time, currentTime).humanize(true)}</div>
        ) : null;

        return (
            <Card className="vitals-view" onClick={onClick}>
                <Card.Content>
                    <Card.Header>{i18n.t("patients.vitals.bloodPressure")}</Card.Header>
                    <Card.Meta className="vital-card-content">
                        {vital != null ? (
                            <div className="blood-pressure-content">
                                <div className="vital-double-value">
                                    {vital.systolic}
                                </div>
                                <div className="blood-pressure-unit">
                                    <span style={{backgroundColor: BryxColors.darkRed, height: "3px", width: "1.5vw"}}>

                                    </span>
                                    <span>{i18n.t("patients.vitals.bloodPressureUnit")}</span>
                                </div>
                                <div className="vital-double-value">
                                    {vital.formatDiastolic()}
                                </div>
                            </div>
                        ) : zeroStateVital}
                    </Card.Meta>
                    {vitalTime}
                </Card.Content>
            </Card>
        );
    }
}

interface BloodOxygenProps extends VitalViewProps {
    vital: BloodOxygen | null;
}

export class BloodOxygenView extends React.Component<BloodOxygenProps, {}> {
    render() {
        const {vital, currentTime, onClick} = this.props;
        const vitalTime = vital != null ? (
            <div className="vital-time-ago">{DateUtils.duration(vital.time, currentTime).humanize(true)}</div>
        ) : null;

        return (
            <Card className="vitals-view" onClick={onClick}>
                <Card.Content>
                    <Card.Header>{i18n.t("patients.vitals.bloodOxygen")}</Card.Header>
                    <Card.Meta className="vital-card-content">
                        {vital != null ? (
                            <div className="vital-single-value">
                                {vital.value}
                                <span className="vital-single-value-units">
                                    {i18n.t("patients.vitals.bloodOxygenUnit")}
                                </span>
                            </div>
                        ) : zeroStateVital}
                    </Card.Meta>
                    {vitalTime}
                </Card.Content>
            </Card>
        );
    }
}

interface GCSProps extends VitalViewProps {
    vital: GCS | null;
}

export class GCSView extends React.Component<GCSProps, {}> {
    render() {
        const {vital, currentTime, onClick} = this.props;
        const vitalTime = vital != null ? (
            <div className="vital-time-ago">{DateUtils.duration(vital.time, currentTime).humanize(true)}</div>
        ) : null;

        return (
            <Card className="vitals-view" onClick={onClick}>
                <Card.Content>
                    <Card.Header>{i18n.t("patients.vitals.gcs")}</Card.Header>
                    <Card.Meta className="vital-card-content">
                        {vital != null ? (
                            <div className="vital-single-value">
                                {vital.eye + vital.motor + vital.verbal}
                                <span className="vital-single-value-units">
                                    {`${vital.eye}E ${vital.verbal}V ${vital.motor}M`}
                                </span>
                            </div>
                        ) : zeroStateVital}
                    </Card.Meta>
                    {vitalTime}
                </Card.Content>
            </Card>
        );
    }
}
