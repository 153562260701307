import {config} from "../config";
import {ApiResult, BryxApi} from "./bryxApi";
import {BryxLocal} from "./bryxLocal";
import {ParseResult, ParseUtils} from "./cerealParse";

interface TopicRequest {
    topic: string;
    version: number | null;
    params: BryxWebSocketTopicParams | null;
}

export type BryxWebSocketMessageSubscribeResponse = {
    key: "subscribeResponse",
    successful: boolean,
    topic: string,
    initialData: {String: any} | null,
    errorReason: string | null,
};
export type BryxWebSocketMessageUnsubscribeResponse = {
    key: "unsubscribeResponse",
    successful: boolean,
    topic: string,
    errorReason: string | null,
};
export type BryxWebSocketMessageServerUpdate = {
    key: "serverUpdate",
    topic: string,
    data: {String: any},
};
export type BryxWebSocketMessageAcknowledgement = {
    key: "acknowledgement",
    successful: boolean,
    topic: string,
    id: number,
    errorReason: string | null,
};
export type BryxWebSocketMessagePingResponse = {
    key: "pingResponse",
    successful: boolean,
    id: number,
    errorReason: string | null,
};
export type BryxWebSocketMessage = BryxWebSocketMessageSubscribeResponse | BryxWebSocketMessageUnsubscribeResponse | BryxWebSocketMessageServerUpdate | BryxWebSocketMessageAcknowledgement | BryxWebSocketMessagePingResponse;

function parseWebsocketMessage(o: any): ParseResult<BryxWebSocketMessage> {
    try {
        const type = ParseUtils.getNumber(o, "type");
            switch (type) {
                case 1: // Subscribe Response
                    return ParseUtils.parseSuccess({
                        key: "subscribeResponse",
                        successful: ParseUtils.getBoolean(o, "ok"),
                        topic: ParseUtils.getString(o, "topic"),
                        initialData: o["initialData"] as {String: any} | null,
                        errorReason: ParseUtils.getStringOrNull(o, "reason"),
                    } as BryxWebSocketMessageSubscribeResponse);
                case 3: // Unsubscribe Response
                    return ParseUtils.parseSuccess({
                        key: "unsubscribeResponse",
                        successful: ParseUtils.getBoolean(o, "ok"),
                        topic: ParseUtils.getString(o, "topic"),
                        errorReason: ParseUtils.getStringOrNull(o, "reason"),
                    } as BryxWebSocketMessageUnsubscribeResponse);
                case 5: // Server Update
                    return ParseUtils.parseSuccess({
                        key: "serverUpdate",
                        topic: ParseUtils.getString(o, "topic"),
                        data: o["data"] as {String: any} | null,
                    } as BryxWebSocketMessageServerUpdate);
                case 7: // Server Acknowledgement
                    return ParseUtils.parseSuccess({
                        key: "acknowledgement",
                        successful: ParseUtils.getBoolean(o, "ok"),
                        topic: ParseUtils.getString(o, "topic"),
                        id: ParseUtils.getNumber(o, "replyTo"),
                        errorReason: ParseUtils.getStringOrNull(o, "reason"),
                    } as BryxWebSocketMessageAcknowledgement);
                case 9: // Ping Response
                    return ParseUtils.parseSuccess({
                        key: "pingResponse",
                        successful: ParseUtils.getBoolean(o, "ok"),
                        id: ParseUtils.getNumber(o, "replyTo"),
                        errorReason: ParseUtils.getStringOrNull(o, "reason"),
                    } as BryxWebSocketMessagePingResponse);
                default:
                    return ParseUtils.parseFailure<BryxWebSocketMessage>(`Invalid BryxWebSocketMessage type: ${type}`);
            }
    } catch (e) {
        return ParseUtils.parseFailure<BryxWebSocketMessage>(`Invalid BryxWebSocketMessage: ${e.message}`);
    }
}

export type BryxWebSocketOnUpdate = (message: BryxWebSocketMessage) => void;
export type BryxWebSocketOnAck = (result: ApiResult<null>) => void;

export type BryxWebSocketTopicParams = {[paramKey: string]: any};

export enum BryxWebSocketState {
    normal,
    reconnecting,
}

export enum PayloadType {
    subscribe = 0,
    subscribeResponse = 1,
    unsubscribe = 2,
    unsubscribeResponse = 3,
    serverUpdate = 5,
    update = 6,
    acknowledgement = 7,
}

export interface BryxWebSocketStateObserver {
    websocketStateDidChange(state: BryxWebSocketState): void;
}

export class BryxWebSocket {
    private static retryInitialWaitTime = 2.0;
    private static retryWaitTimeGrowthFactor = 2.0;
    private static maxRetryWaitTime = 60.0;

    private static pingTimeInterval = 20 * 1000; // 20 seconds

    private websocketConnection: WebSocket | null = null;
    private pingTimerId: NodeJS.Timer | null = null;
    private awaitingPingResponse = false;
    private keysToCallbacks: {[key: string]: {topicString: string, onUpdate: BryxWebSocketOnUpdate}} = {};
    private ackCallbacks: {[index: number]: BryxWebSocketOnAck} = {};
    private topics: TopicRequest[] = [];
    private retryWaitTime = 0;
    private identifierCount = 0;

    private newIdentifier(): number {
       return ++this.identifierCount;
    }

    private stateObservers: BryxWebSocketStateObserver[] = [];
    private suspended = false;

    public state = BryxWebSocketState.normal;

    static shared = new BryxWebSocket();

    open() {
        const apiKey = BryxLocal.getApiKey();
        if (apiKey != null && this.websocketConnection == null && !this.suspended && Object.keys(this.keysToCallbacks).length > 0) {
            this.websocketConnection = new WebSocket(`${BryxApi.wsUrl}/ws?apiKey=${apiKey}`);
            this.websocketConnection.onopen = this.onOpen.bind(this);
            this.websocketConnection.onclose = this.onClose.bind(this);
            this.websocketConnection.onmessage = this.onMessage.bind(this);
            this.websocketConnection.onerror = this.onError.bind(this);
            this.pingTimerId = setInterval(() => {
                if (this.awaitingPingResponse) {
                    config.warn("Missed ping, attempting reconnect...");
                    this.updateState(BryxWebSocketState.reconnecting);
                    this.close();
                    return;
                }
                this.awaitingPingResponse = true;
                this.sendJSON({
                    id: this.newIdentifier(),
                    type: 8,
                });
            }, BryxWebSocket.pingTimeInterval);
        }
    }

    private clearPingTimer() {
        if (this.pingTimerId != null) {
            clearInterval(this.pingTimerId);
            this.pingTimerId = null;
            this.awaitingPingResponse = false;
        }
    }

    close() {
        const websocketConnection = this.websocketConnection;
        if (websocketConnection != null) {
            websocketConnection.close();
            this.websocketConnection = null;
            this.clearPingTimer();
        }
    }

    reconnect() {
        this.close();
        this.open();
    }

    suspend() {
        this.suspended = true;
        this.close();
    }

    resume() {
        this.suspended = false;
        this.open();
    }

    reset() {
        this.keysToCallbacks = {};
        this.ackCallbacks = {};
        this.topics = [];
        this.identifierCount = 0;
        this.close();
    }

    toggleFakeDisconnect() {
        if (!this.suspended) {
            this.updateState(BryxWebSocketState.reconnecting);
            this.suspend();
        } else {
            this.resume();
        }
    }

    addSubscriber(key: string, topicString: string, onUpdate: BryxWebSocketOnUpdate, version?: number, params = <BryxWebSocketTopicParams> {}) {
        if (this.keysToCallbacks[key] != null) {
            // Ignore double subscriptions
            return;
        }
        this.addTopicIfRequired(topicString, version, params);
        this.keysToCallbacks[key] = {topicString: topicString, onUpdate: onUpdate};
        if (this.websocketConnection == null) {
            config.info("Got first subscriber, opening websocket");
            this.open();
        }
    }

    changeSubscription(key: string, topicString: string, params = <BryxWebSocketTopicParams> {}, resubscribe = true) {
        const existingTopic = this.keysToCallbacks[key];
        if (existingTopic == null) {
            return;
        }
        if (topicString != existingTopic.topicString) {
            config.error("Trying to change subscription, provided subscription key for the wrong topic");
            return;
        }
        const topic = this.requestForTopic(topicString);
        if (topic == null) {
            return;
        }
        if (params != null) {
            topic.params = params;
        }
        if (resubscribe) {
            this.sendTopicRequest(topic, PayloadType.subscribe);
        }
    }

    removeSubscriber(key: string) {
        const subscription = this.keysToCallbacks[key];
        delete this.keysToCallbacks[key];
        if (subscription != null) {
            this.removeTopicIfRequired(subscription.topicString);
        }
        if (Object.keys(this.keysToCallbacks).length == 0 && this.websocketConnection != null) {
            config.info("No more subscribers, closing");
            this.close();
        }
    }

    addTopicIfRequired(topic: string, version?: number, params?: BryxWebSocketTopicParams) {
        if (this.requestForTopic(topic) != null) {
            return;
        }
        const request: TopicRequest = {
            topic: topic,
            version: version != null ? version : null,
            params: params != null ? params : null,
        };
        this.sendTopicRequest(request, PayloadType.subscribe);
        this.topics.push(request);
    }

    removeTopicIfRequired(topic: string) {
        const matchedRequest = this.requestForTopic(topic);
        if (matchedRequest == null) {
            // We aren't tracking this topic
            return;
        }
        const remainingSubscribers = Object.keys(this.keysToCallbacks)
            .map(k => this.keysToCallbacks[k])
            .filter(subscription => subscription.topicString == topic);
        if (remainingSubscribers.length != 0) {
            // There are still subscribers for this topic
            return;
        }
        this.sendTopicRequest(matchedRequest, PayloadType.unsubscribe);
        this.topics.splice(this.topics.indexOf(matchedRequest));
    }

    sendUpdate(topic: string, data: {}, completion: BryxWebSocketOnAck) {
        const matchedRequest = this.requestForTopic(topic);
        if (matchedRequest == null) {
            config.warn("Not sending update because we are no longer subscribed");
            return;
        }
        const id = this.newIdentifier();
        this.sendJSON({
            type: PayloadType.update,
            topic: topic,
            id: id,
            data: data,
        });
        this.ackCallbacks[id] = completion;
    }

    private requestForTopic(topic: String): TopicRequest | null {
        const matchedTopics = this.topics.filter(t => t.topic == topic);
        if (matchedTopics.length != 0) {
            return matchedTopics[0];
        } else {
            return null;
        }
    }

    private sendJSON(json: any) {
        const websocketConnection = this.websocketConnection;
        if (websocketConnection != null && websocketConnection.readyState == WebSocket.OPEN) {
            websocketConnection.send(JSON.stringify(json));
        }
    }

    private sendTopicRequest(request: TopicRequest, type: PayloadType) {
        if (type != PayloadType.subscribe && type != PayloadType.unsubscribe) {
            config.error(`Cannot send topic request with payload type: ${type}`);
            return;
        }
        const topicRequest: any = {
            type: type,
            topic: request.topic,
            id: this.newIdentifier(),
        };
        if (request.params != null && type == PayloadType.subscribe) {
            topicRequest["params"] = request.params;
        }
        if (request.version != null && type == PayloadType.subscribe) {
            topicRequest["version"] = request.version;
        }
        this.sendJSON(topicRequest);
    }

    private updateState(state: BryxWebSocketState) {
        config.info(`WebSocket state changed: ${BryxWebSocketState[state]}`);
        this.state = state;
        this.stateObservers.forEach(observer => observer.websocketStateDidChange(state));
    }

    private reconnectIfRequired() {
        // null the connection so it can't be used
        this.websocketConnection = null;
        // Stop the ping timer
        this.clearPingTimer();
        // Don't reconnect if the websocket was manually suspended
        if (this.suspended) {
            return;
        }
        // Don't reconnect if there are no subscribed clients
        if (Object.keys(this.keysToCallbacks).length == 0) {
            this.updateState(BryxWebSocketState.normal);
            return;
        }
        this.updateState(BryxWebSocketState.reconnecting);
        setTimeout(() => {
            this.open();
        }, this.retryWaitTime * 1000);

        // Don't sleep the first time, then start exponential growth.
        // Keep growing until sleep time exceeds maximum.
        if (this.retryWaitTime == 0) {
            this.retryWaitTime = BryxWebSocket.retryInitialWaitTime;
        } else if (this.retryWaitTime <= BryxWebSocket.maxRetryWaitTime) {
            this.retryWaitTime *= BryxWebSocket.retryWaitTimeGrowthFactor;
        }
    }

    private handleMessage(message: BryxWebSocketMessage) {
        switch (message.key) {
            case "subscribeResponse":
                Object.keys(this.keysToCallbacks).map(k => this.keysToCallbacks[k]).forEach(subscription => {
                   if (subscription.topicString == message.topic) {
                       subscription.onUpdate(message);
                   }
                });
                break;
            case "unsubscribeResponse":
                // Ignore
                break;
            case "serverUpdate":
                Object.keys(this.keysToCallbacks).map(k => this.keysToCallbacks[k]).forEach(subscription => {
                    if (subscription.topicString == message.topic) {
                        subscription.onUpdate(message);
                    }
                });
                break;
            case "acknowledgement":
                const callback = this.ackCallbacks[message.id];
                if (callback == null) {
                    return;
                }
                if (message.successful) {
                    callback({success: true, value: null});
                } else {
                    callback({success: false, message: message.errorReason || "", debugMessage: message.errorReason});
                }
                delete this.ackCallbacks[message.id];
                break;
            case "pingResponse":
                this.awaitingPingResponse = false;
                break;
        }
    }

    // WebSocket Handler Functions

    onOpen(event: Event) {
        config.info("Websocket connected");
        this.retryWaitTime = 0;
        this.updateState(BryxWebSocketState.normal);
        this.topics.forEach(topic => this.sendTopicRequest(topic, PayloadType.subscribe));
    }

    onClose(event: CloseEvent) {
        config.info(`WebSocket onClose (${event.code})`);
        this.reconnectIfRequired();
    }

    onMessage(event: MessageEvent) {
        const websocketPayload = JSON.parse(event.data);
        const websocketMessage = parseWebsocketMessage(websocketPayload);
        if (websocketMessage.success == true) {
            this.handleMessage(websocketMessage.value);
        } else {
            config.error(`Failed to parse websocket message: ${websocketMessage.justification}`);
        }
    }

    onError(event: Event) {
        config.info(`WebSocket onError (${event})`);
        this.reconnectIfRequired();
    }

    // State Observers

    public registerStateObserver(observer: BryxWebSocketStateObserver) {
        if (this.stateObservers.filter(o => o === observer).length == 0) {
            this.stateObservers.push(observer);
        }
    }

    public unregisterStateObserver(observer: BryxWebSocketStateObserver) {
        const observerIndex = this.stateObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.stateObservers.splice(observerIndex, 1);
        }
    }
}
