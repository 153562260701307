import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {ParseUtils} from "../utils/cerealParse";
import {Hydrant} from "./hydrant";
import {FullJob} from "./job";
import {Patient} from "./patient";
import {Responder} from "./responder";
import {Supplemental} from "./supplemental";

// SpecificJobServerUpdate

export type SpecificJobUpdateReplace = {
    key: "replace",
    job: FullJob,
};
export type SpecificJobUpdateAssignments = {
    key: "assignments",
    unitShortNames: string[],
};
export type SpecificJobUpdateSupplementals = {
    key: "supplementals",
    supplementals: Supplemental[],
};
export type SpecificJobUpdateResponders = {
    key: "responders",
    responders: Responder[],
};
export type SpecificJobUpdateHydrants = {
    key: "hydrants",
    hydrants: Hydrant[],
};
export type SpecificJobUpdatePatients = {
    key: "patients",
    patients: Patient[],
};
export type SpecificJobUpdate = SpecificJobUpdateReplace | SpecificJobUpdateAssignments | SpecificJobUpdateSupplementals | SpecificJobUpdateResponders | SpecificJobUpdateHydrants | SpecificJobUpdatePatients;

export function parseSpecificJobUpdate(message: BryxWebSocketMessage): ApiResult<SpecificJobUpdate | null> {
    try {
        switch (message.key) {
            case "serverUpdate":
                const type = ParseUtils.getString(message.data, "type");
                switch (type) {
                    case "assignments":
                        return apiSuccess({
                            key: "assignments",
                            unitShortNames: ParseUtils.getArray(message.data, "unitShortNames"),
                        } as SpecificJobUpdateAssignments);
                    case "supplementals":
                        return apiSuccess({
                            key: "supplementals",
                            supplementals: ParseUtils.getArrayOfSubobjects(message.data, "supplementals", Supplemental.parse, "warn"),
                        } as SpecificJobUpdateSupplementals);
                    case "responders":
                        return apiSuccess({
                            key: "responders",
                            responders: ParseUtils.getArrayOfSubobjects(message.data, "responders", Responder.parse, "warn"),
                        } as SpecificJobUpdateResponders);
                    case "hydrants":
                        return apiSuccess({
                            key: "hydrants",
                            hydrants: ParseUtils.getArrayOfSubobjects(message.data, "hydrants", Hydrant.parse, "warn"),
                        } as SpecificJobUpdateHydrants);
                    case "patients":
                        return apiSuccess({
                            key: "patients",
                            patients: ParseUtils.getArrayOfSubobjects(message.data, "patients", Patient.parse, "warn"),
                        } as SpecificJobUpdatePatients);
                    default:
                        return apiFailure(null, `Invalid SpecificJobUpdate subscribe response type: ${type}`);
                }
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }
                return apiSuccess({
                    key: "replace",
                    job: ParseUtils.getSubobject(message, "initialData", FullJob.parse),
                } as SpecificJobUpdateReplace);
            default:
                return apiSuccess(null);
        }
    } catch (e) {
        return apiFailure(null, `Invalid SpecificJobUpdate Model: ${e.message}`);
    }
}
