import { BryxToggleButton } from "@bryxinc/ui";
import {
    CorporateFare,
    ExpandMore,
    Apartment,
    People,
    FireTruck,
    FireHydrantAlt,
    Flag,
    Clear,
} from "@mui/icons-material";
import { Stack } from "@mui/system";
import i18n from "i18next";
import React, { useEffect, useMemo } from "react";
import { Dropdown } from "semantic-ui-react";
import {
    AgencyLayerItem,
    ClientLayerItem,
    HydrantLayerItem,
    JobLayerItem,
    LayerType,
    StationLayerItem,
} from "../../utils/mapManager";
import { BryxLocal } from "../../utils/bryxLocal";

export type MapLayers = {
    self: ClientLayerItem[];
    users: ClientLayerItem[];
    apparatus: ClientLayerItem[];
    hydrants: HydrantLayerItem[];
    jobs: JobLayerItem[];
    stations: StationLayerItem[];
    agencies: AgencyLayerItem[];
};

export function LayerToggle({ layers, onChange }: {
    layers: LayerType[];
    onChange: (layers: LayerType[]) => void;
}) {
    const defaultLayers: LayerType[] = ["jobs", "apparatus", "users"];

    useEffect(() => {
        const preLayers = sessionStorage.getItem("map-layers");
        if (!preLayers) {
            onChange(defaultLayers);
            sessionStorage.setItem(
                "map-layers",
                JSON.stringify(defaultLayers)
            );
        } else {
            onChange(JSON.parse(preLayers));
        }
    }, []);

    const enabledAgency: string | undefined = useMemo(
        () => layers.find((layer) => layer.startsWith("agency:"))?.split(":")[1],
        [layers]
    );

    const agencyOptions = useMemo(() => {
        const agenciesRaw = BryxLocal.getAgencies();
        return [
            ...agenciesRaw.map((agency, i) => ({
                    key: agency.id,
                    text: agency.name,
                    value: agency.id,
                })
            ),
            {
                key: "none",
                value: "none",
                text: (
                    <>
                        <Clear />
                        {i18n.t("map.noneAgency")}
                    </>
                ),
            },
        ];
    }, []);

    function toggleLayer(layer: LayerType): () => void {
        return () => {
            if (layers.includes(layer)) {
                const newLayers = layers.filter((checked) => checked != layer);
                onChange(newLayers);
                sessionStorage.setItem("map-layers", JSON.stringify(newLayers));
            } else {
                onChange([...layers, layer]);
                sessionStorage.setItem(
                    "map-layers",
                    JSON.stringify([...layers, layer])
                );
            }
        };
    }

    return (
        <Stack
            className="layer-toggle-group"
            direction="row"
            sx={{
                position: "absolute",
                right: "16px",
                bottom: "16px",
                zIndex: 1000,
            }}
            spacing="8px"
        >
            <Dropdown
                trigger={
                    <BryxToggleButton
                        className={"layer-toggle-agencies" + (enabledAgency ? " active" : "")}
                        startIcon={<CorporateFare />}
                        endIcon={<ExpandMore />}
                        active={true}
                        onClick={() => {}}
                    >
                        {i18n.t("map.layers.agencies")}
                    </BryxToggleButton>
                }
                options={agencyOptions}
                value={enabledAgency}
                icon={null}
                onChange={(_, data) => {
                    const newLayers: LayerType[] = ((
                        data.value != "none" ? [`agency:${data.value}`] : []
                    ) as LayerType[]).concat([
                        ...layers.filter(
                            (layer) =>
                                (enabledAgency &&
                                    !layer.endsWith(enabledAgency)) ||
                                !layer.startsWith("agency:")
                        ),
                    ]);

                    onChange(newLayers);
                    sessionStorage.setItem(
                        "map-layers",
                        JSON.stringify(newLayers)
                    );
                }}
            />
            <BryxToggleButton
                className={
                    "layer-toggle-stations" +
                    (layers.includes("stations") ? " active" : "")
                }
                startIcon={<Apartment />}
                active={layers.includes("stations")}
                onClick={toggleLayer("stations")}
            >
                {i18n.t("map.layers.stations")}
            </BryxToggleButton>
            <BryxToggleButton
                className={
                    "layer-toggle-self" +
                    (layers.includes("self") ? " active" : "")
                }
                startIcon={<People />}
                active={layers.includes("self")}
                onClick={toggleLayer("self")}
            >
                {i18n.t("map.layers.self")}
            </BryxToggleButton>
            <BryxToggleButton
                className={
                    "layer-toggle-users" +
                    (layers.includes("users") ? " active" : "")
                }
                startIcon={<People />}
                active={layers.includes("users")}
                onClick={toggleLayer("users")}
            >
                {i18n.t("map.layers.users")}
            </BryxToggleButton>
            <BryxToggleButton
                className={
                    "layer-toggle-apparatus" +
                    (layers.includes("apparatus") ? " active" : "")
                }
                startIcon={<FireTruck />}
                active={layers.includes("apparatus")}
                onClick={toggleLayer("apparatus")}
            >
                {i18n.t("map.layers.apparatus")}
            </BryxToggleButton>
            <BryxToggleButton
                className={
                    "layer-toggle-jobs" +
                    (layers.includes("jobs") ? " active" : "")
                }
                startIcon={<Flag />}
                active={layers.includes("jobs")}
                onClick={toggleLayer("jobs")}
            >
                {i18n.t("map.layers.jobs")}
            </BryxToggleButton>
            <BryxToggleButton
                className={
                    "layer-toggle-hydrants" +
                    (layers.includes("hydrants") ? " active" : "")
                }
                startIcon={<FireHydrantAlt />}
                active={layers.includes("hydrants")}
                onClick={toggleLayer("hydrants")}
            >
                {i18n.t("map.layers.hydrants")}
            </BryxToggleButton>
        </Stack>
    );
}
